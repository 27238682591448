// Customizable Area Start
import React from "react";
import { Typography,
   Box,
    Paper,
     styled, 
     withStyles,
      createStyles, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { RouteComponentProps } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CourseCard from "./CourseCard.web";
import Anchor from "./Anchor";
import DashboardController from "./DashboardController.web";

// Define the styles
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: "20px",
      padding: "0px 36px 32px 36px",
      borderRadius: "20px",
    },
    headingContainer: {
      padding: "20px 15px",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 30px",
      },
    },
    heading: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "26px",
      color: "#0A1629",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    skeletonContainer: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop: "5px",
      margin: "0 10px",
      [theme.breakpoints.up("sm")]: {
        margin: "0 40px",
      },
    },
    contentContainer: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop: "20px",
      margin: "0 10px",
      [theme.breakpoints.up("sm")]: {
        margin: "0 40px",
      },
    },
    coursesContainer: {
      justifyContent: "flex-end",
    },
    inActiveTab: {
      display: "flex",
      flexDirection: "row",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      textAlign: "center",
      cursor: "pointer",
    },
    activeTab: {
      color: "#6C328B",
      fontWeight: "bold",
      borderBottom: "solid 4px",
      borderColor: "#6C328B",
      paddingBottom: "5px",
    },
    noCourseHeaderText: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      textAlign: "center",
      marginBottom: "15px",
    },
    noCourseDescText: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "center",
      marginBottom: "32px",
    },
    noCoursesContainer: {
      justifyContent: "center",
    },
    noCourseText: {
      textTransform: "capitalize",
    },
  });


interface Props extends RouteComponentProps {
  isLoading: boolean;
  courses: any[];
  courseStatus: string;
  handleCourseStatusChange: (value: string) => void;
  fetchMoreDashboardCourses: () => void;
  hasMore: boolean;
}


class CoursesWeb extends DashboardController {
  handleNavigation = (courseUrl: string, id: string) => {
  };

  render() {

    const {
      isLoading,
      courses,
      courseStatus,
      handleCourseStatusChange,
      fetchMoreDashboardCourses,
      hasMore,
    } = this.props;

    const {
      flex,
      contentContainer,
      coursesContainer,
      paper,
      headingContainer,
      heading,
      noCourseText,
      noCourseHeaderText,
      noCourseDescText,
      skeletonContainer,
      inActiveTab,
      activeTab,
    } = this.props.classes;

    const statuses = [
      { label: "Past Due", value: "past_dues", color: "#EF2B4B" },
      { label: "In-Progress", value: "in_progress", color: "#FF8B3F" },
      { label: "Not Started", value: "not_started", color: "#979797" },
      { label: "Completed", value: "completed", color: "#8636A3" },
    ];

    return (
      <Paper elevation={3} className={paper}>
        <Box className={`${flex} ${headingContainer}`}>
          <Typography className={heading}>Courses</Typography>
          <Anchor to="/personal" text="view all" />
        </Box>
        {isLoading && (
          <Box className={skeletonContainer}>
            <Skeleton variant="text" />
            <Skeleton variant="text" width={200} />
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            className={`${flex} ${contentContainer}`}
            style={{
              display: "flex",
              alignItems: "center",
              width: "639px",
              height: "49px",
              gap: "33px",
              marginBottom: "24px",
            }}
          >
            <Typography
              id="handle-status-change"
              onClick={() => handleCourseStatusChange("all_courses")}
              className={`${courseStatus === "all_courses" ? activeTab : inActiveTab}`}
            >
              All Courses
            </Typography>
            {statuses.map((status, index) => (
              <Box
                key={index}
                data-test-id={`handle-status-change-${index}`}
                className={`${status.value === courseStatus ? activeTab : inActiveTab}`}
                onClick={() => handleCourseStatusChange(status.value)}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      width: "6px",
                      height: "6px",
                      marginRight: "5.5px",
                      backgroundColor: status.color,
                      border: "1px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography>{status.label}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {!isLoading && courses.length === 0 && (
          <Box
            style={{
              width: "100%",
              height: "153px",
              gap: "32px",
            }}
          >
            <Typography align="center" className={noCourseHeaderText}>
              No Courses Found
            </Typography>
            <Typography align="center" className={noCourseDescText}>
              Discover all the courses and resources for you to learn from the very best.
            </Typography>
            <ViewMoreText className={noCourseText} align="center">
              Go to Courses
            </ViewMoreText>
          </Box>
        )}
        <InfiniteScroll
          dataLength={courses.length}
          next={fetchMoreDashboardCourses}
          hasMore={hasMore}
          loader={<p>Loading ...</p>}
          height={400}
        >
          {!isLoading &&
            courses.length > 0 &&
            courses.map((course, index) => {
              const {
                id,
                attributes: { courseable_id, course_title, is_course },
              } = course;

              let courseUrl = "";
              if (is_course) {
                courseUrl = `/course-detail/Course/${courseable_id}/${course_title?.replace(/\s/g, "-")}`;
              } else {
                courseUrl = `/program-detail/Program/${courseable_id}/${course_title?.replace(/\s/g, "-")}`;
              }

              return (
                <Box
                  key={course.id}
                  data-test-id={`handle-nav-courses-${index}`}
                  className={`${flex} ${contentContainer} ${coursesContainer}`}
                  style={{
                    borderTop: index === 0 ? "1px solid #F1F1F5" : "none",
                  }}
                  onClick={() => this.handleNavigation(courseUrl, id)}
                >
                  <CourseCard key={course.id} course={course} />
                </Box>
              );
            })}
        </InfiniteScroll>
      </Paper>
    );
  }
}

const ViewMoreText = styled(Typography)({
  background: "linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)",
  width: "400px",
  height: "48px",
  gap: "10px",
  margin: "auto",
  borderRadius: "88px",
  fontWeight: 600,
  fontSize: "16px",
  color: "white",
  cursor: "pointer",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width:960px)": {
    fontSize: "12px",
  },
});

// Apply withStyles and withRouter
export default withStyles(styles)(CoursesWeb);

// Customizable Area End
