import React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface Props {
  to: string;
  text: string;
}

export default function Anchor({ to, text }: Props) {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link}>
      {text}
    </Link>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#6C328B",
      textDecoration: "none",
      textTransform: "capitalize",
      padding: "25px 0 15px 0",
      marginRight: "20px",
      [theme.breakpoints.up("sm")]: {
        marginRight: "40px",
      },
    },
    icon: {
      color: "#92929D",
      marginLeft: "10px",
    },
  })
);
