// Customizable Area Start
import React from "react";
import UserAssignContentController, {
    Library,
    Props,
} from "./UserAssignContentController.web";
import Badge from "@material-ui/core/Badge";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    styled
} from "@material-ui/core";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import { Filter } from "../../catalogue/src/assets";
import { addContentImg, imageHtmlCourse2, NotFoundImage, rightArrow } from "./assets";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MaterialUIBox from '@material-ui/core/Box';
import { ExpandMore, ExpandLess } from "@material-ui/icons";

class UserAssignContent extends UserAssignContentController {
    wrapperRefLaunchDate: any;
    wrapperRefDueDate: any;
    constructor(props: Props) {
        super(props);
    }

    generateToggleBoxStyle(isActive: boolean) {
        return {
            backgroundColor: "#FFFFFF",
            height: "48px",
            boxShadow: isActive ? "1px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
            color: isActive ? "#1C1B1B" : "",
            display: "flex",
            alignItems: "center",
            width: "170px",
            minWidth: "170px",
            fontSize: "18px",
            fontWeight: isActive ? 600 : 400,
            justifyContent: "center",
            borderRadius: "10px",
            cursor: "pointer",
            maxWidth: "200px",
            padding: "14px 24px"
        };
    }

    renderGroupAssigneeTextFieldBox = () => {
        return (
            <>
                <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
                    <Typography className="labelStyle" >Group:</Typography>
                    <StyledDropdownClickBoxs
                        onClick={this.groupdropdownOpen}
                        data-test-id="select_ID"
                        style={{ color: '#1C1B1B', background: '#fff' }}
                    >
                        <MaterialUIBox className="contentContainer contentRole">
                            <MaterialUIBox className="innerContentBox"
                                style={{
                                    color: this.state.selectedGroup ? '#1C1B1B' : '#ADAAAA',
                                    background: '#fff', fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                                }}>
                                {this.state.selectedGroup || 'Select a group of users'}
                            </MaterialUIBox>
                        </MaterialUIBox>
                        {this.state.groupDropdown ? <ExpandLess /> : <ExpandMore />}
                    </StyledDropdownClickBoxs>
                    {this.state.groupDropdown && (
                        <DropdownContent>
                            <MaterialUIBox className="checkboxList">
                                <RadioGroup
                                    data-test-id="radioGroup"
                                    aria-labelledby="group-selection"
                                    name="group"
                                    value={this.state.selectedGroup}
                                    onChange={this.handleGroupChange}
                                >
                                    <FormControlLabel className={"checkboxContent"} value="Users" control={<Radio style={{ color: "#652786" }} />} label="Users" />
                                    <FormControlLabel className={"checkboxContent"} value="Branches" control={<Radio style={{ color: "#652786" }} />} label="Branches" />
                                </RadioGroup>
                            </MaterialUIBox>
                        </DropdownContent>
                    )}
                </FormControl>

                <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
                    <Typography className="labelStyle" >Assignee:</Typography>
                    <StyledDropdownClickBoxs
                        onClick={this.assigneedropdownOpen}
                        data-test-id="select_ID"
                    >
                        <MaterialUIBox className="contentContainer contentRole">
                            <MaterialUIBox className="innerContentBox"
                                style={{
                                    color: '#1C1B1B',
                                    background: '#fff', fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                                }}>
                                {`${this.state.firstName} ${this.state.lastName}` || 'Select group first'}
                            </MaterialUIBox>
                        </MaterialUIBox>
                        {this.state.assigneeDropdown ? <ExpandLess /> : <ExpandMore />}
                    </StyledDropdownClickBoxs>
                    {this.state.assigneeDropdown && (
                        <DropdownContent>
                            <MaterialUIBox className="checkboxList">
                                <RadioGroup
                                    data-test-id="radioGroup2"
                                    aria-labelledby="group-selection"
                                    name="group"
                                    value={this.state.selectedAssignee}
                                    onChange={this.handleAssigneeChange}
                                >
                                    <FormControlLabel className={"checkboxContent"} value={`${this.state.firstName} ${this.state.lastName}`} control={<Radio style={{ color: "#652786" }} />} label={`${this.state.firstName} ${this.state.lastName}`} />
                                </RadioGroup>
                            </MaterialUIBox>
                        </DropdownContent>
                    )}
                </FormControl>
            </>
        );
    }

    renderCoursesProgramSearchBox() {
        const { isCourseActive } = this.state;
        const courseToggleStyle = this.generateToggleBoxStyle(isCourseActive === "course");
        const programToggleStyle = this.generateToggleBoxStyle(isCourseActive === "program");
        return (
            <Grid item container style={{ margin: "20px", gap: "clamp(5px, 45px, 3%)" }} >
                <TabGrid >
                    <ToggleBox >
                        <ToggleInnerbox data-test-id="courseToggleId"
                            style={courseToggleStyle} onClick={this.handleToggleCourses} >
                            Course
                        </ToggleInnerbox>
                        <ToggleInnerbox data-test-id="programToggleId"
                            style={programToggleStyle} onClick={this.handleTogglePrograms}>
                            Program
                        </ToggleInnerbox>
                    </ToggleBox>
                </TabGrid>

                <InputMainBox style={{
                    display: 'flex', alignItems: 'center',
                    borderRight: "none", justifyContent: "center", width: "clamp(300px, 518px, 45%)"
                }}>
                    <StyledContentWrapper>
                        <AdvancedSearch
                            data-test-id="btn_change"
                            searchInputValue={this.state.searchInputValue}
                            handleInputChange={this.handleNextPageNavigation}
                            filterList={this.state.filteredList}
                            showList={this.state.showList} navigation={undefined} id={""}
                        />
                        <div style={{ marginLeft: "0px", width: "62px" }} className={"filterIconBox"} aria-describedby={"myPopover"}>
                            <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
                        </div>

                    </StyledContentWrapper>
                </InputMainBox>
            </Grid>
        )
    }

    renderTableData = (libraryStaticData: Library[], handleChange: (selectedData: Library) => void,
        tableName: string, checked: Library[]) => {
        return (
            <Box style={{ overflowY: "scroll", width: "100%", height: "588px" }} >
                {libraryStaticData.map((data, index) => (

                    <Box style={{
                        display: "flex", justifyContent: "space-between", alignItems: "center",
                        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", padding: "16px 24px", borderRadius: "12px",
                        maxWidth: "588px", height: "72px",
                        backgroundColor: this.isCheckedValue(checked, data) ? "rgba(238, 232, 242, 1)" : "#fff", marginBottom: "10px"
                    }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                className="checkBox"
                                style={{ color: this.isCheckedValue(checked, data) ? "#652786" : "grey", textTransform: "capitalize" }}
                                checked={this.isCheckedValue(checked, data)}
                                data-test-id={`selectedCheckBox${index}`}
                                onChange={() => handleChange(data)}
                            />
                            <div style={{ display: "flex", alignItems: "center", gap: "14px", }}>
                                <div>
                                    <img style={thumb_img} src={data?.attributes?.thumbnail_image?.url ? data?.attributes.thumbnail_image.url : imageHtmlCourse2} />
                                </div>

                                <TitleData>{data?.attributes?.name}</TitleData>
                            </div>
                        </div >

                        <Statuses style={webStyle.statusNew} >{data?.attributes?.status}</Statuses>
                    </Box>
                ))}
            </Box>
        )
    }

    renderProgramCLickedData = () => {
        return (
            <>
                {this.state.removedAllData.length > 0 ?
                    (this.renderTableData(this.state.removedAllData, this.handleRemoveCheckboxChangeProgram, "",
                        this.state.removedProgramData)
                    ) : (<Box style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px"
                    }}>
                        <Addedimg src={addContentImg} data-test-id="filterOpenTestID" />
                        <Typography style={webStyle.staticDataStyle}
                        >Select the content you want to add</Typography>
                    </Box>)}

                    <Box style={webStyle.removeButtonBoxstyle}>
                    <Button style={this.state.removedAllData.length>0 ? webStyle.removeButtonStyleAfter : webStyle.removeButtonStyleBefor}
                        data-test-id="removeButtonId"
                        disabled={this.state.removedAllData.length === 0}
                        onClick={this.handleRemoveButtonClickProgram}>
                        <KeyboardBackspaceIcon />
                        &nbsp;&nbsp;&nbsp;
                        <p style={{ textTransform: "capitalize" }} >Remove</p>
                    </Button>
                </Box>
            </>
        )
    }

    libraryDataCheck = (data: any, value: any) => {
        if (data.length > 0) {
            return this.renderTableData(value, this.handleCheckboxChange,
                "data", this.state.selectedContent)
        }
        else {
            return <Box style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                height: "695px"
            }}>
                <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID" />
                <Typography style={webStyle.staticDataStyle}
                >No results founds</Typography>
            </Box>
        }
    }

    renderDataBox() {
        const libraryData = this.state.libraryData.filter((library) => !this.state.removedAllData.includes(library))
        const programData = this.state.programData.filter((library) => !this.state.removedAllData.includes(library))

        return (

            <LibraryBox >
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6} lg={6}  >
                        <Box style={{
                            display: "flex", flexDirection: "column", gap: "24px",
                            boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                            height: "695px", padding: "20px", borderRadius: "16px"
                        }}>
                            <Box>
                                <Typography style={headStyle}>Library</Typography>
                            </Box>
                            {this.state.isCourseActive === "course" ? this.libraryDataCheck(this.state.libraryData, libraryData)
                                :
                                this.libraryDataCheck(this.state.programData, programData)
                            }
                            <Box style={webStyle.addButtonBoxstyle}>
                                <Button
                                    style={webStyle.addButtonStyle}
                                    onClick={this.handleAddButtonClick}
                                    data-test-id="testing">
                                    <Typography
                                        style={typo2}>Add</Typography>
                                    &nbsp;&nbsp;&nbsp;
                                    <img src={rightArrow}
                                        alt="arrow"
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            boxShadow: " 0px 2px 8px 0px rgba(0, 0, 0, 0.07)",
                            height: "695px", padding: "20px", gap: "16px"
                            , top: "616px", left: "134px", borderRadius: "16px"
                        }} >
                            <Box style={{ width: "100%" }}>
                                <Typography style={headStyle}>Assigned</Typography>
                            </Box>
                            {this.renderProgramCLickedData()}
                        </Box>
                    </Grid>
                </Grid>
            </LibraryBox>
        )
    }

    render() {
        return (
            <>
                <Box sx={gridStyle} style={{ display: "flex", flexDirection: "row" }}>
                    {this.renderGroupAssigneeTextFieldBox()}
                </Box>
                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderCoursesProgramSearchBox()}
                </Grid>
                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderDataBox()}
                </Grid>
                <MainButton   >
                    <SaveAndCreate
                        data-test-id="saveChangesBtn"
                        onClick={() => {
                            this.postUserAssign(this.state.removedAllData);
                        }}
                        style={this.state.removedAllData.length ? webStyle.saveChangesEnableBtn : webStyle.saveChangesDisableBtn}
                        disabled={!this.state.removedAllData.length}
                    >
                        <Typography style={typo}>Save Changes</Typography>
                    </SaveAndCreate>
                </MainButton>
            </>
        )
    }
}

const thumb_img = {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #929090",
    boxShadow: "0px 2px 14px 0px #8C8C8CB6"
}

const headStyle = {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "33px",
    color: "#1C1B1B"
};

const typo = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "none" as 'none'
}

const typo2 = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "capitalize" as 'capitalize',
    color: "#FFFFFF"
}

const gridStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    padding: "24px",
    flexWrap: "wrap",
    width: "100%",
    borderRadius: "18px",

};
const webStyle = {
    addButtonBoxstyle: {
        display: "flex",
        padding: "16px 16px 20px",
        justifyContent: "flex-end",
        alignItems: "end",
        gap: "24px"
    },
    removeButtonBoxstyle: {
        display: "flex",
        width: "100%",
        height: "74px",
        padding: "16px 16px 20px",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "24px",
    },
    addButtonStyle: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        backgroundColor: "#652786",
        borderRadius: "12px",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
    },
    removeButtonStyleBefor: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid #E5E2E1",
        color: "#929090",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "16px",
        backgroundColor: "#FFFFFF"
    },
    removeButtonStyleAfter: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid #E47004",
        color: "#E47004",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "16px",


    },
    staticDataStyle: {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#1C1B1B",
    },
    saveChangesDisableBtn: {
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #E5E2E1",
        borderRadius: "16px",
        color: "#929090",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        width: "411px",
        height: "56px"
    },
    saveChangesEnableBtn: {
        width: "411px",
        height: "56px",
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #E47004",
        borderRadius: "16px",
        color: "#E47004",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,

    },
    roleButtonStyle1: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#E5E2E1",
        borderRadius: "16px",
        color: "#929090"
    },
    roleButtonStyle2: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#652786",
        borderRadius: "16px",
        color: "#fff"
    },
    statusNew: {
        display: 'flex',
        width: "125px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "88px",
        background: "#E0D4E7",
        color: "#542070",
        textTransform: "capitalize" as 'capitalize',
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },
    statusArchived: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        background: "#FFD9DF",
        borderRadius: "88px",
        color: "#E70E6B",
        textTransform: "capitalize" as 'capitalize'
    },
    statusDraft: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        gap: "10px",
        alignItems: "center",
        background: "#FFEDE4",
        borderRadius: "88px",
        color: "#E47004",
        textTransform: "capitalize" as 'capitalize'
    },
}
const MainButton = styled(Box)({
    display: "flex",
    padding: "20px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexDirection: "column",
});

const SaveAndCreate = styled(Button)({
    width: "411px",
    height: "56px",
    padding: "20px 32px",
    "@media (max-width:960px)": {
        width: "240px",
        height: "56px",
        padding: "10px 21px",
    },
})

const TitleData = styled("div")({
    color: "#484646",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: "capitalize" as 'capitalize',
    "@media (max-width:960px)": {
        wordBreak: "break-all",
        width: "56px",
    },
});

const Statuses = styled("div")({
    backgroundColor: "#E0D4E7",
    textAlign: "center",
    padding: "6px 9px",
    color: "#542070",
    fontSize: "14px",
    borderRadius: "88px",
    gap: "10px",
    textTransform: "capitalize",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    width: "125px",
    height: "40px",
    "@media (max-width:960px)": {
        fontSize: "10px",
        padding: "6px 9px",
    },
});

const TabGrid = styled(Box)({
    display: "flex",
    flexWrap: "wrap"
})

const LibraryBox = styled(Box)({
    gap: "10px",
    width: "100%",
})

const ToggleBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: "12px ",
    cursor: "pointer",
    flexWrap: "wrap"
});

const FiterImage = styled("img")({
});

const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    width: "100%",
    "& .filterIconBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FAFAFA",
        width: "63px",
        borderRadius: "8px",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
        cursor: "pointer",
        border: "1px solid #C9C6C5",
        position: "relative",
        zIndex: 999,
        left: "-1%"
    },
    "& .filterIconBox:hover": {
        borderColor: '#652786'
    },
    "& .allBorder": {
        borderColor: '#652786',
        backgroundColor: "#EEE8F2",
    }
});

const ToggleInnerbox = styled(Box)({
    color: "#1c1818",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const BadgeComponent = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "-14px",
        left: "20px",
        right: " 0px",
        backgroundColor: " #FF883F",
        fontSize: "81px",
        width: "10px",
        height: "10px",
        borderRadius: "20px"
    }
});

const Addedimg = styled("img")({
    "@media (max-width:960px)": {
        width: "250px"
    },
})

const NoDataImg = styled("img")({
    height: "80%",
    width: "80%",
    "@media (max-width:960px)": {
        width: "250px"
    },
})

const InputMainBox = styled(Grid)({
    '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px',
    }
});

const StyledDropdownClickBoxs = styled('div')({
    width: "392px",
    height: "52px",
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "16px 20px !important",
    border: "1px solid #E5E2E1",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    position: "relative",
    "@media (max-width:1000px)": {
        width: "100%",
        height: "52px",
    },
    "&.selectCommonStyle .contentContainer": {
        display: "flex",
        gap: "3px",
        width: "100%"
    },
    "&.selectActive": {
        backgroundColor: "#EEE8F2",
    },
    "&.selectActive .title": {
        color: "#652786"
    },
    "&.creationDate": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .contentRole ": {
        width: "100% !important",
        display: "flex !important",
        justifyContent: "space-between !important"
    },
    "& .innerContentBox": {
        display: "flex",
        gap: "3px",
        fontSize: "16px",
        fontWeight: 500,
        color: '#ADAAAA'
    },
    "& .title": {
        whiteSpace: "nowrap",
        fontSize: "16px",
        fontWeight: 400,
        color: "#484646",
        fontFamily: "Poppins, sans-serif"
    },
    "& .arrowIconStyle": {
        color: "#652786"
    },
    "&:focus": {
        outline: "none",
        border: "1px solid #652786"
    },
    "&:hover": {
        border: "1px solid #652786"
    }
});

const DropdownContent = styled(MaterialUIBox)({
    position: "absolute",
    top: "100px",
    zIndex: 9999,
    background: "#fff",
    width: "100%",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    padding: "8px 16px",
    borderRadius: "12px",
    "& .checkboxList": {
        marginTop: "5px",
        flexDirection: "column",
        display: "flex",
        gap: "8px",
        marginBottom: "5px"
    },
    "& .checkboxContent": {
        display: "flex",
        alignItems: "center",
        fontFamily: "Poppins , sans-serif",
        borderBottom: "1px solid transparent",
        textTransform: "capitalize"
    }, "& .MuiTypography-body1": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        color: "#1C1B1B"
    },
    "& .checkboxContent:hover": {
        borderBottom: "1px solid transparent",
        backgroundColor: "#EEE8F2",
        borderRadius: "8px",
    },
    "& .selectedStyle": {
        borderBottom: "1px solid transparent !important",
        backgroundColor: "#EEE8F2",
        borderRadius: "8px",
    },
    "& .selectedStyle .MuiTypography-body1": {
        color: "#542070 !important"
    },
    "& .applyBtn": {
        display: "block",
        margin: "auto",
        textTransform: "capitalize",
        color: "#542070",
        fontSize: "16px"
    },
    "& .MuiFormControlLabel-root": {
        margin: 0,
        borderBottom: "1px solid transparent",
        fontFamily: "Poppins, sans-serif"
    },
});

export default UserAssignContent;
// Customizable Area End