// Customizable Area 
import React from "react";

import {
  Button,
  InputLabel,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Card,

} from "@material-ui/core";

//@ts-ignore
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Publish, Close } from "@material-ui/icons";
import { Formik } from 'formik';
import * as yup from 'yup';
import CourseCreationController, {
} from "./CourseCreationController.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DynamicTitle from "../../../components/src/DynamicTitle";
import CloseIcon from "@material-ui/icons/Close";
import './CourseCreation.css';
import { Plus, upload, Vector } from "./assets";
import { MdOutlineLocationSearching } from "react-icons/md";
//@ts-ignore
const styles: any = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });
//@ts-ignore
export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
class AddReward extends CourseCreationController {
  constructor(props: any) {
    super(props);
  }
  // handleFileChange(e: any) {

  //   this.setState({//@ts-ignore
  //     imgVidFile: e.target.files[0], imgVidUrl: URL.createObjectURL(e.target.files[0]), filename: e.target.files[0].name
  //   })
  // }
  // handleSubmit(data: any) {

  // }
  selected(index: any) {
    //@ts-ignore
    if (this.state.data.some((item) => item.selected === true)) {
      //@ts-ignore
      this.state.data[index].selected = !this.state.data[index].selected;
      this.setState({ data: this.state.data });
    }
  }
  add() {
    //@ts-ignore
    this.state.data.push({ content: "", selected: false, file: "" });
    //@ts-ignore
    this.setState({ data: this.state.data });
  }
  // onChange(e: any, index: any) {

  //   if (e.target.value === "") {
  //     //@ts-ignore
  //     this.state.data[index].content = "";
  //     //@ts-ignore
  //     this.setState({ data: this.state.data });
  //   }
  //   if (e.target.name === "content") {
  //     //@ts-ignore
  //     this.state.data[index].content =
  //       //@ts-ignore
  //       this.state.data[index].content === ""
  //         ? e.target.value
  //         : "" + e.target.value;
  //     //@ts-ignore
  //     this.setState({ data: this.state.data });
  //   } else if (e.target.name === "file") {
  //     //@ts-ignore
  //     this.state.data[index].file = e.target.files[0];
  //     //@ts-ignore
  //     this.setState({ data: this.state.data });
  //   }
  // }

  // remove(index: any) {
  //   //@ts-ignore
  //   this.state.data.splice(index, 1);
  //   //@ts-ignore
  //   this.setState({ data: this.state.data });
  // }
  // removeImage = (index: any) => {
  //   this.state.data[index].file = "";
  //   this.setState({ data: this.state.data })
  // }
  // Open = () => {
  //   //@ts-ignore
  //   document.getElementById('get_file').onclick = function () {
  //     //@ts-ignore
  //     document.getElementById('input_file').click();
  //   }
  // }

  render() {
    const onImageChange = (event: any) => {
      this.setState({
        rewardImg: event.target.files[0]
      })

    }


    const checkMessage = (content: any) => {
      if (content == "") {
        return 'Add Object'
      } else if (content.length > 30) {
        return `${content.substring(0, 30)}...`
      } else {
        return content
      }
    }

    return <>
      <DynamicTitle title="Add Reward" />
      <div className="add-reward">
        <Formik
          initialValues={{
            name: "",
            description: "",

          }}
          data-test-id="formik-add-award"
          onSubmit={(values) => {
            this.addReward(values);

          }} /*  */
          validationSchema={yup.object().shape({
            name: yup.string().required('Please enter name')
              .min(5, 'Name is too short (minimum is 5 characters)'),
            description: yup.string().required('Please enter description')
              .min(50, 'Description is too short (minimum is 150 characters)')
              .max(1500, 'Description is too long (maximum is 1500 characters)'),
          })}

        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
            } = props;
            return (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Grid container md={12} xs={12} spacing={4}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      placeholder="Reward name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* <TextField
                      type="text"
                      variant="outlined"
                      placeholder="Search Existing Rewards/Achievements"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      disabled
                    /> */}
                  </Grid>
                </Grid>
                <Grid container md={12} xs={12} spacing={4}>
                  <Grid item md={12} xs={12}>
                    <InputLabel htmlFor="reward-desc" className="input-label" style={{ marginBottom: "10px" }}>Reward Description</InputLabel>
                    <TextField
                      fullWidth
                      id="course_desc"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                      variant="outlined"
                      placeholder="Please write your description here"
                      multiline
                      rows={10}
                      maxRows={12}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                </Grid>
                {/* <Grid container spacing={4}>
                  <Grid item md={5} xs={12} className="icon-library">
                    <InputLabel className="input-label" htmlFor='branch'>
                      Select Branch
                    </InputLabel>
                    <FormControl className="select-filter">
                      <Select
                        labelId="branch"
                        id="branch"
                        name='branch'
                        variant="outlined"
                        IconComponent={() => null}
                        endAdornment={
                          <InputAdornment position="end">
                            <img src={SelectArrow} />
                          </InputAdornment>

                        }
                        value=""
                        fullWidth>
                        <MenuItem value="" selected>
                          <em>Select Branch</em>
                        </MenuItem>
                        <MenuItem value={1}>Branch 1</MenuItem>
                        <MenuItem value={2}>Branch 2</MenuItem>
                        <MenuItem value={3}>Branch 3</MenuItem>
                        <MenuItem value={4}>Branch 4</MenuItem>
                        <MenuItem value={5}>Branch 5</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item md={3} xs={12} className="icon-library">
                    <InputLabel className="input-label" htmlFor='status'>
                      Status
                    </InputLabel>
                    <FormControl className="select-filter">
                      <Select
                        labelId="status"
                        id="status"
                        name='status'
                        variant="outlined"
                        IconComponent={() => null}
                        endAdornment={
                          <InputAdornment position="end">
                            <img src={SelectArrow} />
                          </InputAdornment>

                        }
                        value=""
                        fullWidth>
                        <MenuItem value="" selected>
                          <em>Status</em>
                        </MenuItem>
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"InActive"}>InActive</MenuItem>

                      </Select>
                    </FormControl>

                  </Grid>
                </Grid> */}

                <Grid item container md={12} xs={12} spacing={4}>
                  <Grid item container md={5} xs={12}>
                    <p className="inner-heading">Please add content for this reward</p>

                    <div className="outer-border">
                      {
                        //@ts-ignore
                        this.state.data.map((item, index) => (

                          <>
                            <Grid item md={12} xs={12}>
                              <div className="inner-container" >
                                <div className="inner-border" data-test-id = {`selected_index-${index}`} style={{ cursor: "pointer" }} onClick={() => {
                                  this.selected(index);
                                }}>

                                  <p data-test-id="checkMessage" className="inner-text" >{checkMessage(item.content)}</p>
                                  {
                                    item.file === "" ?
                                      <img src={Vector} /> : <img style={{ width: '40px' }} src={URL.createObjectURL(item.file)} />
                                  }
                                </div>
                              </div>
                            </Grid>
                            {/* <Dialog
                              open={item.selected}
                              // onClose={() => this.selected(index)}
                              aria-labelledby="form-dialog-title"
                            >

                              {//@ts-ignore
                                <DialogTitle
                                  id="customized-dialog-title"

                                  onClose={() => this.selected(index)}
                                  data-test-id = {`${'selected_index_close-'[index]}`}
                                > Please add content and image</DialogTitle>}

                              <DialogContent>
                                <Grid container md={12} xs={12} style={{ marginTop: "10px" }}>
                                  <Grid item md={12}>
                                    <TextField
                                      name="content"
                                      value={item.content}
                                      data-test-id = "on_change_data"
                                      onChange={(e) => this.onChange(e, index)}
                                      type="text"
                                      variant="outlined"
                                      placeholder="Add Content"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth

                                    />
                                  </Grid>

                                </Grid>
                                <Grid container md={12} xs={12} style={{ marginTop: "10px" }}>
                                  <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                                    <Button style={{
                                      background: "#6C328B",
                                      color: "#fff"
                                    }}
                                      id="get_file"
                                      onClick={() => this.Open()}>
                                      <input
                                        name="file"
                                        type="file"
                                        id="input_file"

                                        hidden
                                        onChange={(e) => this.onChange(e, index)}
                                      />
                                      <Publish />
                                    </Button>

                                  </Grid>
                                </Grid>
                                <Grid container md={12} xs={12} >
                                  <Grid item md={12} xs={12} style={{ margin: "30px", textAlign: 'center' }}>
                                    {item.file === "" ? <>


                                    </> : <>
                                      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} className="custom-card-preview" >
                                        <div style={{ minWidth: "200px", maxWidth: "200px", marginBottom: "25px", textAlign: "center" }} >
                                          <Card >
                                            <div
                                              className='card-header'


                                            >
                                              <IconButton data-test-id="removeImage" aria-label="close" onClick={() => this.removeImage(index)}>
                                                <Close />
                                              </IconButton>
                                            </div>
                                            <img className="select-img-preview" src={
                                              URL.createObjectURL(item.file)
                                            } />
                                          </Card>
                                          <p className='file-name'>{item.file.name}</p>
                                        </div>
                                      </div>
                                    </>}

                                    <Button style={{
                                      background: "#6C328B",
                                      color: "#fff",
                                      width: "150px",
                                      height: '40px'
                                    }}
                                      // onClose={() => this.selected(index)}
                                      data-test-id = {`${'selected_index-'[index]}`}
                                      onClick={() => this.selected(index)}>
                                      Save
                                    </Button>

                                  </Grid>
                                </Grid>
                              </DialogContent>
                            </Dialog> */}

                          </>
                        ))
                      }
                      {/* <Grid item md={12} xs={12}>
                                    <div className="inner-container">
                                     <div className="inner-border">
                                        <p className="inner-text">Add Object</p>
                                        <img src={Vector}/>
                                     </div>
                                    </div>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div className="inner-container">
                                    <div className="inner-border">
                                        <p className="inner-text">Add Object</p>
                                        <img src={Vector}/>
                                    </div>
                                    </div>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div className="inner-container">
                                    <div className="inner-border">
                                        <p className="inner-text">Add Object</p>
                                        <img src={Vector}/>
                                    </div>
                                    </div>
                                </Grid> */}
                      <Grid item md={12} xs={12}>
                        <div className="inner-container">
                          <Button data-test-id="add_more_btn" className="add-more-btn" onClick={() => this.add()}>+ Add more</Button>
                        </div>
                      </Grid>
                    </div>

                  </Grid>

                  {/* <Grid item container md={6} xs={12} >
                                
                                <div className="dashed-border-achievement">
                                    <Grid item  md={2}></Grid>
                                    <Grid item  md={8} xs={12}>
                                        <div className="inner-border2">
                                        <img src={Library} />
                                        <p className="text">
                                        Choose video from content Library
                                        </p>
                                        </div>

                                    </Grid>
                                    <Grid item  md={2}></Grid>
                                </div>
                            </Grid> */}
                  <Grid item container md={7} xs={12} >
                    <p className="inner-heading">Upload Thumbnail</p>
                    <div className="dashed-border">
                      <Grid item md={1}></Grid>
                      <Grid item md={5} xs={12}>
                        <div data-test-id="btn_tostSuccessfully" onClick={() => this.tostSuccessfully()} className="inner-border2">
                          <img src={Plus} height="50" />
                          <p className="text">
                            Choose from icon Library
                          </p>
                        </div>

                      </Grid>
                      <Grid item md={1} >
                        <p className="or-text">Or</p>
                      </Grid>


                      <Grid item md={5} xs={12}>
                        <div className="inner-border2" >
                          <label htmlFor="uploadFile" style={{ display: "block" }}>
                            <input type="file" name="files" id="uploadFile" style={{ visibility: "hidden", display: 'none' }} data-test-id="onImageChange_file" accept="image/*" onChange={onImageChange} />

                            {this.state.rewardImg == "" ? <img src={upload} /> : <img width={200} src={URL.createObjectURL(this.state.rewardImg)} />}

                          </label>
                          <p className="text">
                            {this.state.rewardImg == "" ? "Upload from device" : 'Edit Image'}
                          </p>

                        </div>
                      </Grid>
                      <Grid item md={1}></Grid>

                    </div>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={4}>
                  <Grid item md={5} xs={12}>
                  <Typography className={"user-heading"}>Requirements</Typography>      
                  <InputLabel className="input-label">Course/Program Required</InputLabel>
                    <div className='' style={{ display: "flex", flexDirection: "row" }}>
                    <RadioGroup aria-label="required" name="required" row >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    
                    </div>


                  </Grid>
                  <Grid item md={3} xs={12}>
                  <InputLabel className="user-heading" style={{textAlign:"start"}}>Settings</InputLabel>
                  <div className="outer-box">
                  <InputLabel className="input-label">Due Date</InputLabel>
                    <div className='' style={{ display: "flex", flexDirection: "row" }}>
                    <RadioGroup aria-label="required" name="required" row >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>

                  </div>


                  </Grid>
                  <Grid item md={1} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <InputLabel className="input-label">Or</InputLabel>
                  </Grid>
                  <Grid item md={3} xs={12} style={{marginTop:"34px"}}>
                    <div className="outer-box">
                    <InputLabel className="input-label">Require Days to Complete</InputLabel>
                    <div className='' style={{ display: "flex", flexDirection: "row" }}>
                    <RadioGroup aria-label="required" name="required" row >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    </div>
                    </div>
                  </Grid>

                </Grid> */}
                <Grid container md={12} xs={12}>
                  <Grid item md={12} xs={12} style={{ padding: "30px", textAlign: "center" }}>
                    <Button className="create-reward-btn" type="submit">Create Reward</Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>


      </div>

    </>
  }
}
export default AddReward;
// Customizable Area End