// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../utilities/src/NetworkRequest";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";

const configJSON = require("./config.js");

export interface UserResponse {
  id: string;
  attributes: UserAttributes;
}

export interface UserAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  email: string | null;
  phone_number: number;
  country_code: number;
  prefix_title: string | null;
}

interface CountryData {
  id: string,
  type: string,
  attributes: {
    name: string,
    emoji_flag: string,
    country_code: string   
  }  
}

export interface ResponseContentData {
  id: string
  data: [];
};

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  isLoading: boolean;
  selectOpen:boolean;
  CountryFlagCode:Array<CountryData>;
  account_id: number;
  firstName:string;
  middleName:string;
  lastName:string;
  title:string;
  email:string;
  phoneNumber:number;
  countryCode:number;
  UserData:Array<UserResponse>;
}

interface SS {
  id: any;
}

export default class EditUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCountryCodeFlagCallId: string= "";
  updateUserApiCallId: string = "";
  userListApiCallId: string = "";
  handleSetSubmittingCallback: Function = () => { };

  private childRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      selectOpen: false,
      CountryFlagCode:[],
      account_id: 0,
      firstName:"",
      middleName:"",
      lastName:"",
      title:"",
      email:"",
      phoneNumber:0,
      countryCode:0,
      UserData:[],
    };
    this.childRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.editCountryCodeApi();
    let userData = this.props?.navigation?.getParam('id')
    this.getUserList(Number(userData));
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.userListApiCallId) {
        this.setState({
          UserData: responseJson.data,
          firstName: responseJson.data.attributes.first_name,
          middleName: responseJson.data.attributes.middle_name,
          lastName: responseJson.data.attributes.last_name,
          title: responseJson.data.attributes.prefix_title,
          email: responseJson.data.attributes.email,
          countryCode: responseJson.data.attributes.country_code,
          phoneNumber: responseJson.data.attributes.phone_number,
          isLoading: false
        })
      }

      if (apiRequestCallId === this.updateUserApiCallId) {
        this.handleEditUserResponse(responseJson);
      }

      if (apiRequestCallId === this.getCountryCodeFlagCallId) {
        this.getCountryRes(responseJson);
      }
    }
  }

  handleEditUserResponse = (response: any) => {
    if (response?.data) {
      this.props.navigation.navigate("UserManagement");
      this.handleSetSubmittingCallback(false);
      toast.success("Changes Saved");
      return;
    }
    this.setState({ isLoading: false });
      if (response?.errors) {
        response.errors.map((err: { message: string }) => {
          toast.error(err.message);
      });
    }
  };
  
  handleSelectOpen = () => {
    this.setState({selectOpen:true})
  };
  
  handleSelectClose = () => {
    this.setState({selectOpen:false})
  };


  getCountryRes=(responseJSON:ResponseContentData)=>{
  this.setState({CountryFlagCode:responseJSON.data})
  }

  editCountryCodeApi=async()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    this.getCountryCodeFlagCallId = requestMessage.messageId
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.CountryFlagApiEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getUserList = async (userId: number) => {
    this.setState({ isLoading: true });
    this.userListApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.userListEndPoint}/${userId}`,
    });
  };

  updateUserForm = async ( event?: any) => {
    let userData = this.props?.navigation?.getParam('id')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const userApiData = {
      "account": {
          "first_name": event.firstName,
          "middle_name": event.middleName,
          "last_name": event.lastName,
          "prefix_title": event.teamId,
          "full_phone_number": `${event.CountryCode}${event.phone}`
    }
  }
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(userApiData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.createUserEndPoint}/${userData}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

getEditColor = (selectedValue: string | undefined, option: string) => {
  return selectedValue?.toString() === option ? '#652786' : 'inherit';
}
hasEditError = (touched: boolean | undefined, error: any) => touched && Boolean(error);
}
// Customizable Area End