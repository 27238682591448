import React from "react";
// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Box, Dialog,
  styled,
  Popover,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Table,
  withStyles
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Badge from "@material-ui/core/Badge";
import Typography from '@material-ui/core/Typography';
import { newCourse, newProgram, Filter, NotFoundImage, NewFilter, newContentRole } from "./assets";
import FilterItems from "../../filteritems/src/FilterItems.web";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
import "./Catalogue.css";
import { ContentRolesType } from "./CatalogueController";
import moment from "moment";
import { DownArrowIcon, UpArrowIcon, archived,  duplicate, editNew, optionIcon} from "../../../components/src/Table/asset";
import EnhancedTable from "../../../../packages/components/src/Table";
import SaveAndDiscardChangesModal from "../../../../packages/components/src/SaveAndDiscardChangesModal";
export const configJSON = require("./config");
import CoursePreviewWeb from "./CoursePreview.web";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  generateToggleBoxStyle(isActive: boolean) {
    return {
      backgroundColor: isActive ? "#FFF" : "#fcf6f6",
      height: "48px",
      boxShadow: isActive ? "-3px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
      color: isActive ? "#000" : "",
      display: "flex",
      alignItems: "center",
      fontWeight: isActive ? 700 : 400,
      justifyContent: "center",
      borderRadius: "10px",
      cursor: "pointer",
      
    };
  }

  generateNewToggleBoxStyle(isActive: boolean) {
    return {
      backgroundColor: isActive ? "#FFF" : "#fcf6f6",
      height: "48px",
      boxShadow: isActive ? "-3px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
      color: isActive ? "#000" : "",
      display: "flex",
      alignItems: "center",
      minWidth: "170px",
      maxWidth: "200px",
      fontWeight: isActive ? 700 : 400,
      justifyContent: "center",
      borderRadius: "10px",
      cursor: "pointer",
      gap: "10px",
      padding: "14px 24px",
      // border:"1px solid black",

      
    };
  }

  renderToggleBox() {
    const { isCourseActive, filterItems, anchorFilterContainerEl } = this.state;
    
    const courseToggleStyle = this.generateToggleBoxStyle(isCourseActive === "course");
    const programToggleStyle = this.generateToggleBoxStyle(isCourseActive === "program");
    const contentRoleToggleStyle = this.generateNewToggleBoxStyle(isCourseActive === "contentRole");
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl)

    return (
      <Grid item container md={12} style={{display:"flex" , alignItems:"center"}}>
        <TabGrid item md={6} xs={12} lg={4}>
          <ToggleBox >
            <ToggleInnerbox data-test-id="courseToggleId" style={courseToggleStyle} onClick={this.handleToggleCourses}>
              Courses
            </ToggleInnerbox>
            <ToggleInnerbox style={programToggleStyle} onClick={this.handleTogglePrograms} data-test-id="programToggleId">
              Programs
            </ToggleInnerbox>
            <ToggleInnerbox style={contentRoleToggleStyle} onClick={this.handleToggleContentRole} data-test-id="contentRoleToggleId">
              Content Role
            </ToggleInnerbox>
          </ToggleBox>
        </TabGrid>
        <InputMain item md={6} xs={12} lg={6} style={{ display: 'flex', alignItems: 'center', borderRight: "none", justifyContent: "center" }}>
          <StyledContentWrapper  
         >
            <AdvancedSearch
              data-test-id="btn_change"
              isFilterDropActive={openFilterContainer}
              handleListItemClick={this.handleListItemClickSuggestion}
              searchInputValue={this.state.searchInputValue}
              handleInputChange={this.handleInputCatalogueChangeSearch}
              filterList={this.state.filteredList}
              showList={this.state.showList} navigation={undefined} id={""} />
            <div style={{ marginLeft: "0px" }} className={anchorFilterContainerEl ? "filterIconBox allBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
              {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
                <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                ""
              )
              }
              {anchorFilterContainerEl ? (
                <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={NewFilter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover
              data-test-id="filterPropsID"
              open={openFilterContainer}
              className="filterContainerPopover"
              id="myPopover"
              onClose={this.filterContainerDropdownCloseHandler}
              anchorEl={this.state.anchorFilterContainerEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <FilterItems
                isContentRole={this.state.contentRoleTrue}
                data-test-id="FilterItemsTestID"
                selectedStatus={this.state.selectedStatus}
                handleChangeStatus={this.handleCatalogueChangeStatus}
                handleClick={this.handleClickFilter}
                contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandlerChange}
                handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
                categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
                handleCategorySearchChange={this.handleCategoryChangeSearch}
                categorySelectedItems={this.state.categorySelectedItems}
                contentRoleSearchText={this.state.contentRoleSearchText}
                contentRoleSelectedItems={this.state.contentRoleSelectedItems}
                categorySearchText={this.state.categorySearchText}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
                creationDateChangeHandler={this.creationChangeHandlerDate}
                dropdownOpenHandler={this.dropdownHandlerOpen}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendor}
                anchorEl={this.state.anchorEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderSearchText={this.state.placeholderSearchText}
                creationDate={this.state.creationDate}
                expertAdminList={this.state.expertAdminList}
                adminCatagoryList={this.state.adminCatagoryList}
                adminContentRolesList={this.state.adminContentRolesList}
                isCalendarOpen={this.state.isCalendarOpen}
                handleClear={this.handleClearFilter}
                handleCalendarChange={this.handleChangeCalendor} />
            </Popover>

          </StyledContentWrapper>
        </InputMain>
        <CreateNewGrid item md={6} xs={12} lg={2}>
          <Box style={Styles.Accordion} className="btnBox">
            <Box>
              <Dialog
                data-test-id="Tooltip"
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    margin: 'auto',
                    maxWidth: 'fit-content',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    zIndex: 9999,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: "10px"
                  },
                }}
              >
                <MainDialogueComponent spacing={2}>
                  <Grid data-test-id="btn_click" onClick={this.NewCourse} style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer", height: "100%", width: "100%", justifyContent: "center" }} item lg={12}>
                    <ImageComponentInner src={newCourse} />
                    <ButtonComponent>
                      New Course           
                    </ButtonComponent>
                  </Grid>
                  <BorderStyle/> 
                  <GridComponentTwo data-test-id="btn_click1" onClick={this.NewProgram} item lg={12}>
                    <ImageComponentInnerTwo src={newProgram} />
                    <ButtonComponentTwo>
                      New Program    
                    </ButtonComponentTwo>
                  </GridComponentTwo>
                  <BorderStyle/>
                  <GridComponentTwo data-test-id="btn_click1" onClick={this.redirectContentRolePage} item lg={12}>
                    <ImageComponentInnerTwo src={newContentRole} />
                    <ButtonComponentTwo>
                      New Content Role
                    </ButtonComponentTwo>
                  </GridComponentTwo>
                </MainDialogueComponent>
              </Dialog>
            </Box>
            <Button onClick={() => this.handleClickOpen()}
              style={Styles.AccordionSummary}
              data-test-id='panel1a-content'
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <CreateNewVideoComponent>
                <span style={{ fontSize: "16px", fontWeight: 600, paddingRight: "10px", fontFamily: "Poppins, sans-serif" }}>
                  +
                </span>
                Create New
              </CreateNewVideoComponent>
            </Button>
          </Box>
        </CreateNewGrid>
      </Grid>
    );
  }

  enhancedTableData(){
return(
  <EnhancedTable
            data-test-id="tableTestId"
            prevPageExists={!!this.state.pagination.prevPage}
            nextPageExists={!!this.state.pagination.nextPage}
            handleNextPageNavigation1={this.handlePageNavigationNext}
            handlePrevPageNavigation1={this.handlePageNavigationPrev}
            data={this.state.data || []}
            enrolledUser={this.state.enrolledUser}
            getCourseData={(obje: { search: string; filter: string; page: string; per: string; }) => this.getCourse(obje, this.state.pagination)}
            contentType={this.state.contentType}
            meta={this.state.meta}
            search={this.state.text}
            handleSave={(courseId: string | number, idSav: number, expand_type: string | number) => this.handleEditSave(courseId, idSav, expand_type)}
            getUserEnrolled={(idEnr: string | number, expand_type: string | number) => this.getEnrolledUser(idEnr, expand_type)}
            deleteItem={(idDelete: string | number, expand_type: string | number) => this.deleteItemNew(idDelete, expand_type)}
            archivedItem={(idArchived: string | number, expand_type: string | number) => this.archivedItemNew(idArchived, expand_type)}
            className="table"
            isExist={this.state.isExist}
            handleInputChange={this.handleEditCourse}
            handleEditClick1={this.handleEditCourse}
            handleEditClick={this.handleEditCourse}
            gotoEditCourse={this.handleEditCourseData}
            handleDuplicate={this.handleDuplicate}
            handlePreview={this.handlePreview}
            isEditCourseIdOpen={this.state.isEditCourseIdOpen}

          />
)
  }
  renderTableOrNotFound() {
    let contentToRender;
    if (this.state.isCourseActive === "contentRole" &&  this.state.contentRolesData.length>0) {
      contentToRender = this.renderTableContentRole();
    }else if(( this.state.isCourseActive === "course" ||  this.state.isCourseActive === "program") && this.state.data.length>0){
      contentToRender = this.enhancedTableData();
    }
    else if(this.state.contentRolesData.length===0 || this.state.data.length===0){
        contentToRender = (
        <MainNotFoundBox>
        <MainInnerBox>
          <Box>
            <ImageComponent src={NotFoundImage} />
          </Box>
          <NotFoundText>No results founds</NotFoundText>
        </MainInnerBox>
      </MainNotFoundBox>)
      } 
  
  
    return contentToRender;
  }

  statusOptionData = (row:any) => {
    if(row.attributes.status ==="active"){
      return webStyle.statusNew
    }else if(row.attributes.status === "draft"){
      return webStyle.statusDraft
    }else {
      return webStyle.statusArchived
    }
  }

  renderTableContentRole = () => {
    return (
      <TableContainer style={{marginTop:"2%"}}>
        <Table style={{ minWidth: 750 , borderSpacing:"0px 15px",borderCollapse:"separate" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {this.state.contentRoleTableHeaderData.map((header, index) => {
                return (
                  <TableCell key={index} id={index===4?"last":""}  className={index === 0 ? "first borderNone" : "all borderNone"}>
                    <TableSortLabel direction={"asc"} style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                      <span  style={{ display: "flex", justifyContent: "space-between", whiteSpace: "nowrap", fontWeight: 600, fontStyle: "normal", lineHeight: "24px", color: "#000", fontFamily: "Poppins, sans-serif" }}>{header.name}</span>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column', 
                        marginLeft: '4px'
                      }}>
                        <img
                          src={UpArrowIcon}
                          alt="Up Arrow"
                          width="10px"
                          data-test-id={`testing${index}`}
                          style={{ paddingBottom: '5px', cursor: 'pointer' }}
                          onClick={() => this.handleSort(header.key)}
                        />
                        <img
                          src={DownArrowIcon}
                          alt="Down Arrow"
                          width="10px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.handleSort(header.key)}
                        />
                      </div>
                    </TableSortLabel>
                  </TableCell>
                )
              })}

            </TableRow>

          </TableHead>
          <TableBody>
            {this.state.contentRolesData.map((row: ContentRolesType) => (
              <TableRow style={webStyle.contentRoleTableStyle} key={row.id}
              >
                <TableCell style={{ maxWidth: "200px", wordBreak:"break-all",
                 borderBottom:"none",height:"72px",padding:"0 24px"}} >{row.attributes.name}</TableCell>
                <TableCell align="center" style={{ width: "300px",borderBottom:"none",height:"72px",padding:"0 24px"}}>
                  {row.attributes.uniq_id}
                </TableCell>
                <TableCell style={{ width: "300px",borderBottom:"none",height:"72px",padding:"0 24px"}} align="center">{row.attributes.lessons_count}</TableCell>
                <TableCell style={{width: "300px",borderBottom:"none",height:"72px",padding:"0 24px"}} align="center">{ moment.utc(row.attributes.updated_at).format('MM-DD-YYYY')}</TableCell>
                <TableCell style={{borderBottom:"none",height:"72px",padding:"0 24px"}} align="center">
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: "300px" }}>
                    <div style={this.statusOptionData(row)}
                    >
                      {row.attributes.status}
                    </div>
                    <LightNewTooltip
                      enterDelay={500}
                      leaveDelay={100}
                      interactive={true}
                      title={
                        <NewToolTipMain>
                          <NewToolTipInner>
                            <img src={editNew} />
                            <NewToolTipTextComponent>Edit</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          <NewToolTipInner>
                            <img src={archived} />
                            <NewToolTipTextComponent>Duplicate</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          <NewToolTipInner>
                            <img style={{ cursor: "pointer" }} src={duplicate} />
                            <NewToolTipTextComponent>Archive</NewToolTipTextComponent>
                          </NewToolTipInner>
                        </NewToolTipMain>
                      }
                      placement="bottom"
                    >
                      <TableCell
                        style={{
                          border: "none",
                        }}
                        align="right">
                        <img style={{ cursor: "pointer" }} src={optionIcon} />
                      </TableCell>
                    </LightNewTooltip>
                  </div>
                </TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     

    )
  }
  renderduplicateConfirm() {
    return (
      <Box sx={continueButtonContainer}>
          <>
            {this.state.handleDuplicateOpen && 
              <SaveAndDiscardChangesModal
              data-test-id="duplicate-content-modal"
              open={this.state.handleDuplicateOpen}
              onClose={this.handleCloseDuplicate}
              description={configJSON.duplicateSubText}
              heading={configJSON.duplicateHeadingTxt}
              rightButtonText={configJSON.duplicateText}
              leftButtonText={configJSON.cancelTxt}
              onLeftButtonClick={this.handleCloseDuplicate}
              onRightButtonClick={this.handleDuplicateSubmit} />             
            }               
          </>      
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container style={{ marginTop: '7px' }}>
          {this.renderToggleBox()}
          {this.renderTableOrNotFound()}
          {this.renderduplicateConfirm()}
          <CoursePreviewWeb 
          data-test-id="coursePreviewId"
          isPreview={this.state.isPreview} 
          dialogContent={this.state.coursePreviewData} handlePreviewClose={this.handlePreviewClose}
            gotoEditCourse={this.handleEditCourseData}
          gotoEditContent={this.handleEditCourseContent}
          />
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const continueButtonContainer = { display: "flex", flexDirection: "row", justifyContent: "center" }
const CreateNewVideoComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: ' flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize'
})
const webStyle = {
  table: {
    minWidth: 750,
    borderCollapse: "separate" as "separate",
    borderSpacing: "0 15px",
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 15px"
    },
  },

  statusNew: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#EEE8F2",
    color: "#652786",
    textTransform:"capitalize" as 'capitalize'
    
  },



  textalignleft: {
    paddingLeft: "0",
    textAlign: "left",
    border: "1px solid red"

  },
  statusDraft: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFEDE4",
    color: "#E47004",
    textTransform:"capitalize" as 'capitalize'


  },
  statusArchived: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFD9DF",
    color: "#E70E6B",
    textTransform:"capitalize" as 'capitalize'
  },

  contentRoleTableStyle: {
    height:"72px !important",
    borderRadius: "20px !important",
    borderSpacing:"0 15px !important",
    padding:"0px !important"
    
  },
}

const LightNewTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    border: '15px',
    height: 'auto',
    borderRadius: "14px",
  }
}))(Tooltip);

const StyledContentWrapper = styled(Box)({
  position: "relative",
  display: "flex",
  maxWidth: "520px",
  width: "100%",
 

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    width: "63px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
    left: "-1%"
  },
  "& .filterIconBox:hover": {
    borderColor: '#652786'
  },
  "& .allBorder": {
    borderColor: '#652786',
    backgroundColor: "#EEE8F2"
  }
});

const CustomTableRow = styled(TableRow)({
  borderRadius: '12px',
  marginBottom: '8px',
});

const MainDialogueComponent = styled(Grid)({
  height: "221px",
  backgroundColor: "#652786",
  width: "723px",
  "@media (max-width:960px)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "723px !important",
    backgroundColor: "#652786",
    width: "221px !important",
    flexDirection:"column"
  },
  
  "@media (min-width:720px)": {
    display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  },

});


const BorderStyle =styled('div')({

  "@media (max-width:960px)": {
    borderBottom: '1px solid #f3f0f0',
     width: '100%', 
     margin: '0 auto',
    borderLeft:"none !important",
    height:"0% !important",
  },
  "@media (min-width:720px)": {
    borderLeft: '1px solid #f3f0f0', margin: '0 auto',
    height:"100%",

  },

})

const ImageComponentInner = styled("img")({
  width: "48px !important",
  height: "48px !important"
});

const NewToolTipMain = styled("div")({
  width: "100%",

})

const NewToolTipTextComponent = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: 400,
  paddingLeft: "10px",
  color: "#484646",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontStyle: "normal",
  lineHeight: "24px"

})

const NewToolTipInner = styled("div")({
  display: "flex",
  width: "100%",
})

const HrComponent = styled("hr")({
  border: "1px solid #652786",
  margin: "6px 0",
  width: "100px"
})

const HrComponentNew = styled("hr")({
  border: "0.01px solid #E5E2E1",
  width: "100%",
  height: "1px"
})


const EditComponent = styled("div")({
  borderRadius: "8px !important",
  backgroundColor: "#fff",
  height: '12px',
  width: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
})

const EditInnerComponent = styled(Typography)({
  fontSize: "18px !important",
  color: "#6C328B",
  fontWeight: 400
})

const IconButtonComponent = styled(IconButton)({
  fontSize: "15px",
  color: "#6C328B"
})

const ContentRoleMain = styled(Box)({
  display: "flex",
  width: "100%",
  height: "20px",
  borderRadius: "40px"
})

const ContentRoleInner = styled(Typography)({
  color: "#000",
  fontSize: "15px",
  display: "inline"
})

const UserEnrollMain = styled(Box)({
  margin: "0 20px",
  height: "270px",
})

const UserEnrollInner = styled(Typography)({
  color: "#000",
  fontSize: "11px",
  paddingRight: "5px"
})

const UserEnrollInnerHeading = styled(Typography)({
  color: "#000",
  fontWeight: 500,
  fontSize: '17px'
  ,
})

const CourseIdComponent = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center", "& .MuiInputBase-input": {
    padding: "6px 8px 7px"
  }
})

const GridComponentTwo = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  height: "100%",
  width: "100%",
  justifyContent: "center",
});

const ImageComponentInnerTwo = styled("img")({
  width: "48px !important",
  height: "48px !important"
});

const RenameComponent = styled(Typography)({
  fontSize: "14px !important",
  color: "#000",
  marginBottom: "10px !important",
  fontWeight: 400
})


const ButtonComponent = styled(Button)({
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "capitalize",
  fontFamily: "Poppins, sans-serif !important"
});

const ButtonComponentTwo = styled(Button)({
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "capitalize",
  fontFamily: "Poppins, sans-serif !important"
});

const InputMain = styled(Grid)({
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '0px',
  },

});

const CreateNewGrid = styled(Grid)({
  paddingRight: "0 !important",
  "& .btnBox": {
    width: "max-content",
    marginLeft: "auto"
  }
});

const TabGrid = styled(Grid)({
  paddingRight: "0 !important",
})

const DivVertical = styled("div")({
  width: "63px",
  border: "1px solid #C9C6C5",
  height: "54px",
  borderTopLeftRadius: "0px !important",
  borderBottomLeftRadius: "0px !important",
  borderRadius: "9px",
  '&:focus': {
    borderColor: '#652786'
  },
  '&:hover': {
    borderColor: '#652786'
  },
})

const SearchIcon = styled(Search)({
  color: "#ADAAAA"
})

const InputAdornmentComponent = styled(Box)({
  backgroundColor: "#EEE8F2",
  '&:focus': {
    borderColor: '#652786'
  },
  '&:hover': {
    borderColor: '#652786'
  },
});

const ToggleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  backgroundColor: "#fcf6f6",
  height: "48px",
  alignItems: "center",
  borderRadius: "12px ",
  cursor: "pointer",
  width:"100%"
});

const MainAdorment = styled("div")({
  backgroundColor: "#EEE8F2",
  width: "63px",
  borderRadius: "10px",
  borderTopLeftRadius: "0px !important",
  borderBottomLeftRadius: "0px !important",
  position: "relative",
  '&:focus': {
    borderColor: '#652786'
  },
  '&:hover': {
    borderColor: '#652786'
  },
});

const FiterImage = styled("img")({
});

const ToggleInnerbox = styled(Box)({
  color: "#1c1818",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width:"173px"
});

const BadgeComponents = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px"
  }
});

const ImageComponent = styled("img")({
  width: "100%",
  height: "100%"
});

const MainNotFoundBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%"
});

const MainInnerBox = styled(Box)({
  width: "727px",
  height: "540px",
  marginTop: "40px"
});

const NotFoundText = styled(Typography)({
  marginTop: "26px",
  textAlign: "center",
  color: "#1C1B1B !important",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "-1px"
});

const Styles = {
  Accordion: {
    height: '56px',
    borderRadius: '15px',
    boxShadow: 'none',
    position: 'relative',
  } as React.CSSProperties,
  accordionContent: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },
  AccordionDetails: {
    backgroundColor: '#fff',
    position: 'absolute',
    borderRadius: '10px',
    zIndex: 999,
  },
  Accordion_Typography: {
    margin: 'auto',
    fontStyle: 'normal',
    display: ' flex',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'capitalize',
  } as React.CSSProperties,
  AccordionSummary: {
    height: '56px',
    backgroundColor: '#6C328B',
    borderRadius: '15px',
    width: '192px',
  },
  course1_btns: {
    background: '#6C328B',
    height: "56px",
    borderRadius: "16px",
    width: "196px",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "16px",
    color: "#FFFFFF",
    textTransform: "capitalize"
  },
  course2_btns: {
    background: '#6C328B',
    borderRadius: "16px",
    width: "196px",
    height: "56px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "16px",
    color: "#FFFFFF",
    textTransform: "capitalize"
  }
}
// Customizable Area End