import React from "react";

import {
    Box,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    TextField,
    Switch,
    FormControl,
    MenuItem,
    Select,
    CircularProgress,
    Checkbox,
    FormControlLabel
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area 
//@ts-ignore
import { createStyles, Theme, withStyles, styled } from "@material-ui/core/styles";
import {ExpandMore,ExpandLess, AttachMoneyOutlined, ErrorOutlineOutlined } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from "formik";
import MaterialUIBox from '@material-ui/core/Box';
import CustomDatePicker from "../../../components/src/DatePicker";
import { startOfToday , addDays} from 'date-fns';
import './CourseCreation.css';
import Commonmanagement from "../../imagemanagement2/src/Commonmanagement.web"
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";

interface Errors {
    title?: string;
}
  
interface Touched {
    title?: boolean;   
}
const CustomMenuProps = {
    PaperProps: {
      style: {
        marginTop: '15px'  as const,
        marginLeft:"10px" as const,
        left: "10px"
      },
    },
    getContentAnchorEl: null
  };

export const configJSON = require("./config");

// Customizable Area End

import CourseCreationController, {
    Props,
} from "./CourseCreationController.web";
import { EDIT1_ICON } from "./assets";

// Customizable Area Start
class ProgramCreation extends CourseCreationController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount()
    }

    hasError (errors: Errors, touched: Touched, isSubmitted: boolean)  {
        return Boolean(errors.title && (isSubmitted || touched.title));
      };

    getHelperText (errors: Errors, touched: Touched, isSubmitted: boolean): string | undefined {
        if ((isSubmitted || touched.title) && errors.title) {
          return errors.title;
        }
        return undefined;
    };
    
    btnBackgroundColor = (values: {title:string,expert_id:string, add_thumbnail: string}) => {
        if (values.title !== "" && (values.title)?.length>=4 && values.expert_id !== "" && values.add_thumbnail !== "") {
          return "#4D0F6C"; 
        } else {
          return "#E5E2E1";  
        }
      };
      
      btnTextColor = (values: {title:string,expert_id:string, add_thumbnail: string}) => {
        if (values?.title !== "" &&  (values.title)?.length>=4 && values?.expert_id !== "" && values?.add_thumbnail !== "") {
          return "#FFFFFF";  
        } else {
          return "#929090";  
        }
      };

    renderSaveAsDraftModal() {
        return (
          <Box sx={continueButtonContainer}>
              <>
                {this.state.saveDraftContentModal && 
                 <DeleteModal
                 data-test-id='delete-all-content'
                 openDialog={this.state.saveDraftContentModal}
                 headingText={configJSON.saveAsDraftHeadding}
                 btnCancelText={configJSON.cancelTxt}
                 btnOkText={configJSON.saveAndContinueBtn}
                 subHeading={configJSON.saveAsDraftSubText}
                 handleCancel={this.handleCloseSaveAsDraft}
                 handleOk={this.doCreateProgram1} 
               />
                          
                }               
              </>      
          </Box>
        );
      }
      renderPlaceholderDropdownContent(allExperts:any,values:any) {
        return <DropdownContent onMouseLeave={this.dropdownHandlerClose}>
          <TextFieldSearch
            variant="outlined"
            placeholder="Search"
            data-test-id="search_value"
            value={this.state.placeholderSearchText}
            onChange={(event)=>this.handlePlaceholderSearchChange(event)}
            InputProps={{
              startAdornment: (
                <SearchIcon />
              ),
            }}
          />
          <MaterialUIBox className="checkboxListsCss" data-test-id="expertsList">
            { allExperts.filter((option: any) => option.attributes.full_name.toLowerCase().includes(this.state.placeholderSearchText.toLowerCase())).map((option:any) => {
              return (
                <FormControlLabel  key={option.id} className={values?.expert_id?.toString()===option.id? "checkboxContentCss selectedStyles" : "checkboxContentCss"} control={<CheckboxMain  data-test-id={`select_expert_id${option.id}`} checked={values?.expert_id?.toString()===option.id? true : false} name={option.attributes.full_name} onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.placeholderCheckboxChangeHandler(event, option.id)}/>} label={option.attributes.full_name} />
              )
            })}
          </MaterialUIBox>
        </DropdownContent>
      }
      renderNotifyValues=(selected:any)=>{
  
        if (selected?.length === 0) {
          return <em style={{fontStyle:"normal"}}>Select user</em>;
            }
            let selectedLabels = this.state.programUserList
            .filter((option:any) => selected.includes(option.id))
            .map((option:any) => option.attributes.full_name);
            if (selectedLabels.length === 1) {
             return <span>{selectedLabels[0]}</span>;
           } 
           else {
             return <span>{selectedLabels[0]} & {selectedLabels.length - 1} more</span>;
           }
      }
      renderThumbnail=(values:any)=>{
        return(
            <StyledBoxThumbnail style={{ display: "flex", gap: "20px" }}>
                    <Button
                      data-test-id="thumbnailButton"
                      className="changethumb-btn"
                      onClick={this.handleThumbnailShow}
                     >
                     {'Add Thumbnail'}
                    </Button>
                      <div className={values?.add_thumbnail ? "":"thumbpreview"}>
            {values?.add_thumbnail ?
              (<span>
                <img src={values?.add_thumbnail} className={values?.add_thumbnail ?"thumbnailImg":"thumbnailwidth"}/>
                </span>
            ) : (
              <div></div>
            )}
          </div>  
             </StyledBoxThumbnail>
        )
      }
    // Customizable Area End
    render() {

        const {classes}= this.props
        const { programAllExperts, programSelectedExpert,isEditMode, programThumbnailImage, programCategory } = this.state;
        return (
            // Customizable Area Start
            <Box>
                {(this.props.navigation.getParam('type') === "addProgram" || this.state?.programs?.attributes || this.props?.location?.state?.formObject) && programSelectedExpert &&                   
                   <Formik
                   innerRef={formik => { this.formikRef = formik; }}
                   data-test-id="program_formik"
                   enableReinitialize = {true}
                   initialValues={this.getInitialValue()}
                   onSubmit={(values) => {
                       this.setState({ isSubmitted: true }, () => {
                           this.handleSubmit(values);
                       });
                   }}
                   validationSchema={this.programValidationSchema}
                   validateOnMount={true}  // Add this line to validate the form on mount
               >
                   {({ values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue }) => {
                        
                        const isValidPrice = (value: string): boolean => {
                          return /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(value) || value === "";
                        };
                        const handlePrice= (value: string) => {
                          if (isValidPrice(value)) {
                            setFieldValue('price', value);
                          }
                        };
                        const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                          const value = event.target.value;
                          handlePrice(value);
                        };
                       return (
                           <div>
                               <Box data-test-id="createProgramForm" sx={{ width: "83%" }}>
                                <Box sx={gridStyle}>
                                            <Box style={{ display: 'flex', paddingRight: this.getPaddingRight()}}>
                                                <FormControl variant="standard" className="inputwidth" style={{ display:'flex', gap:'6px' }} data-test-id='text-field'>
                                               <label className="labelStyle" style={labelText}>{'Program name*'}</label>
                                               <TextField
                                                   data-test-id="programTitle"
                                                   name={'title'}
                                                   placeholder={'Program name here'}
                                                   value={values.title}
                                                   variant="outlined"
                                                   error={this.hasError(errors, touched, this.state.isSubmitted)}
                                                   helperText={this.getHelperText(errors, touched, this.state.isSubmitted)}                                                   
                                                   onChange={handleChange}
                                                   className={`${classes.textInputField} ${!values.title && classes.empty}`}
                                               />
                                           </FormControl>
                                            </Box>
                                            <Box style={{ display: 'flex', paddingRight: "36px" }}>
                                                <Box style={thumbnailOuterBox}>
                                                    <label className="labelStyle" style={labelText}>Thumbnail*</label>
                                                    {this.renderThumbnail(values)}
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex' }}>
                                                <FormControl variant="standard" className="customInput" style={{ width: '100%' }}>
                                                    <label className="labelStyle" style={{ ...labelText, color: "#787776" }}>Program ID</label>
                                                    <Box style={{ display: 'flex', alignItems: 'center', gap: '12px', margin: 0, }}>
                                                        <TextField
                                                            className="customDisabled"
                                                            defaultValue="PR-U420"
                                                            id="bootstrap-input-courseID"
                                                            disabled
                                                            style={{ width: '100%' }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <ErrorOutlineOutlined />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        {renderEditIcon(this.state.isEditMode)}                                          
                                                      </Box>
                                                    <Typography style={{ display: "flex", marginTop: "5px", alignItems: "center", fontSize: "12px", fontFamily: "'Poppins', sans-serif", color: "#787776" }}>
                                                        <ErrorOutlineOutlined style={{ marginRight: "5px", width: "16px", height: "16px" }} />
                                                        Automatic ID Assigned
                                                    </Typography>
                                                </FormControl>
                                            </Box>
                                            <Box style={{ display: 'flex', paddingRight: this.getPaddingRight()}}>                                             
                                           <FormControl variant="standard" className="customInput" style={Boxwidth}>
        <Typography className="labelStyle" style={labelText}>Assigned Xpert*</Typography>
          <StyledDropdownClickBox 
           onClick={(event) => this.dropdownHandlerOpen(event)} 
           style={{color:this.TextColor(values.expert_id ),background: this.backColor(values.expert_id ) }}
            data-test-id="selectButtonIDs"
      >
      <MaterialUIBox className="contentContainer contentRole">
      <MaterialUIBox className="innerContentBox">
        {
      values?.expert_id ? programAllExperts.filter((i)=>i.id==values.expert_id).map(i=>i.attributes.full_name): "Select Xpert"
        }
        </MaterialUIBox>
        </MaterialUIBox>
       {this.state.anchorEl ? <ExpandLess /> : <ExpandMore />}
      </StyledDropdownClickBox>
      {this.state.anchorEl && this.renderPlaceholderDropdownContent(programAllExperts,values)}
      </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginY: '40px' }}>
                                        <Button data-test-id="btn_setData" className="contentRole-btn">
                                            + Add Content Roles
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Box sx={categoriesHeader}>Categories</Box>
                                        <Box sx={categoriesContainer} data-test-id='categoriesContainer'>
                                        {programCategory.map((item, index) => {
        // Check if this category is in the pre-filled selectedCategories array
        const isSelected = values.selectedCategories?.some(
          (category: { id: string; }) => category.id === item.id
        );
        return (
          <Box key={item.id}>
            <Button
              data-test-id={`selectCategory-${index}`}
              className={categoryClass(isSelected)} // Apply class based on selection state
              variant={getButtonVariant(isSelected)} // Change style based on whether the category is selected
              onClick={() => {
                let selected = Array.isArray(values.selectedCategories)
                ? [...values.selectedCategories]
                : [];
                // Toggle category selection
                if (isSelected) {
                  // If already selected, remove it from the selectedCategories array
                  selected = selected.filter(
                    (category) => category.id !== item.id
                  );
                } else {
                  // If not selected, add the category to selectedCategories
                  selected.push({
                    id: item.id,
                    name: item.attributes?.name,
                  });
                }
                setFieldValue("selectedCategories", selected);
              }}
            >
              {item.attributes?.name}
            </Button>
          </Box>
        )})}
                                        </Box>
                                    </Box>
                                    <Box style={{display:"flex", flexDirection:"column", gap:'6px', marginTop:'25px'}}>
                                        <label className="labelStyle" style={labelText}>Program Description</label>
                                        <TextField
                                            fullWidth
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{ maxLength: 1500 }}
                                            InputProps={{ style: { borderRadius: "8px",}}}
                                            variant="outlined"
                                            placeholder="Please write your description"
                                            multiline
                                            rows={10}
                                            maxRows={12}
                                            className={`${classes.textInputField} ${!values.description && classes.empty}`}
                                        />
                                        <Typography style={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", fontWeight: 400, lineHeight: "18px", textAlign: "left" }}>
                                            *A description will increase sales and improve the user experience.
                                        </Typography>
                                    </Box>
                                        <Grid container spacing={4} style={{marginTop:'25px',}}>
                                            <Grid item xl={4} lg={5} md={6} sm={6} xs={12}>
                                                <div style={{ boxShadow: "1px 2px 10px 0px #00000011", borderRadius: "8px", height:'100%', padding:"24px 16px 24px 16px" }}>
                                                    <Box sx={programHeader}>Program Logic</Box>
                                                <div style={{display:'flex', flexDirection:"column", gap:'24px', marginTop:'10px'}}>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Box style={programLabel}> <ErrorOutlineOutlined/> <label>Below settings will overwrite Courses in this Program</label></Box>
                                                    </Grid>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
                                                            Include launch date
                                                        </Typography>
                                                        <Switch
                                                            onChange={(event)=>{
                                                                setFieldValue('launch_date',null)
                                                                handleChange(event)
                                                            }}
                                                            name="include_launch_date"
                                                            data-test-id='include_launch_date_id'
                                                            checked={values.include_launch_date}
                                                            inputProps={{ "aria-label": "Include Launch Date" }}
                                                            className="switch"
                                                            classes={{
                                                                switchBase: "switchBase",
                                                                track: "track",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
                                                            Add due date
                                                        </Typography>
                                                        <Switch
                                                            onChange={(event) => {
                                                                setFieldValue("days_to_complete_the_program", false);
                                                                setFieldValue("program_timeline","");
                                                                setFieldValue('due_date',null)
                                                                handleChange(event);
                                                            }}
                                                            name="add_due_date"
                                                            checked={values.add_due_date}
                                                            inputProps={{ "aria-label": "Add Due Date" }}
                                                            data-test-id="add_due_date_id"
                                                            className="switch"
                                                            classes={{
                                                                switchBase: "switchBase",
                                                                track: "track",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
                                                            Days to complete the program
                                                        </Typography>
                                                        <Switch
                                                            data-test-id="daysToComplete"
                                                            onChange={(event) => {
                                                                const value = this.getDueDateEnable(values.days_to_complete_the_program);
                                                                setFieldValue("add_due_date", false);
                                                                setFieldValue('due_date',null)
                                                                handleChange(event)
                                                            }}
                                                            name="days_to_complete_the_program"
                                                            checked={Boolean(values.days_to_complete_the_program)}
                                                            inputProps={{ "aria-label": "Days to complete the course" }}
                                                            className="switch"
                                                            classes={{
                                                                switchBase: "switchBase",
                                                                track: "track",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }}>
                                                            Notify when completed
                                                        </Typography>
                                                        <Switch
                                                            onChange={(event)=>{ 
                                                                handleChange(event);
                                                            }}
                                                            data-test-id='notify_when_completed'
                                                            name="is_notify_when_completed"
                                                            checked={values.is_notify_when_completed}
                                                            inputProps={{ "aria-label": "Notify when completed" }}
                                                            className="switch"
                                                            classes={{
                                                                switchBase: "switchBase",
                                                                track: "track",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
                                                            Content release schedule
                                                        </Typography>
                                                        <Switch
                                                            onChange={(event)=>{                                                               
                                                                setFieldValue('content_release_schedule_value',"")
                                                                setFieldValue('phases',"")
                                                                handleChange(event)
                                                            }}
                                                            data-test-id='schedule-release'
                                                            name="content_release_schedule"
                                                            checked={values.content_release_schedule}
                                                            inputProps={{ "aria-label": "Content release schedule" }}
                                                            className="switch"
                                                            classes={{
                                                                switchBase: "switchBase",
                                                                track: "track",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
                                                            Forced learning
                                                        </Typography>
                                                        <Switch
                                                            onChange={handleChange}
                                                            name="is_forced_learning"
                                                            checked={values.is_forced_learning}
                                                            inputProps={{ "aria-label": "forced_learning" }}
                                                            className="switch"
                                                            classes={{
                                                                switchBase: "switchBase",
                                                                track: "track",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={switchContainer}>
                                                        <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
                                                            Make this program sellable
                                                        </Typography>
                                                        <Switch
                                                            onChange={(event)=>{
                                                                setFieldValue('price','');
                                                                setFieldValue('subscription_period',"")
                                                                handleChange(event)
                                                            }}
                                                            name="is_sellable"
                                                            data-test-id='sellable_switch'
                                                            checked={values.is_sellable}
                                                            inputProps={{ "aria-label": "is_sellable" }}
                                                            className="switch"
                                                            classes={{
                                                                switchBase: "switchBase",
                                                                track: "track",
                                                            }}
                                                        />
                                                    </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xl={8} lg={7} md={6} sm={6} xs={12}>
                                                <div style={{ boxShadow: "1px 2px 10px 0px #00000011", borderRadius: "8px", height:'100%', padding:"24px 16px 24px 16px" }}>
                                                    <Box sx={programHeader}> Program Options</Box>
                                                    <div style={{display:'flex', flexDirection:"column", gap:'30px', marginTop:'10px',}}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12} md={6} lg={3} style={{display:'flex',flexDirection:'column',gap:"8px", }}>
                                                            <label style={getLabelStyle(!values.include_launch_date)}>Launch Date</label>
                                                            <CustomDatePicker
                                                                data-test-id='launch-date-picker'
                                                                disable={!values.include_launch_date}
                                                                placeHolder="Launch Date"
                                                                selectedDate={values.launch_date}
                                                                top="70px" left="63px"
                                                                maxDate={values.due_date}
                                                                handleChange={(value:Date)=>{
                                                                    setFieldValue("launch_date", value)
                                                                }}
                                                                minDate={addDays(startOfToday(),1)} />
                                                        </Grid>                                                      
                                                        <Grid item xs={12} sm={12} md={6} lg={3} style={{display:'flex',flexDirection:'column',gap:"8px", }}>
                                                            <label style={getLabelStyle(!values.add_due_date)}>Due Date</label>
                                                            <CustomDatePicker data-test-id={'due_date_picker'}
                                                                disable={!values.add_due_date} 
                                                                placeHolder="Due Date"
                                                                selectedDate={values.due_date}
                                                                minDate={this.getDueDateMinimumValue(values.launch_date)}
                                                                top="70px"
                                                                left="205px"
                                                                handleChange={(value:Date)=>{
                                                                    setFieldValue("due_date", value)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}  lg={6}>
                                                        <FormControl  fullWidth variant="outlined" style={{ display:'flex', gap:'6px' }}>
                                                            <label style={getLabelStyle(!values.days_to_complete_the_program)}>Days to complete the program</label>
                                                            <TextField
                                                                name="course_timeline"
                                                                type="number"                                                                
                                                                data-test-id="program_timeline"
                                                                value={values.program_timeline}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}   
                                                                disabled={!values.days_to_complete_the_program}                                                                
                                                                onChange={(event)=>{
                                                                    const input = event.target.value;
                                                                    const value = this.handleDay(input.replace(/^0+/, ''), values.program_timeline)
                                                                    setFieldValue("program_timeline",value.toString())
                                                                    event.target.value=value.toString()                                                                 
                                                                }}                                                             
                                                                placeholder="Type a number"
                                                                variant="outlined"
                                                                fullWidth
                                                                className={`${classes.textInputField} ${classes.daysToComplete} ${!values.program_timeline && classes.empty}`}
                                                            />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} > 
                                                            <FormControl  fullWidth variant="outlined" style={{ display:'flex', gap:'6px' }}>
                                                                <label style={getLabelStyle(!values.is_notify_when_completed)}>
                                                                    Who gets notified?</label>
                                                                                                                 
                                         <Select
                                           multiple
                                           MenuProps={{
                                             ...CustomMenuProps,
                                             anchorOrigin: { vertical: "bottom", horizontal: "right" },
                                             transformOrigin: { vertical: "top", horizontal: "right" },
                                           }}
                                           name="notify_when_completed"
                                           data-test-id="notify_when_completed-dropdown"
                                           style={{
                                             height: "54px",
                                             margin: "0",
                                             color: this.TextColor(values.is_notify_when_completed),
                                             background: this.backColor(values.is_notify_when_completed),
                                           }}
                                           disabled={!values.is_notify_when_completed}
                                           value={Array.isArray(values.notify_when_completed) ? values.notify_when_completed : []}
                                           displayEmpty
                                           className={classes.selectDropDown}
                                           renderValue={(selected) => this.renderNotifyValues(selected)}
                                           onChange={(event) => {
                                             const {
                                               target: { value },
                                             } = event;
                                             setFieldValue('notify_when_completed', typeof value === 'string' ? value.split(',') : value);
                                           }}
                                           fullWidth
                                         >
                                           <MenuItem value="" disabled style={{ color: "grey" }}>
                                             {"Select user"}
                                           </MenuItem>
                                           {this.state.programUserList.map((option, index) => (
                                             <MenuItem key={index} value={option.id}>
                                               {option.attributes.full_name}
                                             </MenuItem>
                                           ))}
                                         </Select>
                                          </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3} >
                                                        <Grid item xs={12} >
                                                        <FormControl  fullWidth style={{ display:'flex', gap:'6px' }}>
                                                            <label style={getLabelStyle(!values.content_release_schedule)} >Release content</label>
                                                            <StyledBox>
                                                                <Button
                                                                    data-test-id="release_daily"
                                                                    variant="outlined"
                                                                    disabled={!values.content_release_schedule}
                                                                    onClick={() => {
                                                                        setFieldValue("content_release_schedule_value", "daily")
                                                                    }}
                                                                    className={this.renderDaily(!!values.content_release_schedule, values.content_release_schedule_value)}
                                                                >Daily</Button>
                                                                <Button
                                                                    data-test-id="release_weekly"
                                                                    variant="outlined"
                                                                    disabled={!values.content_release_schedule}
                                                                    onClick={() => {
                                                                        setFieldValue("content_release_schedule_value", "weekly")
                                                                    }}
                                                                    className={this.renderWeekly(!!values.content_release_schedule, values.content_release_schedule_value)}
                                                                >Weekly</Button>
                                                                <Button
                                                                    data-test-id="release_monthly"
                                                                    variant="outlined"
                                                                    disabled={!values.content_release_schedule}
                                                                    onClick={() => {
                                                                        setFieldValue("content_release_schedule_value", "monthly")
                                                                    }}
                                                                    className={this.renderMonthly(!!values.content_release_schedule, values.content_release_schedule_value)}
                                                                >Monthly</Button>
                                                            </StyledBox>
                                                        </FormControl>
                                                         </Grid>
                                                        <Grid item xs={12} lg={6}>                                                            
                                                            <FormControl  fullWidth style={{ display:'flex', gap:'6px' }}>
                                                                <label style={getLabelStyle(!values.content_release_schedule)} >Phases</label>
                                                                <Select
                                                                    labelId="open-select-label"
                                                                    data-test-id="open-select"
                                                                    name="phases"
                                                                    value={values.phases}
                                                                    onChange={handleChange}
                                                                    className={classes.selectDropDown}
                                                                    displayEmpty
                                                                    variant="outlined"
                                                                    style={{color:this.TextColor(values.content_release_schedule ),background: this.backColor(values.content_release_schedule ) }}
                                                                    disabled={!values.content_release_schedule}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value={""} disabled style={{ color: 'grey' }}>Select the number of phases</MenuItem>
                                                                    <MenuItem value={1} disabled>1</MenuItem>
                                                                    <MenuItem value={2}>2</MenuItem>
                                                                    <MenuItem value={3}>3</MenuItem>
                                                                    <MenuItem value={4}>4</MenuItem>
                                                                    <MenuItem value={5}>5</MenuItem>
                                                                    <MenuItem value={6}>6</MenuItem>
                                                                    <MenuItem value={7}>7</MenuItem>
                                                                    <MenuItem value={8}>8</MenuItem>
                                                                    <MenuItem value={9}>9</MenuItem>
                                                                    <MenuItem value={10}>10</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3} >
                                                        <Grid item xs={12} lg={6}>
                                                            <FormControl  disabled={!values.is_sellable} fullWidth>
                                                                <label style={getLabelStyle(!values.is_sellable)} >Price</label>
                                                                <TextField
                                                                    name="price"
                                                                    className={classes.priceInput}
                                                                    data-test-id='program-price'
                                                                    style={{ display: "flex", alignItems: "center" }}
                                                                    disabled={!values.is_sellable}
                                                                    value={values.price}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={handlePriceChange}
                                                                    placeholder="Price"
                                                                    variant="filled"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        inputMode: 'decimal',
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" style={{ margin: "0" }}>
                                                                            US <AttachMoneyOutlined style={{fontSize:"inherit", marginLeft:"-3px"}}/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item  xs={12}lg={6} >
                                                            <FormControl  fullWidth variant="outlined">
                                                                <label style={getLabelStyle(!values.is_sellable)}>Subscription Period</label>
                                                                <Select
                                                                    labelId="subscription-select-label"
                                                                    data-test-id="subscription-select"
                                                                    name="subscription_period"
                                                                    value={values.subscription_period}
                                                                    onChange={handleChange}
                                                                   className={classes.selectDropDown}
                                                                    displayEmpty
                                                                    
                                                                    style={{ height: "54px", margin: "0", marginTop: "5px",color:this.TextColor(values.is_sellable ),background: this.backColor(values.is_sellable ) }}
                                                                    disabled={!values.is_sellable}
                                                                    fullWidth
                                                                > 
                                                                    <MenuItem value="" disabled >Select a Period</MenuItem>
                                                                    <MenuItem value={"one_time_purchase"}>One Time Purchase</MenuItem>
                                                                    <MenuItem value={"monthly_subscription"}>Monthly</MenuItem>
                                                                    <MenuItem value={"semi_anually"}>Semi-Annually</MenuItem>
                                                                    <MenuItem value={"anually"}>Annually</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    <Box sx={continueButtonContainer}>
                                        <DraftContainer
                                            data-test-id="updateSaveData"
                                            onClick={() => this.handleOpensaveAsDraft(values)}
                                        >
                                            Save to Draft
                                        </DraftContainer>
                                        <HeaderContainer
                                            onClick={() => handleSubmit()}
                                            style={{
                                                color: this.btnTextColor(values),
                                                background: this.btnBackgroundColor(values),
                                              }}
                                            data-test-id="submit-button">
                                            Continue to Add Content
                                        </HeaderContainer>
                                    </Box>
                                    {this.renderSaveAsDraftModal()}
                                </div>
                             
                            );
                        }}
                    </Formik>
                }
                {
                    (this.props?.navigation.getParam('type') !== "addProgram" && this.state.isLoading) && <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box>
                }
                  {this.state.showThumbnailPopup && 
      <>
      <Commonmanagement
      id="2"
      data-test-id="common_id"
      navigation={this.props.navigation}
         open={this.state.showThumbnailPopup}
         onClose={this.handleThumbnailModalClose}
        CloseModal={this.closeModal}
      />
       </>
      }
            </Box>
            // Customizable Area End
        );
    }
}
// Customizable Area 

const HeaderContainer = styled(Button)({
    width: "300px", height: "56px", borderRadius: "16px", textTransform: "none", color: "#fff", fontSize: "16px", lineHeight: "16px", fontWeight: 600, fontFamily: "'Poppins', sans-serif", "&:hover": {
    },
    "@media (max-width:666px)": {
        maxWidth:'70%'
    },
});
const DropdownContent = styled(MaterialUIBox)({
    position:"absolute",
    top:"88px",
    zIndex:9999,
    background:"#fff",
    width:"100%",
    padding: "8px 16px",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius:"12px",
    "& .checkboxListsCss": {
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      marginBottom: "16px",
      gap: "8px"
    },
    "& .checkboxContentCss": {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid lightgray",
      textTransform: "capitalize",
      fontFamily: "Poppins , sans-serif"
    },  "& .MuiTypography-body1":{
      fontFamily:"Poppins, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      color: "#1C1B1B"
    },
    "& .selectedStyles": {
      backgroundColor: "#EEE8F2",
      borderBottom: "1px solid transparent !important",
      borderRadius: "8px",
    },
    "& .selectedStyles .MuiTypography-body1":{
      color: "#542070 !important"
    },
    "& .checkboxContentCss:hover": {
      backgroundColor: "#EEE8F2",
      borderRadius: "8px",
      borderBottom: "1px solid transparent",
    },
    "& .applyBtn": {
      display: "block",
      color: "#542070",
      margin: "auto",
      textTransform: "capitalize",
      fontSize: "16px"
    },
    "& .MuiFormControlLabel-root": {
      margin: 0,
      borderBottom: "1px solid lightgray",
      fontFamily:"Poppins, sans-serif"
    },
    "& .react-calendar":{
      border: "unset",
      fontWeight: 500,
      fontSize: "14px"
    },
    "& .react-calendar abbr":{
      textDecoration: "none"
    },
    "& .react-calendar__month-view__days__day":{
        height: "31px",
      width: "31px",
      borderRadius: "50%",
      fontWeight: 400,
      fontSize: "14px !important"
    },
    "& .react-calendar__month-view__weekdays":{
        fontSize: "10px !important",
      fontWeight: 400
    },
    "& .react-calendar__tile--now:enabled":{
      backgroundColor: "#652786",
      color: "#fff"
    },

    "& .react-calendar__month-view__days__day--weekend": {
      color: "rgba(0, 0, 0, 0.87)"
    },
    "& .react-calendar__tile--now:enabled:hover":{
        backgroundColor: "#652786"
      },
    "& .react-calendar__month-view__days__day--neighboringMonth":{
      color: "#757575"
    },
    "& .react-calendar__tile--active":{
      color: "#fff",
      backgroundColor: "#652786 !important"
    },
    "& .react-calendar__year-view__months__month":{
        height: "74px",
      width: "74px",
      borderRadius:"50%"
    },
  
    "& .react-calendar__navigation__next2-button":{
      display: "none"
    },
    "& .react-calendar__navigation__prev2-button":{
        display: "none"
      },
    "& .react-calendar__navigation button:enabled:hover":{
      backgroundColor: "unset"
    },
    "& .react-calendar__navigation button":{
      minWidth: "auto"
    },
    "& .calendarBtnContainer":{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      marginTop: "16px"
    }
  });
  const TextFieldSearch = styled(TextField)({
    width:"100%",
    "& .MuiOutlinedInput-notchedOutline":{
      backgroundColor: "#fcf6f6"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray"
    },
    "& .MuiSvgIcon-root":{
      position: "relative",
      zIndex: 1,
      color: "gray"
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: "12px",
      height: "48px"
    },
    "& .MuiOutlinedInput-input":{
      position: "relative",
      zIndex: 1,
    },
    "& .MuiOutlinedInput-inputAdornedStart":{
      paddingLeft: "6px"
    }
  });
  const CheckboxMain = styled(Checkbox)({
    color: '#652786 !important',
  });
  const StyledDropdownClickBox = styled('div')({
    marginTop:"6px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "14px 16px !important",
    border: "1px solid #ADAAAA",
    borderRadius: "8px",
    backgroundColor: "#fcf6f6",
    cursor: "pointer",
    position:"relative",
    "&.selectCommonStyle .contentContainer":{
      display: "flex",
      gap: "3px",
      width: "100%"
    },
    "&.selectActive":{
      backgroundColor: "#EEE8F2",
      borderColor: "#652786"
    },
    "&.selectActive .title":{
      color: "#652786"
    },
   "&.creationDate":{
    display: "flex",
    justifyContent: "space-between"
   },
   "& .contentRole ":{
    width: "100% !important",
   display: "flex !important",
   justifyContent: "space-between !important"
   },
    "& .innerContentBox":{
      display: "flex",
      gap: "3px",
      fontSize:"16px",
      fontWeight:500,
      color:'#ADAAAA'
    },
    "& .title":{
      whiteSpace: "nowrap",
      fontSize: "16px",
      fontWeight: 400,
      color: "#484646",
      fontFamily: "Poppins, sans-serif"
    },
    "& .arrowIconStyle":{
      color: "#652786"
    }
  });
const DraftContainer = styled(Button)({
    width: "300px", height: "56px", textTransform: "none", textAlign: "center", color: "#FF8D3B", fontSize: "16px", lineHeight: "16px", fontWeight: 600, fontFamily: "'Poppins', sans-serif", border: "1px solid #FF8D3B", borderRadius: "16px",
    "@media (max-width:666px)": {
        maxWidth:'70%'
    },
});

const StyledBox = styled(Box)({
    display: "flex",
    "@media  (min-width:600px) and (max-width:666px)": {
        flexDirection:'column',
        gap:'8px',
        maxWidth:'100px'
    },

    "@media (max-width:296px)": {
        flexDirection:'column',
        gap:'8px',
        maxWidth:'100px'
    },
});

const StyledBoxThumbnail = styled(Box)({
    display: "flex",
    "@media (max-width:371px)": {
        flexDirection:'column',
    },
});
const Boxwidth={width:"100%"}

const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gap: "24px",
    "@media (max-width: 1300px)": {
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    "@media (max-width: 700px)": {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
};
const labelText = { fontSize: "16px", fontWeight: 400, lineHeight: "24px", textAlign: "left" as const, color: "#1C1B1B", fontFamily: "'Poppins', sans-serif" }
const thumbnailOuterBox = { display: "flex", flexDirection: "column" as const, gap: "4px" }
const categoriesHeader = { fontSize: "22px", fontWeight: "600" }
const categoriesContainer = { display: "flex", flexWrap: "wrap", gap: "20px", marginTop: "20px" }
const programHeader = { fontSize: "22px", fontFamily: "'Poppins', sans-serif", lineHeight: "33px", fontWeight: "600", borderBottom: "1px solid #E5E2E1", paddingBottom: "10px" }
const switchContainer = { display: "flex", justifyContent: "space-between", alignItems: "center",}
const continueButtonContainer = { display: "flex", flexDirection: "column", gap:'16px', alignItems: "center" , marginTop:"24px"}

const categoryClass =(selected:boolean)=>{
    if(selected){
     return "contentRoleContent-btn btn-background"
    }  
    return "contentRoleContent-btn"
}

const renderEditIcon = (isEditMode: boolean) => {
  if (!isEditMode) return null;
  return (
    <img
      src={EDIT1_ICON}
      height="24"
      width="24"
      style={{ marginTop: "10px", cursor: "pointer" }}
      alt="Edit"
    />
  );
};
const getButtonVariant = (isSelected: boolean) => {
  return isSelected ? "contained" : "outlined";
}

const programLabel = {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "14px",
    textAlign: "left" as const, fontFamily: "'Poppins', sans-serif",
    color:'#E47004',
    display:'flex',
    gap:'10px',
    alignItems:'center'
}

const getLabelStyle =(disable:boolean)=>{
    return {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left" as const, fontFamily: "'Poppins', sans-serif",
        color: disable ? "#ADAAAA":"#1C1B1B"
    }
}

const styles: any = (theme: Theme) =>
    createStyles({
     textInputField:{
        '& .MuiInputBase-root':{            
            '&:hover': {
                borderColor: '#652786',
                borderWidth:"1px",
            },
            '&.Mui-focused fieldset': {
                borderWidth:"1px",
                borderColor: '#652786',
            },
            '& .Mui-disabled': {
                background: "#e5e2e1", 
                borderRadius:'8px'            
            },
            
        },
        '& .MuiInputBase-root.Mui-disabled': {
            background: '#e5e2e1', 
        },
        '& .MuiOutlinedInput-root':{
            borderRadius:'8px'
        }  
        
     },
     empty:{
       '& .MuiInputBase-root':{
          backgroundColor: '#FCF6F6',
       } 
     },

     daysToComplete:{
        '& .MuiInputBase-input::placeholder': {
            color: '#ADAAAA,',
            opacity: 1,
        },
     },
     
     selectDropDown: {
        borderRadius:'7px',
        "& .MuiSelect-select:focus":{
backgroundColor:"#FCF6F6 !important",
border: '1px solid #E5E2E1 !important',
outline:"none"
        },
        '& .MuiSelect-outlined': {
            borderRadius: '9px'
          }
    },
    priceInput:{
        height: '54px',
        margin: '5px 0px 0px',
        '& .MuiFilledInput-root': {
            backgroundColor: '#fff',
            height:"100%",
          },
          '& .MuiFilledInput-root:hover': {
            backgroundColor: '#fff',
          },
          '& .MuiFilledInput-root.Mui-focused': {
            backgroundColor: '#fff',
          },
          '& .MuiFilledInput-root.Mui-disabled': {
            backgroundColor: '#fff',
          },
          '& .MuiFilledInput-underline::after':{
            borderBottom: '1px solid #E5E2E1'
          },
          '& .MuiFilledInput-underline::before':{
            borderBottom: '1px solid #E5E2E1'
          },
          '& .MuiInputAdornment-root':{
            fontSize:"16px"
          },
          '& .MuiFilledInput-input':{
            padding:"0px"

          },
          '& .Mui-disabled':{
            color: '#ADAAAA',
          },
          '& .MuiFilledInput-input::placeholder': {
            color: '#ADAAAA',
            opacity: 1,
          },
        },
    });
// @ts-ignore
//@ts-nocheck
export default withRouter(withStyles(styles)(ProgramCreation))
// Customizable Area End
// Customizable Area End