import React from "react";
import CourseCreationController, {
  Props,
  configJSON,
} from "./CourseCreationController.web";
import { Box, Typography, styled, Button, Modal, Grid, Dialog} from "@material-ui/core";
import { NotFoundImage, iconPage, iconQuiz, BackgroundColor, icon_video, icon_audio, icon_page, IconClse } from "./assets"
import './CourseCreation.css';
import CloseIcon from '@material-ui/icons/Close';
import LessonDragDrop from "../../../components/src/LessonDragDrop/LessonDragDrop";
import PhaseDragDrop from "../../../components/src/LessonDragDrop/PhaseDragDrop"



class AddContentPage extends CourseCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    if (this.props.navigation.getParam('type') === "Phases") {
      this.setState({
        phasesIncluded: true
      })
    }
  }

  renderCourseStatusMain = () => {
    const { courseStatus } = this.state;
    let statusComponent = null;
    if (courseStatus === "draft") {
      statusComponent = (
        <DraftContainerComponent>
          <DraftTitleMain >draft</DraftTitleMain>
        </DraftContainerComponent>
      );
    } else if (courseStatus === "active") {
      statusComponent = (
        <ActiveContainerMain>
          <ActiveTitleMain>{courseStatus}</ActiveTitleMain>
        </ActiveContainerMain>
      );
    } else if (courseStatus === "archived") {
      statusComponent = (
        <ArchivedContainerMain>
          <ArchivedTitleMain style={webStyle.archivedTitle}>
            {courseStatus}
          </ArchivedTitleMain>
        </ArchivedContainerMain>
      );
    }

    return (
      <Box sx={webStyle.courseMain}>
        <CourseTitleComponent>
          <CourseTitleNameComponent>
            {`Course Title: ${this.state.courseTitle}`}
          </CourseTitleNameComponent>
          {statusComponent}
        </CourseTitleComponent>
        <Box sx={webStyle.addPhasesButton}>
          <Box sx={webStyle.imageContainer}>
            <img src={BackgroundColor} alt="BackgroundColor" />
          </Box>
          <Button style={webStyle.addPhaseButtonText} data-testId="AddPhaseBtn" onClick={() => { this.handleAddPhase(this.state.courseData.id) }}>Add phase</Button>
        </Box>
      </Box>
    );
  }


  courseMainSecond = () => {
    const { courseStatus } = this.state;
    let statusComponentSecond = null;
    if (courseStatus === "draft") {
      statusComponentSecond = (
        <DraftContainerComponent data-test-id="contentStatusdraftID">
          <DraftTitleMain>{courseStatus}</DraftTitleMain>
        </DraftContainerComponent>
      );
    } else if (courseStatus === "active") {
      statusComponentSecond = (
        <ActiveContainerMain>
          <ActiveTitleMain>{courseStatus}</ActiveTitleMain>
        </ActiveContainerMain>
      );
    } else if (courseStatus === "archived") {
      statusComponentSecond = (
        <ArchivedContainerMain>
          <ArchivedTitleMain style={webStyle.archivedTitle}>
            {courseStatus}
          </ArchivedTitleMain>
        </ArchivedContainerMain>
      );
    }
    return(
      < Box sx={webStyle.courseMain}>
        <Box sx={webStyle.courseTitle}>
          <Typography style={webStyle.courseTitleName}>
            {`Course Title: ${this.state.courseTitle}`}
          </Typography>
        </Box>
        <Box sx={webStyle.leftContainer}>
          <Box>
            <Typography style={webStyle.courseTitleName2}>
              Status
            </Typography>
          </Box>
          {statusComponentSecond}
        </Box>
      </Box >
    )
  }

  renderConditions = (isContentReleaseScheduleData:boolean, isPhasesContentEqualLessonCount:boolean, isContentValueNonEmpty:boolean) => {
    if(isContentReleaseScheduleData){
      return  isPhasesContentEqualLessonCount;
    }else{
      return isContentValueNonEmpty;
    }
  };

  courseMainThird = () => {
    const { courseStatus } = this.state;
    let statusComponentThird = null;
    if (courseStatus === "draft") {
      statusComponentThird = (
        <DraftContainerComponent data-test-id="contentStatusdraftID">
          <DraftTitleMain>{courseStatus}</DraftTitleMain>
        </DraftContainerComponent>
      );
    } else if (courseStatus === "active") {
      statusComponentThird = (
        <ActiveContainerMain>
          <ActiveTitleMain>{courseStatus}</ActiveTitleMain>
        </ActiveContainerMain>
      );
    } else if (courseStatus === "archived") {
      statusComponentThird = (
        <ArchivedContainerMain>
          <ArchivedTitleMain style={webStyle.archivedTitle}>
            {courseStatus}
          </ArchivedTitleMain>
        </ArchivedContainerMain>
      );
    }
    return(
      <Box sx={webStyle.courseMain}>
        <Box sx={webStyle.courseTitle}>
          <Typography style={webStyle.courseTitleName}>
            {`Course Title: ${this.state.courseTitle}`}
          </Typography>
          {statusComponentThird}
        </Box>
        <Box sx={webStyle.buttonsTop}>
        <Button  style={webStyle.addQuizButton} 
          onClick={this.handleOpenLessonNew}
        >
            <img src={iconPage} alt="iconQuiz" />

            <Typography style={webStyle.buttonText}>
              Add Lesson
            </Typography>
          </Button>
        </Box>
    </Box>)}

  // Customizable Area End

  render() {
    // Customizable Area Start
    const isContentReleaseScheduleData = this.state.contentReleaseScheduleData;
    const isPhasesContentEqualLessonCount = this.state.phasesContent.length === this.state.lessonCount.length;
    const isContentValueNonEmpty = this.state.content_value.length !== 0;

    return (
      <Box sx={webStyle.mainContainer}>
        {
          this.state.phasesIncluded ? (
            <>
              <Box sx={webStyle.mainContainer}>
                {this.renderCourseStatusMain()}
                <Box style={webStyle.containtContainer2}>
                  <PhaseDragDrop
                    data-test-id="phase-drag-id"
                    onDragEnd={this.onDragEnd}
                    phasesContent={this.state.phasesContent}
                    handleMouseEnter={this.handleMouseEnter}
                    handleMouseLeave={this.handleMouseLeave}
                    handleLessonDeleteDialog={this.handleLessonDeleteDialog}
                    addDialogBoxopen={this.state.addDialogBoxopen}
                    dialogImageToggle={this.state.dialogImageToggle}
                    handleAddIconClick={this.handleAddIconClick}
                    hoverLessonId={this.state.hoverLessonId}
                    isHovering={this.state.isHovering}
                    handleDragEnter={this.handleDragEnter}
                    handleDragLeave={this.handleDragLeave}
                    editingIndex={this.state.editingIndex}
                    courseData={this.state.courseData}
                    showPhassesDropDown={this.state.showPhassesDropDown}
                    handlePhaseMouseEnter={this.handlePhaseMouseEnter}
                    hoverPhaseId={this.state.hoverPhaseId}
                    handlePhaseDeleteDialog={this.handlePhaseDeleteDialog}
                    handlePhaseAddIconClick={this.handlePhaseAddIconClick}
                    handleAddIconOpenLessonNew={this.handleAddIconOpenLessonNew}
                    handleMouseLeavePhase={this.handleMouseLeavePhase}
                    showPhasesDialogLesson={this.state.showPhasesDialogLesson}
                    handleMouseEnterPhases={this.handleMouseEnterPhases}
                    handleRenameText={this.handleRenameText}
                    handleLeavePhaseRename={this.handleLeavePhaseRename}
                    editingText={this.state.editingText}
                    showRenameDialog={this.state.showRenameDialog}
                    handleDropDown={this.handleDropDown}
                    handleEnterPhaseRename={this.handleEnterPhaseRename}
                    handleEditClick={this.handleEditClick}
                    handleSaveName={this.handleSaveName}
                    webStyle={webStyle}
                    handleOpenLessson={this.handleOpenLessson}
                    handleLessonEdit={this.handleLessonEdit}
                    showEmptyText={this.showEmptyText}
                    handleLessonOpen={this.handleLessonOpen}                  />
                </Box>

              </Box>

              <Box sx={webStyle.thirdContainer}>
                {console.log(this.state.lessonCount,"count")}
                <Button style={webStyle.saveDraftButton} data-testId="modalOpen" onClick={() =>  
                this.handleSaveDraftCourse("draft")}>
                  <Typography>
                    Save Draft
                  </Typography>
                </Button>
                {
                  (this.state.isLessonAdded === "lessonAdded" &&  this.state.lessonCount.length === this.state.phasesContent.length)  ? (
                    <Button style={webStyle.createCoureseButton2} data-test-id={`createCourse`} onClick={()=>{this.sendActiveStatus()}}>
                      <Typography style={{fontWeight:600}}  data-test-id={`createCourseText`}>
                        Create Course
                      </Typography>
                    </Button>
                  ) : (
                    <Button style={webStyle.createCoureseButton}>
                      <Typography style={{fontWeight:600}}>
                        Create Course
                      </Typography>
                    </Button>
                  )
                }
              </Box>
            </>
          ) : (
            <>
              <Box sx={webStyle.mainContainer}>
                {this.state.content_value.length == 0 ? (
                  this.courseMainSecond()
                ) : (
                  this.courseMainThird()
                )}
                {this.state.content_value.length === 0 ? (
                  <Box style={webStyle.secondContainer}>
                    <MainNotFoundBox>
                      <MainInnerBox>
                        <Box sx={webStyle.imageContainer}>
                          <ImageComponent src={NotFoundImage} />
                        </Box>
                        <NotFoundText>You don’t have any content yet</NotFoundText>
                      </MainInnerBox>
                      <Box sx={webStyle.buttons}>
                        <Button
                          style={{
                            ...webStyle.secondaryButton,
                            border: "1px solid #652786",
                            backgroundColor: "#652786"
                          }}
                          data-test-id="addLessonWithoutID"
                          onClick={this.handleOpenLessonNew}

                        >
                          <Typography style={{...webStyle.buttonText,color: "#fff"}}>
                            + Add Lesson
                          </Typography>
                        </Button>
                      </Box>
                    </MainNotFoundBox>
                  </Box >

                ) : (
                  <Box sx={webStyle.containtContainer} data-testId="mouseLeaveAdd"
                    onMouseLeave={() => this.handleMouseLeave()}>
                    <LessonDragDrop
                    data-test-id="lesson-drag-id"
                      onDragEndLesson={this.onDragEndLesson}
                      contentLesson={this.state.content_value}
                      handleMouseEnter={this.handleMouseEnter}
                      handleLessonDeleteDialog={this.handleLessonDeleteDialog}
                      handleAddIconOpenLessonNew={this.handleAddIconOpenLessonNew}
                      addDialogBoxopen={this.state.addDialogBoxopen}
                      dialogImageToggle={this.state.dialogImageToggle}
                      handleAddIconClick={this.handleAddIconClick}
                      handleLessonEdit={this.handleLessonEdit}
                      hoverLessonId={this.state.hoverLessonId}
                      webStyle={webStyle}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={webStyle.thirdContainer}>
                <Button style={webStyle.saveDraftButton} data-testId="modalOpen1"
                        onClick={this.sendDraftStatus}
                >
                  <Typography style={{fontWeight:600}}>Save Draft</Typography>
                </Button>
                {this.state.content_value.length !== 0 ?
                  (
                    <Button style={webStyle.createCoureseButton2} data-test-id={`createLessonCourse`} onClick={this.sendActiveStatus}>
                      <Typography style={{fontWeight:600}}>Create Course</Typography>

                    </Button>
                  )
                  :
                  (
                    <Button style={webStyle.createCoureseButton}>
                      <Typography style={{fontWeight:600}}>Create Course</Typography>
                    </Button>
                  )
                }
              </Box>
            </>

          )
        }
        {<ModalMainBody
          open={this.state.createCourseModal}
          onClose={this.handleClose}>
          <ModalContents>
            <IconAligned onClick={this.handleCrossIconClose}><CloseIcon /></IconAligned>
            <ModatTexts>{configJSON.modalCreateCourseText}</ModatTexts>
            <ModatTextSecond>{configJSON.modalCreateCourseTextSecond}</ModatTextSecond>
            <ButtonBoxes>
              <ButtonGrouped>
                <ButtonShapeOne onClick={() => {this.handleClose(); this.handleToastSavedDraft(); this.handleSaveDraftCourse("draft")}} data-test-id="cancelBtnId">Save as Draft</ButtonShapeOne>
                <ButtonShapeTwo onClick={() => { this.handleCloseOne(); this.handleToastSavedActive(); this.handleSaveDraftCourse("active") }} style={{ color: "white", background: "#652786" }} data-test-id="backDraftId">Save as Active</ButtonShapeTwo>
              </ButtonGrouped>
            </ButtonBoxes>
          </ModalContents>
        </ModalMainBody>}
        {<ModalMainBody
          open={this.state.createCourseModalDraft}
          onClose={this.handleClose}
          data-test-id="modalOutId"
          >
          <ModalContents>
            <IconAligned onClick={this.handleCrossIconClose} data-test-id="crossIconId"><img src={IconClse} alt=""/></IconAligned>
            <ModatTexts>{configJSON.modalDraftText}</ModatTexts>
            <ModatTextSecond>{configJSON.modalDraftTextSecond}</ModatTextSecond>
            <ButtonBoxes>
              <ButtonGrouped>

              {this.renderConditions(isContentReleaseScheduleData, isPhasesContentEqualLessonCount, isContentValueNonEmpty)?
                <ButtonShapeOne onClick={() => {this.handleClose(); this.handleToastSavedActive(); this.handleSaveDraftCourse("active")}} data-test-id="cancelBtnNextId">{configJSON.saveTodraftButtonText}</ButtonShapeOne> : 
                <ButtonShapeOne data-test-id="addLessonAndShownToastId" onClick={() => {this.showAlert()}}>{configJSON.saveTodraftButtonText}</ButtonShapeOne>
                }


                <ButtonShapeTwo onClick={() => { this.handleCloseOne(); this.handleToastSavedDraft(); this.handleSaveDraftCourse("draft")}} style={{ color: "white", background: "#652786" }} data-test-id="backDraftNextId">Save Draft</ButtonShapeTwo>
              </ButtonGrouped>
            </ButtonBoxes>
          </ModalContents>
        </ModalMainBody>}
        <CreateNewGrid item md={6} xs={12} lg={2}>
        <Box>
          <Dialog
            data-test-id="Tooltip"
            open={this.state.openLesson}
            onClose={this.handleCloseLessson}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                margin: 'auto',
                maxWidth: 'fit-content',
                maxHeight: '80vh',
                overflowY: 'auto',
                zIndex: 9999,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                borderRadius: "10px"
              },
            }}
          >
            <MainDialogueComponent spacing={2}>
                      <Grid data-test-id="btn_click" 
                      style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" ,height:"100%", width: "100%",justifyContent:"center"}}  
                      item lg={12}
                      onClick={() => {
                this.gotoLessons("video");
              }}>
                <ImageComponentInner src={icon_video} />
                <ButtonComponent>
                  Video
                </ButtonComponent>
              </Grid>
              <BorderStyle/>
              <Grid data-test-id="btn_click1" style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" ,height:"100%", width: "100%",justifyContent:"center"}} item lg={12} onClick={() => {
                this.gotoLessons("audio");
              }}>
                <ImageComponentInner src={icon_audio} />
                <ButtonComponent>
                  Audio
                </ButtonComponent>
              </Grid>
              <BorderStyle/>
                      <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" ,height:"100%", width: "100%",justifyContent:"center"}} item lg={12} data-test-id="btn_click2"
                onClick={() => {
                  this.gotoLessons("document");
                }}>
                <ImageComponentInner src={icon_page} />
                <ButtonComponent>
                  Document
                </ButtonComponent>
              </Grid>
            </MainDialogueComponent>
          </Dialog>
        </Box>
        
          
        </CreateNewGrid>
        <ModalMainBody
        open={this.state.openPhaseDelete}
        onClose={this.cancelPhaseDeleteDialog}>
        <ModalContent>
          <IconAlign onClick={this.cancelPhaseDeleteDialog}><CloseIcon /></IconAlign>
          <ModalText>{`Are you sure you want to delete this ${this.state.phaseName} ?`}</ModalText>
          <ModalText1>{"When you delete a phase, will also delete all the content that are inside that phase."}</ModalText1>
          <ButtonBox>
          <ButtonGroup>
            <ButtonShape1 onClick={this.cancelPhaseDeleteDialog}>Cancel</ButtonShape1>
            <ButtonShape2 onClick={()=>this.handleDeletePhase(this.state.phaseID)} style={{color:"white", background:"#652786"}}>Delete</ButtonShape2>
          </ButtonGroup>
          </ButtonBox>
        </ModalContent>
      </ModalMainBody>

      <ModalMainBody
        open={this.state.openLessonDelete}
        onClose={this.cancelLessonDeleteDialog}>
        <ModalContent>
          <IconAlign onClick={this.cancelLessonDeleteDialog}><CloseIcon /></IconAlign>
          <ModalText>{`Are you sure you want to delete this ${this.state.lessonName} ?`}</ModalText>
          <ModalText1>{"When you delete a lesson, will also delete all the content that are inside that lesson."}</ModalText1>
          <ButtonBox>
          <ButtonGroup>
            <ButtonShape1 onClick={this.cancelLessonDeleteDialog}>Cancel</ButtonShape1>
            <ButtonShape2 onClick={()=>this.handleDeleteLesson(this.state.lessonID)} style={{color:"white", background:"#652786"}}>Delete</ButtonShape2>
          </ButtonGroup>
          </ButtonBox>
        </ModalContent>
      </ModalMainBody>

      </Box>
    );
    // Customizable Area End
  }
};

// Customizable Area Start

const CreateNewGrid = styled(Grid)({
  paddingRight: "0 !important",
  "& .btnBox": {
    width: "max-content",
    marginLeft: "auto"
  }
});

const ModalContainer = styled(Modal)({
  backgroundColor: "rgba(0, 0, 0, 0.55)",
});

const NotFoundText = styled(Typography)({
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "32px",
  color: "##000000",
  textAlign: "center",
  margin: "32px"
});

const NotFoundTextLesson = styled(Typography)({
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "24px",
  color: "##000000",
  textAlign: "center",
  marginTop: "32px"
});

const MainInnerBox = styled(Box)({
  height: "468px",
  marginBottom: "31px"
});

const LessonMainInnnerBox = styled(Box)({
  width: "450px",
  height: "250px",
});

const ImageComponent = styled("img")({
  width:"100%",
  height:"auto",
  maxWidth: "616.47px"
 
});
const MainDialogueComponent = styled(Grid)({
  height: "221px",
  backgroundColor: "#652786",
  width: "723px",
  "@media (max-width:960px)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "723px !important",
    backgroundColor: "#652786",
    width: "221px !important",
    flexDirection:"column"
  },
  
  "@media (min-width:720px)": {
    display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  },

});

const BorderStyle =styled('div')({

  "@media (max-width:960px)": {
    borderBottom: '1px solid #f3f0f0',
     width: '100%', 
     margin: '0 auto',
    borderLeft:"none !important",
    height:"0% !important",
  },
  "@media (min-width:720px)": {
    borderLeft: '1px solid #f3f0f0', margin: '0 auto',
    height:"100%",

  },

});

const ImageComponentInner = styled("img")({
  width: "30px !important",
  height: "30px !important"
});

const ButtonComponent = styled(Button)({
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "capitalize"
});
const MainNotFoundBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  flexDirection: "column",
  paddingBottom:"20px",
  paddingTop:"20px"
});

const  CourseTitleComponent = styled(Box) ({
  width: "50%",
  display: 'flex',
  gap: '24px'
});

const  CourseTitleNameComponent = styled (Typography)({
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 600,
  fontSize: "22px",
  lineHeight: "33px",
  color: "#1C1B1B"
});

const  DraftContainerComponent = styled(Box) ({
  width: "96px",
  height: "40px",
  borderRadius: "88px",
  padding: "6px 9px 6px 9px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: '#ffede4'
});

const  DraftTitleMain = styled(Typography) ({
  color: "#E47004",
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  textTransform:"capitalize" as const
});

const  ActiveContainerMain = styled(Box) ({
  width: "96px",
  height: "40px",
  borderRadius: "88px",
  padding: "6px 9px 6px 9px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#EEE8F2",
})

const  ActiveTitleMain = styled(Typography) ({
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  textTransform:"capitalize" as const,
  color: "#652786",
});


const ArchivedContainerMain = styled(Box) ({
  width: "96px",
  height: "40px",
  borderRadius: "88px",
  padding: "6px 9px 6px 9px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: '#ffd9df',
});


const ArchivedTitleMain = styled(Typography) ({
  color:"#E70E6B",
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  textTransform:"capitalize" as const
})


const webStyle = {
  leftContainer: {
    display: "flex",
    gap: "16px",
    width: "178px",
    justifyContent: 'center',
    alignItems: "center",
    flexWrap: { xs: "wrap", md: "noWrap" }
  },
  buttonsTop: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end"
  },
  buttonsLesson: {
    display: "flex",
    gap: { xs: "0px", sm: "10px" , md:"5px" },
    flexWrap: "wrap",
    justifyContent: { xs: "center", md: "end" },
    alignItems: "center",
    cursor: "pointer"
  },
  hr: {
    margin: '2px 0',
    width: "1px",
    border: '1px solid rgba(101, 39, 134, 1)',
    display: { xs: "none" as "none", md: "block" as "block" },
    height: "32px"

  },
  dialogBoxContaint: {
    width: "91px",
    border: "1px solid rgba(222, 222, 222, 1)"
  },
  mainContainer: {
    position: "relative"
  },
  innnerRowWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  innerContainer: {
    minHeight: "59px",
    backgroundColor: "#F4F0EF",
    boxShadow: "1px 2px 10px 0 rgba(0, 0, 0, 0.0677)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 60px",
    height: "auto"
  },
  innerRightContainer: {
    display: "flex",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center"
  },
  primaryText: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#929090"
  },
  spanText: {
    color: "#1C1B1B"
  },
  innerLeftContainer: {
    display: "flex",
    gap: "16px"
  },
  courseMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 24px",
  },
  courseTitle: {
    width: "50%",
    display: 'flex',
    gap: '24px'
  },
  courseTitleName: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "33px",
    color: "#1C1B1B"
  },
  activeContainer: {
    width: "96px",
    height: "40px",
    borderRadius: "88px",
    padding: "6px 9px 6px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EEE8F2",
  },
  activeTitle: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform:"capitalize" as const,
    color: "#652786",
  },

  DraftContainer: {
    width: "96px",
    height: "40px",
    borderRadius: "88px",
    padding: "6px 9px 6px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: '#ffede4'
  },

  draftTitle: {
    color: "#E47004",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform:"capitalize" as const
  },

  archivedContainer: {
    width: "96px",
    height: "40px",
    borderRadius: "88px",
    padding: "6px 9px 6px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: '#ffd9df',
  },

  archivedTitle: {
    color:"#E70E6B",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform:"capitalize" as const
  },
  addPhasesButton: {
    width: "164px",
    height: "36px",
    padding: "6px 10px 6px 10px",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  secondContainer: {
    height: "581px",
    padding: "20px 24px 20px 24px",
    gap: "31px",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    margin: "10px"
  },
  secondContainerLesson: {
    backgroundColor: "#F4F0EF"
  },
  thirdContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: '128px',
    gap: "16px",
    marginTop: "19px"
  },
  imageContainerLesson: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    height: "178px",
    width: "271.61px"
  },
  buttons: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap"
  },
  primaryButton: {
    width: "145px",
    height: "42px",
    borderRadius: "12px",
    gap: "16px",
    color: "#FFFFFF",
  },
  buttonText: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color:"#542070",
    textTransform:"capitalize" as const,
    margin:"0px 5px"
  },
  buttonText2: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center" as "center",
    textTransform:"capitalize" as const,
    cursor:"pointer",
  },
  saveDraftButton: {
    width: "345px",
    height: "56px", border: "1px solid #E47004",
    borderRadius: "16px",
    padding: "20px 32px 20px 32px",
    fontWeigth:600,
    gap: "10px",
    color: "#E47004",
    textTransform:"capitalize" as const
  },
  createCoureseButton: {
    width: "345px",
    height: "56px", border: "1px solid #E5E2E1",
    borderRadius: "16px",
    padding: "20px 32px 20px 32px",
    gap: "10px",
    backgroundColor: "#E5E2E1",
    textTransform:"capitalize" as const
  },
  createCoureseButton2: {
    width: "345px",
    height: "56px", border: "1px solid rgba(101, 39, 134, 1)",
    borderRadius: "16px",
    padding: "20px 32px 20px 32px",
    gap: "10px",
    backgroundColor: "rgba(101, 39, 134, 1)",
    color: "#FFF",
    textTransform:"capitalize" as const
  },
  addQuizButton: {
    width: "161px",
    height: "44px",
    padding: "10px",
    textTransform: "capitalize" as "capitalize",
    display: "flex",
    gap: "10px"
  },
  addQuizButtonPhases: {
    textTransform: "capitalize" as "capitalize",
    display: "flex",
    gap: "8px"
  },
  containtContainer: {
    height: "413px",
    borderRadius: "16px",
    overflowY: "scroll"
  },
  lessonContaintContainer: {
    borderRadius: "16px",
  },
  hoverContainer: {
    height: 0,
    border: "4px solid #B293C2",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  dialogBox: {
    position: "absolute" as "absolute",
    width: "125px",
    height: "auto",
    backgroundColor: "#FFFFFF",
    top: "2px",
    left: "50%",
    borderRadius: "12px",
    padding: "8px 16px 8px 16px",
    display: "flex",
    gap: "8px",
    flexDirection: "column" as "column",
    zIndex: 9 
  },
  dialogBoxLabel:{
    cursor:"pointer",
  },

  dialogBoxPhases: {
    backgroundColor: "#FFFFFF",
    height: "80px",
    zIndex: 9,
    position: "absolute" as "absolute",
    left: "38%",
    borderRadius: "12px",
    width: "140px",
    padding: "8px 16px 8px 16px",
    top: "33px",
    flexDirection: "column" as "column",
    gap: "8px",
    display: "flex",
  },
  dialogRenamePhases: {
    backgroundColor: "#FFFFFF",
    height: "40px",
    zIndex: 9,
    position: "absolute" as "absolute",
    left: "60px",
    borderRadius: "12px",
    width: "140px",
    padding: "8px 16px 8px 16px",
    top: "-20px",
    flexDirection: "column" as "column",
    gap: "8px",
    display: "flex",
  },
  modalCloseButton: {
    position: "absolute" as "absolute",
    top: "-16px",
    left: "94%",
    cursor: "pointer" as "pointer"
  },
  modalMainHeading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "33px",
    color:"#000"
  },
  modalSubHeading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "22px",
    display:"flex",
    gap:"5px",
    alignItems:"center",
    color:"#000"
  },
  modalLeftButton: {
    width: "214.5px",
    height: "42px",
    borderRadius: "12px",
    padding: "16px 24px 16px 24px",
    border: "1px solid rgba(228, 112, 4, 1)",
    color: "#E47004"
  },
  modalRightButton: {
    width: "214.5px",
    height: "42px",
    borderRadius: "12px",
    padding: "16px 24px 16px 24px",
    border: "1px solid rgba(101, 39, 134, 1)",
    backgroundColor: "rgba(101, 39, 134, 1)",
    color: "#FFF"
  },
  modalMainContainer: {
    position: 'relative',
    width: "525px",
    height: "177px",
    bgcolor: 'background.paper',
    borderRadius: "12px",
    border: '2px solid #000',
    padding: "24px 32px 24px 32px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    backgroundColor: "#FFF"
  },
  modalStyle: {
    display: 'flex',
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainerAdd: {
    width: "24px",
    height: "24px",
    size: "20px"
  },
  addPhaseButtonText: {
    fontFamily: "'Poppins', sans-serif" as "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color: "rgba(101, 43, 133, 1)",
    textTransform: "capitalize" as "capitalize",
    textDecoration:"underline",
    textDecorationColor:"#652B85"

  },
  containtContainer2: {
    borderRadius: "16px",
    height: "auto"
  },
  mainLessonWrapper: {
    minHeight: "59px",
    boxShadow: "1px 2px 10px 0 rgba(0, 0, 0, 0.07)",
    backgroundColor: "#EEE8F2",
    padding: "17px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 'auto'
  },
  lessonRightContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  dropDownArrow: {
    width: "24.66px",
    height: "24px",
  },
  courseTitleName2: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "33px",
    color: "#1A1A1A"
  },
  secondaryButton: {
    width: "162px",
    height: "42px",
    borderRadius: "12px",
    gap: "16px",
    color: "#FFFFFF",
  },
}

const ModalMainBody = styled(Modal)({
  display:"flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center"
});

const ModalContents = styled(Box)({
  backgroundColor: "#fff",
  position: "absolute",
  height: "auto",
  alignItems: "center",
  maxWidth: "525px",
  width: "80%",
  margin: "auto",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "12px",
  "@media (max-width: 656px)": {
    flexWrap: "wrap"
  },
  "@media (max-width: 400px)": {
    maxWidth:"330px",
  },
    padding: "19px 0px 20px 20px"
});

const IconAligned = styled(Box)({
  right: "-6",
  position: "absolute",
  top: "-6",
  color: "white",
  backgroundColor: "#652786",
  borderRadius: "50%",
  fontSize:"22px",
  "& .MuiSvgIcon-root":{
    fontSize:"20px",
  }
});

const ModatTexts = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  justifyContent: "left",
  padding: "0px 20px 5px 20px",
  fontWeight: 500,
  fontSize: "22px"
});

const ModatTextSecond = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:400,
  fontSize:"16px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const ButtonShapeOne = styled(Button)({
  width: "228px",
  height: "42px",
  fontWeight: 600,
  border: "1px solid #E47004",
  borderRadius: "16px",
  fontSize: "16px",
  background: "1px solid #652786 !important",
  textTransform: "none",
  color: "#E47004",
  "@media (max-width: 656px)": {
   width: "100%"
  },
});

const ButtonGrouped = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "23px",
  padding: "10px 20px 0px 0px",
  justifyContent: "space-between",
  "@media (max-width: 656px)": {
    flexWrap: "wrap"
  },
});

const ButtonBoxes = styled(Box)({
  width: "100%",
  padding: "0px 10px 0px 10px",
  "& .MuiButton-root:hover":{
    backgroundColor: "white",
  }
});

const ButtonShapeTwo = styled(Button)({
  fontWeight: 600,
  width: "228px",
  height: "42px",
  background: "1px solid ##652786 !important",
  borderRadius: "16px",
  fontSize: "16px",
  textTransform: "none",
  color: "#E47004",
  "@media (max-width: 656px)": {
   width: "100%"
  },
});

// Delete modal 

const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "auto",
  padding: "9px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  paddingTop: "20px",
  paddingBottom: "25px",
  "@media (max-width: 656px)": {
    maxWidth:"400px",
  },
  "@media (max-width: 400px)": {
    maxWidth:"330px",
  }
});

const ModalText = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:500,
  fontSize:"22px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const ModalText1 = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:400,
  fontSize:"16px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const IconAlign = styled(Box)({
  position: "absolute",
  top: "-6",
  right: "-6",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white",
 
  "& .MuiSvgIcon-root":{
    fontSize:"20px",
  }
});

const ButtonGroup = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent:"space-between",
  gap: "23px",
  padding: "0px 2px 0px 2px"
});

const ButtonShape1 = styled(Button)({
  width: "48%",
  height: "42px",
  fontWeight:600,
  fontSize:"16px",
  background: "1px solid #652786 !important",
  border: "1px solid #E47004",
  borderRadius:"12px",
  color: "#E47004",
  textTransform:"none",
});

const ButtonShape2 = styled(Button)({
  width: "48%",
  height: "42px",
  background: "1px solid ##652786 !important",
  borderRadius:"12px",
  fontWeight:600,
  fontSize:"16px",
  color: "#E47004",
  textTransform:"none",
});

const ButtonBox = styled(Box)({
  padding:"10px 10px 0px 10px",
  width:"100%",
  "& .MuiButton-root:hover":{
    backgroundColor: "white",
  }
});

// Customizable Area End


// @ts-ignore
//@ts-nocheck
export default AddContentPage
