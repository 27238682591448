// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");  

export interface Props {
  navigation: any;
  history:any;
  location:any;
  id: string;
}

interface S {
    inProgressData:any[];
    completedData:any[];
}

interface SS {
  id: any;
}

export default class MyContentController extends BlockComponent<
  Props,
  S,
  SS
> {
    getInProgressCallId:string = "";
    getCompletedCallId:string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
    inProgressData: [],
    completedData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(){
    this.getInProgressData();
    this.getCompletedData()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if(apiRequestCallId === this.getInProgressCallId && responseJson.data){
            this.setState({inProgressData:responseJson.data})
        }
        if(apiRequestCallId === this.getCompletedCallId && responseJson.data){
          this.setState({completedData:responseJson.data})
      }
      }
  }

  getInProgressData = () => {
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getInProgressCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_catalogue/progress_libraries`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'get'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompletedData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompletedCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/completed_libraries`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  getTransformValue = (currentIndex:number,itemsToShow:number) => {
    return -(currentIndex * 25) / itemsToShow
  }

  gotoBackSide = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Library2WebPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToAnyPage = (pageLink:string) => {
     const message = new Message(getName(MessageEnum.NavigationMessage));
     message.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
     message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
     this.send(message); 
  }

  handleNavigateTOProduct = (courseType:any) => {
   if(courseType.attributes.collection.attributes.expand_type === 'Course'){
        this.props.history.push(`/course-detail/${courseType.attributes.collection.attributes.expand_type}/${courseType.attributes.collection.id}/${courseType.attributes.collection.attributes.title.replace(/\s/g, '-')}`)    
  } else if(courseType.attributes.collection.attributes.expand_type === 'Program'){
      this.props.history.push(`/program-detail/${courseType.attributes.collection.attributes.expand_type}/${courseType.attributes.collection.id}/${courseType.attributes.collection.attributes.title.replace(/\s/g, '-')}`) 
  }
  }
  // Customizable Area End
}