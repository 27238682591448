import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AdminCatagoryListing, AdminContentRoles, ExpertListing, CheckboxSelectedListType } from "../../catalogue/src/CatalogueController";
import { UserTeamsListing,UserBranchesListing } from "../../accountgroups/src/UserManagementController.web";

export interface ExpertList{
  id: string,
  type: string,
  attributes: {
      first_name: string,
      last_name: string,
      email: string,
      full_name:string,
      profession:  string,
      avatar: null,
      is_admin: boolean
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?:any;
  handleClear: () => void;
  handleChangeStatus?: (status: string) => void;
  handleContentRoleSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  categoryCheckboxChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>,id:  string) => void;
  handleCategorySearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderCheckboxChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>, id:  string) => void;
  placeholderCheckboxUserManageChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>, id:  string) => void;
  placeholderCheckboxUserManageChangeHandlerBranch?: (event: React.ChangeEvent<HTMLInputElement>, id:  string) => void;
  contentRoleCheckboxChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>, id:  string) => void;
  handlePlaceholderSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePlaceholderUserSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePlaceholderUserSearchChangeBranch?: (event: React.ChangeEvent<HTMLInputElement>) => void; 
  creationDateChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  dropdownOpenHandler: (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => void;
  handleCalendarChange:((value: any) => void);
  dropdownCloseHandler: () => void;
  handleCalendarOpen: () => void;
  anchorEl: null | HTMLElement;
  dropdownType: string;
  categorySelectedItems?: Array<CheckboxSelectedListType>;
  contentRoleSearchText?: string;
  contentRoleSelectedItems?: Array<CheckboxSelectedListType>;
  categorySearchText?: string;
  placeholderSelectedItems: Array<CheckboxSelectedListType>;
  placeholderSelectedTeamsItems?: Array<CheckboxSelectedListType>;
  placeholderSelectedBranchItems?: Array<CheckboxSelectedListType>;
  tagsSelectedItems?: Array<CheckboxSelectedListType>;
  tagsSearchText?: string;
  handleTagsSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreatorSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderCreatorSearchText?:string;
  tagsCheckboxChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>, id:  string) => void;
  placeholderSearchText?: string;
  placeholderSearchBranchText?: string;
  creationDate?: string;
  contentCreationDate?: Array<CheckboxSelectedListType>;
  handleClick:  () => void;
  selectedStatus?: string;
  expertAdminList?: Array<ExpertListing>;
  userTeamsList?: Array<UserTeamsListing>;
  userBranchList?:  Array<UserBranchesListing>;
  adminCatagoryList?: Array<AdminCatagoryListing>;
  adminContentRolesList?: Array<AdminContentRoles>;
  isCalendarOpen: boolean;
  tagListing?: Array<any>
  createrNameListing?: Array<any>;
  creationCheckboxChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  creationSelectedItems?: Array<CheckboxSelectedListType>;
  fileSelectedItems?: Array<{value:string, id:string}>;
  isThumbnail?:boolean;
  isContentRole?:boolean;
  fileCheckboxChangeHandler?: (event:React.ChangeEvent<HTMLInputElement>, doc:string)=> void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedValue: string;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class FilterItemsControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedValue: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
