// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const EditIcon = require("../assets/edit.png");
export const SearchIcon = require("../assets/search.png");
export const UpArrowIcon = require("../assets/UpArrow.svg");
export const DownArrowIcon = require("../assets/DownArrow.svg");
export const SelectArrow = require("../assets/select_arrow.svg");
export const AddIcon = require("../assets/add_icon.png");
export const UploadIcon = require("../assets/upload.png");
export const DownloadIcon = require("../assets/download.png");
export const TrashIcon = require("../assets/trash_icon.png");
export const CloseModal = require("../assets/close-circle.png");
export const Filter = require("../assets/filter.svg");
export const infoIcon = require("../assets/info-icon.svg");
export const EditIcon2 = require("../assets/edit-icon.png");
export const EditIcon3 = require("../assets/icon_edit.png");
export const deleteIcon = require("../assets/delete_Icons.png");
export const plusIcon = require("../assets/plus-icon.png");
export const infoIcon2 = require("../assets/info-icon.png");
export const searchIcon = require("../assets/image_.svg");
export const closeIcon = require("../assets/close_icon.svg");
export const NotFoundImage = require("../assets/NotFoundImage.jpeg")
export const addContentImg = require("../assets/image_select.png");
export const imageHtmlCourse2 = require("../assets/image_html_course2.png")
export const rightArrow = require("../assets/right_arrow.png")
// Customizable Area End