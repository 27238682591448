import {
    Box,
    Button,
    Typography,
  } from "@material-ui/core";
import React from 'react';
import { styled} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

interface Props {
  modalOpened:boolean, 
  goBackButtonText:string, 
  heading:string, 
  bodyMessage:string, 
  handleCancel:()=>void , 
  handleLeave:()=>void,
  handleClose:()=>void
}

const  iconClose = require("./icon_close.png");
const GoBackConfirmationModal = ({modalOpened, goBackButtonText, heading, bodyMessage, handleCancel, handleLeave, handleClose}:Props)=>{
    return(
       <ModalContainer
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={modalOpened}
          onClose={handleClose}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
        >
            <ModalMainContainer>
              <ModalMainHeading>
                {heading}
              </ModalMainHeading>
              <ModalSubHeading id="server-modal-description">
               {bodyMessage}
              </ModalSubHeading>
              <ButtonBox>
                <ModalLeftButton onClick={handleLeave}>
                  <Typography data-test-id="openModalBackModalID" style={{ textTransform: "none" }} >Leave</Typography>
                </ModalLeftButton>
                <ModalRightButton onClick={handleCancel}>
                  <Typography data-test-id="modalBackModalID" style={{ textTransform: "none" }}>{!!goBackButtonText ? goBackButtonText:"Keep Creating"}</Typography>
                </ModalRightButton>
              </ButtonBox>
                <ImageComponent
                  data-testId="modalClose"
                  onClick={handleClose}
                  src={iconClose}
                  alt="close"
                  data-test-id="closeModal"
                />
            </ModalMainContainer>
        </ModalContainer>
    )
  }


  const ModalContainer = styled(Modal)({
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    display: 'flex',
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center'
  });

  const ModalMainContainer = styled(Box)({
    position: 'relative',
    width: "525px",
    minHeight: "177px",
    bgcolor: 'background.paper',
    borderRadius: "12px",
    border: '2px solid #000',
    padding: "24px 32px 24px 32px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    "@media (max-width: 750px)": {
      width: "70%",
    },
  });
  
  const ModalLeftButton = styled(Button)({
    width: "214.5px",
    height: "42px",
    borderRadius: "12px",
    padding: "16px 24px 16px 24px",
    border: "1px solid #E47004",
    color: "#E47004",
    "@media (max-width: 370px)": {
      width: "97%",
      height: "fit-content",
      padding:"16px"
    },
    '& .MuiTypography-body1':{
      fontWeight: 600,
      fontSize: "16px",
    }
  });
  
  const ModalRightButton = styled(Button)({
    width: "214.5px",
    height: "42px",
    textAlign:"center",
    borderRadius: "12px",
    padding: "16px 24px 16px 24px",
    border: "1px solid #652786",
    backgroundColor: "#652786",
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#5A2972", 
      borderColor: "#5A2972",
    },
    "@media (max-width: 370px)": {
      width: "97%",
      height: "fit-content",
      padding:"16px"
    },
    '& .MuiTypography-body1':{
      fontWeight: 600,
      fontSize: "16px",
    }
  });
  
  const ModalMainHeading = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "33px",
    color: "#1C1B1B",
    marginBottom:"8px"
  });

  const ModalSubHeading = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1C1B1B"
  });

  const ButtonBox = styled(Box)({
    display: "flex",
    marginTop: "24px",
    gap: "10px",
    justifyContent:"space-between",
    "@media (max-width: 750px)": {
      justifyContent:'center',
      flexDirection:'column',
      alignItems:'center'
    },
  });

  const ImageComponent = styled("img")({
    position: "absolute" as "absolute",
    top: "-11px",
    right: "-9px",
    width: "24.66px",
    height: "24px",
    cursor: "pointer"
  });

export default GoBackConfirmationModal