import React, { useEffect } from "react";
import {
  Box, Button,
  // Customizable Area Start
  Grid,
  // Customizable Area End
  Paper,
  TextField, Typography,
  Accordion, AccordionDetails,
  AccordionSummary, Backdrop,
  Checkbox, CircularProgress,
  FormControl,
  MenuItem, Select
} from "@material-ui/core";
// Customizable Area Start
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RangeSlider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme, makeStyles, styled } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import { Bar, Line } from 'react-chartjs-2';
import Slider from "react-slick";
import PersonalSlider from "../../../components/src/LibraryComponent/PersonalSlider";
import XpandSlider from "../../../components/src/LibraryComponent/XpandSlider";
import CorporateSlider from "../../../components/src/LibraryComponent/CorporateSlider";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Shape, iconVideo, noContentImg } from "./assets";
import './videostyles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import CustomCarousel from "../../../../packages/components/src/CustomCarousel/CustomCarousel";
// Customizable Area End
import Library2Controller, {
  LibraryDataType,
  Props
} from "./Library2Controller.web";

export default class Library2 extends Library2Controller {
  // Customizable Area Start
  renderContentData = (itemsToShow:number) => {
    return (
<>
                {this.sortingMyContentData().filter((conent:any,index:number)=>index < 5).map((conent: any, index: number) => {
                  const totalVideoCount = conent.attributes.total_video_count || 0;
                  const completedVideoCount = conent.attributes.completed_video_count || 0;
                  const completionPercentage = totalVideoCount
                    ? (completedVideoCount / totalVideoCount) * 100
                    : 0;
                  return (
                <Box style={{
                  margin:"0 15px" ,
                  height: "407px",
                  position: "relative",
                  width:"270px",
                  cursor:"pointer"
                }} key={index} onClick={()=>this.handleNavigateTOProduct(conent)}>
                  <Box
                    style={{
                      height: "181px",
                      padding: "16px 0px 0px 0px",
                      gap: "13px",
                      borderRadius: "12px 0px 0px 0px",
                      opacity: "0px",position:"relative"
                    }}>
                    <img width="100%" height="100%" style={{borderRadius:"12px"}} src={conent.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                    {conent.attributes.collection.attributes.expand_type === "Program" && 
                    <Typography style={{...styles.chipTypography,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{conent.attributes.collection.attributes.expand_type}</Typography>}
                  </Box>

                  <Box
                    style={{
                      width:"100%",
                      height: "242px",
                      padding: "24px",
                      borderRadius: "12px ",
                      position: "absolute",
                      bottom:5,
                      backgroundColor:"white",
                      boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"
                    }}>
                      <Box style={{
                        display:"flex",
                        gap: "16px",
                        flexDirection:"column"

                      }}>

                     
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "22px",
                        textAlign: "left",
                        color:"#1C1B1B"
                      }}>{conent.attributes.collection.attributes.title}</Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "22px",
                      }}
                    >{conent.attributes.collection.attributes.created_by}</Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        color:"#484646"
                      }}
                    >{(conent.attributes.due_in_days === "Overdue" || conent.attributes.due_in_days === "Completed") ? conent.attributes.due_in_days : `Due in ${conent.attributes.due_in_days} Days`}</Typography>

                    <Box style={{display:"flex",justifyContent:"space-between",position:"absolute",bottom:"20px"
                      ,width:"80%"
                    }}>
                      <Box>
                      <Box style={{ 
    width: "154px", 
    height: "10px", 
    borderRadius: "88px", 
    backgroundColor: "rgba(236, 195, 195, 0.23)", 
    position: "relative" 
  }}>
    <span style={{ 
      display: "block", 
      width: `${completionPercentage}%`, 
      height: "10px", 
      borderRadius: "88px", 
      background: "linear-gradient(155.66deg, #EF2B4B -1.02%, #FF8B3F 68.24%)",
      position: "absolute", 
      top: 0, 
      left: 0 
    }}></span>
  </Box>
                        <Typography style={{
                          color: "var(--sys-color-Secondary, rgba(228, 112, 4, 1))",
                          fontWeight:400,fontSize:"12px",
                          marginTop:"10px"
                        }}>
                      {`${conent.attributes.completed_video_count}/${conent.attributes.total_video_count} Lessons Completed`}
                      </Typography>
                      </Box>
                      <Box style={{
                          width:"40px",height:"40px",borderRadius:"8px",
                          boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                          display:"flex",justifyContent:"center",alignItems:"center"
                        }}>
                        <img src={iconVideo}/>
                        </Box>
                     </Box>
                  </Box>
                  </Box>



                </Box>
              )})}</>
    )
  }

  renderContent = () => {
    return (
      <Box style={{gap:"16px",margin:"10px 0px 10px 20px"}}>
      <Paper className="paper" style={{ display: "flex",justifyContent:"space-between",alignItems:"center", }}>
        <Typography style={{...styles.typography_personal,marginLeft:"5px"}}>My content</Typography>
          <Typography style={{...styles.flexBetween,color:"#652786",cursor:"pointer"}}
            id="navigate-personal-tst-btn" onClick={this.gotoMyContentPage}>View More <ChevronRightIcon />
            </Typography>
          </Paper>
          <Box className="cutomCarouselBox">
        <CustomCarousel data-test-id="contentDataCallId" children={this.renderContentData} datavalue={this.state.myContentData} getTransformValue={this.getTransformValue}/>
        </Box>
    </Box>
    )
  }

  renderOffers = (itemsToShow:number) => {
    return (
      this.state.libraryData.map((slide:LibraryDataType, index:number) => (
        <div
          className="carousel-item"
          key={index}
          onClick={()=> this.handleNavigateTOCourse(slide)}
          style={{ width: `calc(100% / ${itemsToShow})`,margin:"0 10px" }}
          title={slide.attributes.title.length>20 ? slide.attributes.title : ""}
        >
          <img src={slide.attributes.banner_image.url} alt={`Slide ${index}`} />
          <div className="carousel-text">
              <h2 style={{ fontSize: '24px', fontWeight: 600 }}>
            {
              slide.attributes.title.length>20 ? (
                `${slide.attributes.title.slice(0,20)}...`
              ) :(
                slide.attributes.title
              )
            }
              </h2>
            <p style={{ fontSize: '16px', fontWeight: 600, width:"100%"}}>
              {slide.attributes.description}
            </p>
          </div>
        </div>
      ))
    )
  }
  // Customizable Area End

  render() {
    return (
      <Grid container spacing={4} style={{ paddingTop: "25px" }}>
        {this.state.myContentData.length > 0 ? (
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '0px', }}>
          <Box style={{ paddingLeft: '15px' }}>
            <Typography style={styles.mainhadding} variant="h3" className="profile_header">Library</Typography>
          </Box>
          <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={this.state.loaderOpen} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box>
            <Box style={{
              background: "linear-gradient(262.33deg, #FFFFFF 0%, #F0F3F6 100%)",
              padding:"24px",
              borderRadius:'16px',
              margin:"10px 0 10px 10px"
            }}>
      <Typography style={styles.offerTypography}>Latest offers for you</Typography>
        <CustomCarousel data-test-id="sliderComponentId" children={this.renderOffers} datavalue={this.state.libraryData}/>
        </Box>
                     {this.renderContent()}
            <Paper className="paper" style={styles.filterPaper}>
              <Grid container>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                  <Box sx={{ display: { lg: 'block !important', md: 'block !important', sm: 'none', xs: 'none' } }}>
                    <Typography style={styles.learningHadding}>Learning Progress</Typography>
                    {this.state.show ? <LineChart2 state={this.state.chartData?.this_time_data} /> :
                      <LineChart state={this.state.chartData.last_time_data} />}
                  </Box>
                  <Box sx={{ display: { xs: 'block !important', sm: 'none !important', md: 'none !important', lg: 'none !important' } }}>
                    <Box style={{ fontFamily: "sans-serif", textAlign: "center" }}>
                      <BarCart Week={this.state.barWeek} state={this.state.chartData} />
                    </Box>
                    <Box style={styles.BarDropBox}>
                      <Typography style={styles.BarDropTypography}>
                        <FormControl style={styles.formControl}>
                          <Select
                            style={styles.FormControlSlect}
                            value={this.state.selected}
                            onChange={this.handleChange}
                            disableUnderline
                            inputProps={{
                              name: "agent",
                              id: "age-simple"
                            }}
                          >
                            {this.state.values.map((value: any, index: any) => {
                              return <MenuItem key={value} onClick={() => this.handleClickChart(value)} value={value}>{value}</MenuItem>;
                            })}
                          </Select>
                        </FormControl></Typography>

                      <Button variant="contained" color="primary" onClick={this.BarWeektoggle} id="filter-week-btn-test">
                        {this.state.barWeek ? "This Week" : "Last Week"}
                      </Button>
                    </Box>
                  </Box>
                  <Box sx={{ display: { xs: 'none !important', sm: 'block !important', md: 'none !important', lg: 'none !important' } }}>
                    <Box style={{ fontFamily: "sans-serif", textAlign: "center" }}>
                      <BarCart1 Week={this.state.barWeek} state={this.state.chartData} />
                    </Box>
                    <Box style={styles.barCart2MainBox}>
                      <Typography style={styles.barCart2Typography}>
                        <FormControl style={styles.barCart2formControl}>
                          <Select
                            style={styles.select}
                            value={this.state.selected}
                            onChange={this.handleChange}
                            disableUnderline
                            inputProps={{
                              name: "agent",
                              id: "age-simple"
                            }}
                          >
                            {this.state.values.map((value: any, index: any) => {
                              return <MenuItem id="hanle-chart-menu-click-tst" key={value} onClick={() => this.handleClickChart(value)} value={value}>{value}</MenuItem>;
                            })}
                          </Select>
                        </FormControl></Typography>
                      <Button variant="contained" color="primary" onClick={this.BarWeektoggle}>
                        {this.state.barWeek ? "This Week" : "Last Week"}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} >
                  <Box sx={{ display: { lg: 'block !important', md: 'block !important', sm: 'none', xs: 'none' } }}>
                    <Box style={{ display: 'flex', marginLeft: '-72%', marginTop: '-20px' }}>
                      <Typography style={{ fontWeight: 700, fontSize: '17px', float: 'none', marginTop: '17px', cursor: 'pointer' }}>
                        <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                          <Select
                            style={{ width: "220px", height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none', textTransform: 'capitalize' }}
                            value={this.state.selected}
                            onChange={this.handleChange}
                            disableUnderline
                            inputProps={{
                              name: "agent",
                              id: "age-simple"
                            }}
                          >
                            {this.state.values.map((value: any, index: any) => {
                              return <MenuItem id="unique-item-click-test-id" key={value} onClick={() => this.handleClickChart(value)} value={value}>{value}</MenuItem>;
                            })}
                          </Select>
                        </FormControl></Typography>
                      <Typography style={{ paddingLeft: '15px', fontWeight: 700, fontSize: '17px', float: 'right', marginTop: '20px', cursor: 'pointer' }}>
                        <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                          <Select
                            style={{ width: "220px", textTransform: 'capitalize', height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none' }}
                            value={this.state.weekSelected}
                            onChange={this.weekhandleChange}
                            disableUnderline
                            inputProps={{
                              name: "agent",
                              id: "age-simple"
                            }}
                          >
                            {this.state.weekValue.map((value: any, index: any) => {
                              return <MenuItem id="handle-week-btn-test-01" key={value} onClick={() => this.handleClickWeek(value)} value={value}>{value}</MenuItem>;
                            })}


                          </Select>
                        </FormControl></Typography>
                    </Box>
                    <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "35px", marginBottom: '-10px', marginLeft: '40px' }}>Videos Watched</Typography>
                    <Grid container style={{ paddingTop: '0px' }}>
                      <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '30px' }}>
                        <Box style={{ display: 'flex', marginTop: '30px', }}>
                          <Box style={{ width: '16px', height: '16px', backgroundColor: '#1b10e6', borderRadius: '10px' }}></Box>
                          <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>This Week</Typography>
                        </Box>
                        <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "21px", marginLeft: '15px' }}>{this.state.chartData?.meta?.this_count}</Typography>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '30px' }}>
                        <Box style={{ display: 'flex', marginTop: '30px' }}>
                          <Box style={{ width: '16px', height: '16px', backgroundColor: '#1fd1a8', borderRadius: '10px' }}></Box>
                          <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>Last Week</Typography>
                        </Box>
                        <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "21px", marginLeft: '15px' }}>{this.state.chartData?.meta?.last_count}</Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box style={{ display: 'flex', marginTop: '30px', marginLeft: '40px' }}>
                          <Box style={{ width: '16px', height: '16px', backgroundColor: '#1097e6', borderRadius: '10px' }}></Box>
                          <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>Total Videos</Typography>
                        </Box>
                        <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "5px", marginLeft: '75px' }}>{this.state.chartData?.meta?.total_count[0].count}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

            </Paper>
          </Box>
          <Paper className="paper" style={styles.paper_personal}>
            <Typography style={styles.typography_personal}>Personal</Typography>
            <Typography style={styles.typography_view_personal}
              id="navigate-personal-tst-btn"
              onClick={() => { return this.props.navigation.navigate('PersonalPageWeb') }}>View More <img src={Shape} style={styles.img_shape} /></Typography>
            <PersonalSlider style={this.state.style} last_trackVideo={this.last_trackVideo} handleNavigate={this.handleNavigate} props={this.props} personalData={this.state.allLibraryData.personal} />
          </Paper>



          <Paper className="paper" style={styles.paper_corporate}>
            <Typography style={styles.typography_personal}>Corporate Courses</Typography>
            <Typography style={styles.typography_view_personal}
              id="navigate-corporate-tst-btn"
              onClick={() => {
                return this.tostSuccessfully()
              }}>View More <img src={Shape} style={styles.img_shape} /></Typography>
            <CorporateSlider last_trackVideo={this.last_trackVideo} CorporateData={this.state.allLibraryData} handleNavigate={this.handleNavigate} tostSuccessfully={this.tostSuccessfully} style={undefined} />
          </Paper>


          <Paper className="paper" style={styles.paper_xpand}>
            <Typography style={styles.typography_personal}>Xpand Collection</Typography>
            <Typography style={styles.typography_view_personal}
              id="navigate-xpand-tst-btn"
              onClick={() => {
                return this.props.navigation.navigate('XpandPageWeb')
              }}>View More <img src={Shape} style={styles.img_shape} /></Typography>
            <XpandSlider style={this.state.style} postplayButton={this.postplayButton} handleNavigate={this.handleNavigate} props={this.props} XpandData={this.state.allLibraryData} postXpandLibraryData={this.postXpandLibraryData} handleSubscription={this.handleSubscription} addCartItem={this.addCartItem} />
          </Paper>
        </Grid>
        ):(
<Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '0px', gap:"56px" }}>
<Typography style={{fontSize:"48px",fontWeight:700}} variant="h3">Library</Typography>
  <Box style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
    <img width={"100%"} style={{maxWidth:"673px"}} src={noContentImg} alt='Image Loading...'/>
    <Typography style={{
      fontWeight: 600,
      fontSize: "22px",
      textAlign:"center",
    }}>
      Content will be assigned to you soon.</Typography>
  </Box>
  </Grid>
        )}
      </Grid>
    )

  }
}

const styles = {
  mainhadding: {
    display: 'contents'
  },
  mainContainer: {
    display: 'flex'
  },
  BoxContainer: {
    width: '130px',
    height: '45px',
    marginTop: '3px'
  },
  filterButton: {
    color: '#6C328B',
    fontWeight: 700,
    height: '45px',
    width: '100%',
    border: '1.5px solid #6C328B',
    borderRadius: '10px',
  },
  flexBetween:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  IconBox: {
    width: '100%',
    marginTop: '3px',
    paddingLeft: '15px'
  },
  filterIcon: {
    width: '40px',
    height: '46px',
    borderRadius: '10px',
    border: '1.5px solid #6C328B',
    cursor: 'pointer'
  },
  mainOfferPaper: {
    flex: 1,
    marginTop: '25px',
    padding: '30px',
    borderRadius: '24px',
    background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
    overflow: 'hidden',
    maxWidth: '90vw',
    height: '384px',
    margin: '25px auto auto auto'
  },
  offerTypography: {
    fontWeight: 600,
    marginBottom: '20px',
    fontSize: '24px'
  },
  filterPaper: {
    overflow: 'hidden',
    padding: '30px',
    background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
    borderRadius: '24px',
    flex: 1,
    maxWidth: '90vw',
    height: '384px',
    margin: 'auto',
    marginTop: '25px'
  },
  learningHadding: {
    fontWeight: 700,
    fontSize: '24px'
  },
  BarDropBox: {
    display: 'inline-grid',
    marginTop: '8px',
    marginLeft: '25px',
    justifyContent: 'space-around'
  },
  BarDropTypography: {
    fontWeight: 700,
    fontSize: '17px',
    // float: 'right',
    marginTop: '-30px',
    cursor: 'pointer'
  },
  formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  chipTypography:{
    height:"28px",
    width: "117px",
    borderRadius: "16px",
    padding: "3px 0px",
    fontWeight:400,
    color:"white",
    background: "#E70E6B",
    fontSize:"14px",
  },
  FormControlSlect: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  },
  barCart2MainBox: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  barCart2Typography: {
    fontWeight: 700,
    fontSize: '17px',
    // float: 'right',
    marginTop: '0px',
    cursor: 'pointer'
  },
  barCart2formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  select: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  },
  paper_personal: {
    flex: 1,
    overflow: 'hidden',
    height: '490px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  },
  typography_personal: {
    fontWeight: 700,
    fontSize: '24px'
  },
  typography_view_personal: {
    fontWeight: 700,
    fontSize: '17px',
    float: 'right' as 'right',
    marginTop: '-36px',
    cursor: 'pointer',
    color: 'rgba(97, 97, 97, 1)'
  },
  img_shape: {
    paddingLeft: '13px'
  },
  paper_corporate: {
    overflow: 'hidden',
    flex: 1,
    height: '500px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  },
  paper_xpand: {
    overflow: 'hidden',
    flex: 1,
    height: '500px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  }
}

const useStyles = makeStyles((theme: any) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export const Search = ({ _onFocus }: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.search}>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <TextField onFocus={_onFocus} className="Rounded" inputProps={{ maxlength: 50 }} style={{ width: '100%', borderRadius: '12px', marginTop: '3px' }} placeholder=" Search" variant="outlined" />

    </Box>
  )
}

export const LineChart = ({ state }: any) => {
  if (!state) {
    state = []
  }
  const data = {
    labels: state.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    responsive: true,// labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [
      // data:[50,40,65,55,45,80],
      {
        id: 1,
        label: '',
        data: state.map((data: any) => data.count),//[50, 40, 65, 55, 45, 80],
        backgroundColor: 'transparent',
        borderColor: '#1fd1a8',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },


    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <Box style={{ width: '100%', height: '250px', marginTop: '20px' }}>
      <Line width="915px" height="250px" data={data} options={options}></Line>
    </Box>
  )
}



export const LineChart2 = ({ state }: any) => {
  const data = {
    responsive: true,// labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [

      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[35, 45, 50, 56, 45, 70],
        backgroundColor: 'transparent',
        borderColor: '#0786ed',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <Box style={{ width: '100%', height: '250px', marginTop: '20px' }}>
      <Line width="915px" height="250px" data={data} options={options}></Line>
    </Box>
  )
}



export const LineChart3 = ({ state }: any) => {
  const data = {
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    responsive: true,
    // labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [
      // data:[50,40,65,55,45,80],
      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[50, 40, 65, 55, 45, 80],
        backgroundColor: 'transparent',
        borderColor: '#1fd1a8',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <Box style={{ width: '850px', height: '250px', marginTop: '20px' }}>
      <Line width="850px" height="250px" data={data} options={options}></Line>
    </Box>
  )
}



export const LineChart4 = ({ state }: any) => {
  const data = {

    responsive: true,// labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [

      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[35, 45, 50, 56, 45, 70],
        backgroundColor: 'transparent',
        borderColor: '#0786ed',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <Box style={{ width: '850px', height: '250px', marginTop: '20px' }}>
      <Line width="850px" height="250px" data={data} options={options}></Line>
    </Box>
  )
}

export const BarCart = ({ state, Week }: any) => {
  const data = {
    labels: state?.this_time_data?.map((data: any) => data.track_date),
    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.this_time_data?.map((data: any) => data.count)
      }
    ]
  }

  const data1 = {
    labels: state?.last_time_data?.map((data: any) => data.track_date),
    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.last_time_data?.map((data: any) => data.count)
      }
    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };


  return (
    <Box style={{ marginTop: '-15px', width: "100%", height: "100%" }} >

      {Week ?
        <Bar data={data} width="100%" height="100%" options={options}></Bar> :
        <Bar data={data1} width="100%" height="100%" options={options}></Bar>
      }
    </Box>
  )
}

export const BarCart1 = ({ state, Week }: any) => {
  const data = {
    labels: state?.this_time_data?.map((data: any) => data.track_date),

    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.this_time_data?.map((data: any) => data.count)

      }
    ]
  }


  const data1 = {
    labels: state?.last_time_data?.map((data: any) => data.track_date),

    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.last_time_data?.map((data: any) => data.count)

      }
    ]
  }



  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <Box style={{ marginTop: '0px' }} >
      {Week ?
        <Bar data={data} options={options}></Bar> :
        <Bar data={data1} options={options}></Bar>
      }
    </Box>
  )
}

// Customizable Area Start
// Customizable Area End
