import React from "react";
import {
  CircularProgress as CircularProgressMUI,
  Typography,
  Box,
} from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";

interface CircularProgressProps {
  icon: string;
  value: number;
  color: string;
  title: string;
}

interface CircularProgressWithLabelProps {
  title: string;
  description: string;
  icon: string;
  value: number;
  color: string;
}

function CircularProgress({
  icon,
  value,
  color,
  title,
}: CircularProgressProps) {
  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgressMUI
        variant="determinate"
        className={classes.bottom}
        size={70}
        thickness={3}
        value={100}
      />
      <CircularProgressMUI
        variant="determinate"
        value={value}
        size={70}
        thickness={3}
        style={{ color }}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div">
          <img src={icon} alt={title} />
        </Typography>
      </Box>
    </Box>
  );
}

export default function CircularProgressWithLabel({
  title,
  description,
  icon,
  value,
  color,
}: CircularProgressWithLabelProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress icon={icon} value={value} color={color} title={title} />
      <Box className={classes.content}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {},
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: "15px",
      marginBottom: "15px",
    },
    content: {
      marginLeft: "20px",
    },
    title: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "36px",
      letterSpacing: "0.0833333px",
      color: "#171725",
      textTransform: "capitalize",
    },
    description: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.1px",
      color: "#696974",
      textTransform: "capitalize",
    },
    bottom: {
      color: theme.palette.grey[200],
    },
    top: {
      color: "red",
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);
