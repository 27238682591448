import React from "react";
// Customizable Area Start
import './videostyles.css';
import './CoursePage.css';
import LessonPageController from "./LessonPageController.web";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, Card, CircularProgress, Dialog, Grid, LinearProgress, Paper, Slider, Tooltip, Typography, makeStyles, styled } from "@material-ui/core";
import { backwardIcon, collapseIcon, expandIcon, forwardIcon, iconImage, iconMusic, iconPage, iconQuiz, iconVideo, lessonCompleteIcon, pauseIcon, playIcon, startIcon, viewShape, volumeIcon } from "./assets";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ReactPlayer from 'react-player'
import VolumeOffOutlinedIcon from '@material-ui/icons/VolumeOffOutlined';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: "10px",
        border: '1px solid #E5E2E1',
        borderRadius: '8px',
    },
    activeRoot: {
        display: 'flex',
        marginBottom: "10px",
        borderRadius: '8px',
        background: "#E0D4E7",
        border: "1px solid #652786",
        boxShadow: "0px 0px 16px 0px #6C328B54"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));
// Customizable Area End
let id: any;
export default class LessonPage extends LessonPageController {
    // Customizable Area Start
    renderImageDialog = () => {
        return (
<Dialog fullScreen
        open={this.state.openImageDialog}
        aria-labelledby="alert-dialog-title"
        style={{backgroundColor:"black"}}
        aria-describedby="alert-dialog-description">
        <Box style={styles.imageDialogBox}><CancelOutlinedIcon data-test-id="imageDialogCross" style={{color:"white"}} onClick={() => {this.state.lessonData?.data?.attributes.is_completed ? this.toggleImageDialog() : this.completeLesson()}}/></Box>
 <img style={{width:"100%",height:"100%"}} src={this.state.lessonData?.data?.attributes.video.url} alt="img" />
      </Dialog>
        )
    }

    imageComponent = () => {
        return (
                               <Box
                                style={styles.mainImg_box}>
                                <img data-test-id="imageid" src={this.state.lessonData?.data?.attributes.video.url} style={styles.main_courseImg} alt="img" onClick={this.toggleImageDialog}/>
                                <img src={expandIcon} style={{position:"absolute",bottom:5,right:5,cursor:"pointer"}} onClick={this.toggleImageDialog}/>
                            </Box>
        )
    }

     ValueLabelComponent(props:any) {
        const { children, open, value } = props;
      
        return (
          <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
          </Tooltip>
        );
      }

    videoComponent = () => {
        return (
            <div
            className="videoPlayerId"
            data-test-id="videoPlayerId"
            ref={this.videoContainerRef}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            >
<ReactPlayer
data-test-id="reactPlayerId"
          ref={this.playerRef}
          url={this.state.lessonData?.data?.attributes.video.url}
          playing={this.state.playing}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          muted={this.state.muted}
        />
        <div style={{position:"absolute",bottom:0,left:0,backgroundColor:"#1C1B1B",width:"100%",height:"44px",display:"flex",alignItems:"center",
            gap:"8px",padding:"0 8px"
        }}>
          <img data-test-id="backWardId" src={backwardIcon} onClick={this.handleSeekBackward}/>
          <img data-test-id="playPauseId" style={{width:"24px",height:"24px"}} src={this.state.playing ? pauseIcon : startIcon} onClick={this.handleToggleVideo}/>
          <img data-test-id="forwarId" src={forwardIcon} onClick={this.handleSeekForward}/>
          <Slider
        ValueLabelComponent={this.ValueLabelComponent}
        data-test-id="sliderchangeId"
        defaultValue={0}
        max={this.state.duration || 0}
        value={this.state.played}
        onChange={this.handleSeekChange}
        style={{margin:"0 5px"}}
        />
          <Typography style={{color:"white"}}>{this.formatTime(this.state.played)}</Typography>
          {this.state.muted ? <VolumeOffOutlinedIcon style={{fontSize:"24px",color:"white"}} onClick={this.handleToggleMute}/> : <img data-test-id="toggleMute" src={volumeIcon} onClick={this.handleToggleMute}/>}
          <img data-test-id="fullScreenVideo" src={this.state.isFullScreen ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
        </div>
        {this.state.showPlayButton && <Box style={{position:"absolute",top:"35%",left:"45%"}}>
            <img src={this.state.playing ? pauseIcon : playIcon} style={{width:"87px",height:"87px",cursor:"pointer"}} onClick={this.handleToggleVideo}/>
        </Box>}
        </div>
        )
    }

    renderLessonType = () => {
      if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "document" && this.state.lessonData.data.attributes.file_extension === "image"){
        return this.imageComponent()
      } else if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "video"){
        return this.videoComponent()
      }
    }

    renderImageLessonInstruction = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                color:"#652786"
            }}>
                    Click image to complete
               </p>
        )
    }
    renderVideoLessonInstruction = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                color: this.state.lessonData.data.attributes.is_completed ? "#00882C" :"#652786"
            }}>
                    Watch to complete
               </p>
        )
    }

    renderInstructions = () => {
        if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "video"){
           return this.renderVideoLessonInstruction()
        }else{
            return this.renderImageLessonInstruction()
        }
    }

    renderImageContinueBtn = () => {
        return (
<Button data-test-id="continueToNextLessonId" onClick={()=>{this.state.lessonData?.data?.attributes.is_completed && this.continueToNextLesson()}} className="coniunueNextLessonBtn" style={{textTransform:"none",background: this.state.lessonData?.data?.attributes?.is_completed ? "#E47004" : "#E5E2E1",width:"271px",height:"36px",padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "32px",
                                    color:this.state.lessonData?.data?.attributes?.is_completed ? "#FFFFFF" : "#787776",fontSize:"14px",fontWeight:600
                                   }} endIcon={<ArrowForwardOutlinedIcon />}>Continue to next lesson</Button>
        )
    }

    renderVideoContinueBtn = () => {
        return (
            <Button data-test-id="continueToNextLessonId" onClick={()=>{this.state.lessonData?.data?.attributes.is_completed && this.continueToNextLesson()}} className="coniunueNextLessonBtn" style={{textTransform:"none",background: this.state.lessonData?.data?.attributes?.is_completed ? "linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)" : "#E5E2E1",width:"271px",height:"36px",padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "32px",
                                    color:this.state.lessonData?.data?.attributes?.is_completed ? "#FFFFFF" : "#787776",fontSize:"14px",fontWeight:600
                                   }} endIcon={<ArrowForwardOutlinedIcon />}>Continue to next lesson</Button>
        )
    }

    renderContinueBtn = () => {
        if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "video"){
            return this.renderVideoContinueBtn()
         }else{
             return this.renderImageContinueBtn()
         }
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
              <Grid container style={styles.profileHeaderGrid}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography style={styles.profileHeader} variant="h3" className="profile_header" data-test-id ="headerDataId"> 
                    <img src={viewShape} style={{padding:"0px 10px 0px 10px", width:"32px", height: "22px"}} onClick={()=>window.history.back()}/>
                    {this.state.lessonData?.data?.attributes?.title}
                    </Typography>
                    <Backdrop style={styles.backdrop} open={this.state.isOpen} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container spacing={2} style={styles.mainGridBox} key={this.state.lessonData?.data?.id}>
                        <Grid item lg={8} md={8} sm={12} xs={12}
                        style={{
                            width:"844px"
                        }}
                        >
                            {this.renderLessonType()}
                            <Box style={{
                                background: "linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)",
                                height:"80px",
                                width:"100%",
                                padding:"16px 40px",
                                gap:"32px",
                                display:"flex",
                                justifyContent:"flex-end",
                                alignItems:"center"
                            }}>
                                {this.renderInstructions()}
                            {this.renderContinueBtn()}
                            </Box>
                            <p
                            style={styles.data_detailP}>
                                Description</p>
                            <p style={styles.data_detail_main}>{this.state.lessonData?.data?.attributes?.description}</p>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}
                        style={{
                            width:"412px"
                        }}
                        >
                            <Paper elevation={4} style={styles.sidebar_paper}>
                                <StyledTabs>
                                    <StyledTab className={this.state.activeTabIndex === 0 ? "activeTab": ""}>Lesson</StyledTab>
                                    <StyledTab  className={this.state.activeTabIndex === 1 ? "activeTab": ""}>Notes</StyledTab>
                                </StyledTabs>
                                <ProgressContainer>
                                <ProgressTop>
                                    <CourseSetting>Course completion</CourseSetting>
                                    <CourseSettingAfter>{this.state.lessonData?.data?.attributes.due_in_days}</CourseSettingAfter>
                                </ProgressTop>
                                <Box><StyledProgressBar variant="determinate" value={this.state.progressPercentage} />
</Box>
                                <ProgressBottom>
                                    <LessonsFont>{this.state.lessonData?.data?.attributes.completed_video_count} of {this.state.lessonData?.data?.attributes.total_video_count} lessons completed</LessonsFont>
                                </ProgressBottom>
                               </ProgressContainer>
                                <Box style={styles.main_Accordion}>
                                    <StyledAccordian style={styles.course_detail_phase}>
                                        {this.state.lessonData.data && this.state.lessonData.data.attributes.phases ?
                                            <>
                                                {this.state.lessonData.data.attributes.phases && Object.entries(this.state.lessonData.data.attributes.phases).map(([k, v]: any) => {
                                                    return (
                                                        <Accordion style={styles.course_accordion}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon style={{color:"black"}} />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <p style={styles.course_p}>{v.phase}</p>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={styles.course_detail}>
                                                                {v?.videos?.map((ele: any, index: any) => {

                                                                    return (
                                                                        <Box key={index}>
                                                                            <MediaControlCard activeId={this.state.activeLesson} isFreeTrial={k} button={this.state.lessonData?.data?.attributes?.button} index={index} ele={ele} handleNavigateToLesson={this.activeLessons}/>
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                {this.state.lessonData.data && this.state.lessonData.data.attributes.videos.map((ele: any, index: any) => {
                                                    return (
                                                        <Box key={index} style={{ width: "100%" }}>
                                        <MediaControlCard data-test-id="mediaControlComponent" activeId={this.state.activeLesson} button={this.state.lessonData?.data?.attributes?.button} index={index} ele={ele} handleNavigateToLesson={this.activeLessons}/>
                                                        </Box>
                                                    )
                                                })}
                                            </>
                                        }
                                    </StyledAccordian>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {this.renderImageDialog()}
    </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    cardMedia: {
        padding: "10px",
        justifyContent: "space-between",
        display: "flex",
        alignItem: "center",
        alignContent: "center",
    },
    imageDialogBox:{
        display:"flex",
        background:"black",
        justifyContent:"flex-end",
        right:0,
        position: 'relative' as 'relative',
        cursor:"pointer"
    },
    media_mainBox: {
        position: 'relative' as 'relative',
        display:"flex",
    },
    media_img: {
        height: '65px',
        borderRadius: '9px',
        width: '106px',
    },
    media_boxplay: {
        top: '50%',
        position: 'absolute' as 'absolute',
        transform: 'translate(-50%,-50%)',
        left: '50%',
    },
    media_boxtitle: {
        marginTop: "10px",
        marginLeft: '10px',
    },
    media_title: {
        marginLeft: '0px', fontSize: '16px',
        width: '100%', margin: 'auto',
        marginBottom: '10px', cursor: 'pointer',
        fontWeight: 400, lineHeight: '16px',
    },
    media_chapter: {
        margin: 'auto',
        lineHeight: '18px',
        fontSize: '14px',
        width: 'auto',
        fontWeight: 400,
    },
    course_box: {
        maxWidth: '83vw',
        height: '500px',
        flex: 1,
        overflow: 'hidden',
    },
    course_h3: {
        fontFamily: 'Montserrat'
    },
    course_detail: {
        padding: 0,
        flexDirection: 'column' as 'column',
        display: 'block',
        alignItems: 'center',
    },
    course_p: {
        fontSize: "16px",
        fontWeight: 700,
        textTransform: 'capitalize' as 'capitalize',
        margin: 0,
    },
    course_accordion: {
        boxShadow: 'none'
    },
    phase_h2: {
        fontFamily: 'Montserrat',
        marginTop: '-5px',
    },
    course_detail_phase: {
        flexDirection: 'column' as 'column',
        maxWidth: "unset",     
        padding: 0,
    },
    main_Accordion: {
        boxShadow: 'none',
        maxWidth: "unset !important",       
        position: 'inherit' as 'inherit',       
    },
    mainh2_sidebar: {
        marginTop: '-5px',
        fontFamily: 'Montserrat',
    },
    sidebar_paper: {
        marginBottom: '2rem',
        borderRadius: '16px',
        padding: '30px',
    },
    data_detail: {
        color: 'gray'
    },
    "& .tabBox": {
        width: "100%",
        marginTop: "15px",
      },
      "& .tabMainBox": {
          minHeight: "0px",
        borderBottom: "1px solid rgba(234, 236, 240, 1)",
      },
    data_detailP: {
        fontSize: "20px",
        fontWeight: 600,
        fontStyle: 'roman' as 'roman',
        lineHeight: "24px",
        fontFamily: "Poppins",

    },
    data_detail_main: {
        color: 'rgba(80, 80, 80, 1)',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
    },
    course_start: {
        color: '#fff',
        background: '#2b2b2b',
        padding: '6px 18px',
        borderRadius: '17px',
        marginLeft: '-10px',
        cursor: 'pointer',
    },
    course_buy: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    start_button: {
        background: '#2b2b2b',
        width: '220px',
        color: '#fff',
        borderRadius: '88px',
        height: '48px',
        cursor: 'pointer',
        padding: '6px 18px',
        fontSize: "16px",
        fontFamily: "Poppins",
        lineHeight: "16px",
        fontWeight: 600,
    },
    continue_button:{
        background: '#E47004',
        width: '220px',
        color: '#fff',
        borderRadius: '88px',
        height: '48px',
        cursor: 'pointer',
        padding: '6px 18px',
        fontSize: "16px",
        fontFamily: "Poppins",
        lineHeight: "16px",
        fontWeight: 600,
    },
    more_info_button:{
        background: '#E47004',
        width: '343px',
        color: '#fff',
        borderRadius: '88px',
        height: '48px',
        cursor: 'pointer',
        padding: '6px 18px',
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "21px",
        fontWeight: 600,
    },
    completed_button:{
        color: '#fff',
        background: '#652786',
        height: '48px',
        width: '220px',
        padding: '6px 18px',
        borderRadius: '88px',
        fontFamily: "Poppins",
        cursor: 'pointer',
        fontWeight: 600,
        lineHeight: "16px",
        fontSize: "16px",
    },
    ratting: {
        marginTop: '3px',
        fontSize: '16px',
        color: '#f9b651',
    },
    rattingBox: {
        display: 'flex',
    },
    rattingTypo: {
        fontWeight: 400,
        fontSize: '12px',
    },
    cateTypo: {
        paddingBottom: "10px",
        fontFamily: 'Montserrat' as 'Montserrat',
        fontWeight: 400,
        fontSize: '12px',
    },
    box_cate: {
        fontWeight: 400,
        marginTop: '-12px',
        color: "#787776",
        fontSize: "16px",

    },
    boxCreate: {
        fontWeight: 500,
        marginTop: '-9px',
        color: "787776",
        fontSize: "16px",
    },
    create_typo: {
        paddingBottom: "10px",
        fontFamily: 'Montserrat' as 'Montserrat',
        fontWeight: 400,
        fontSize: '12px',
    },
    main_title: {
        fontStyle: 'roman' as 'roman',
        fontSize: "20px",
        fontFamily: 'Montserrat' as 'Montserrat',
        fontWeight: 'bold' as 'bold',
    },
    main_titleBox: {
        flexDirection: 'column' as 'column',
        display: 'flex',
    },
    main_courseImg: {
        borderTopRightRadius: "20px",
        width: '100%',
        height: '100%',
        cursor:"pointer",
        borderTopLeftRadius: "20px",
    },
    mainImg_box: {
        borderRadius:"16px 16px 0px 0px",
        width: "100%",
        height: "344px",
        position:"relative" as "relative"
    },
    mainGridBox: {
        marginTop: '15px'
    },
    backdrop: {
        color: '#fff',
        zIndex: 99999
    },
    profileHeader: {
        fontFamily: 'Poppins',
        fontSize: '48px',
        fontWeight: 700
    },
    profileHeaderGrid: {
        paddingTop: '0px',
    }
}

const StyledAccordian = styled(AccordionDetails)({

    "& .Mui-expanded":{
        maxWidth: "unset !important",
    },

    "&.MuiAccordionDetails-root":{
        maxWidth: "unset !important",
        boxShadow: "none",
        border: "none",
        
    },
});

const IconArrange = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItem: "center",
});

const CourseSetting = styled(Typography)({
    fontWeight: 600,
    fontSize: "18px !important",
});

const CourseSettingAfter = styled(Typography)({
fontSize: "12px",
fontWeight: 700,
lineHeight: "18px",
textAlign: "left",
color: "#E47004",
});

const LessonsFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px !important"
});

const StyledTabs = styled(Box)({
    display: "flex",
    width: "100%",
    position: "relative",
    "&::before":{
        height:"2px",
        content: "' '",
        backgroundColor: "lightgray",
        width: "100%",
        bottom: "0",
        position: "absolute",
        left:"0"
    }
});

const StyledTab = styled(Box)({
    height: "81px",
    color: "#1C1B1B",
    lineHeight: "80px",
    textAlign: "center",
    flex: "0.5",
    position: "relative",
    fontWeight: 600,
    fontSize:"16px",
    "&.activeTab":{
        color: "#652786",
    },
    "&.activeTab::before":{
        height:"4px",
        content: "' '",
        backgroundColor: "#652786",
        width: "100%",
        bottom: "-1px",
        position: "absolute",
        borderRadius: "10px",
        left:"0",
    }
});

const StyledProgressBar = styled(LinearProgress)({
        height: "8px !important",
        backgroundColor: "#EEE8F2 !important",
        borderRadius: "5px",
        "& .MuiLinearProgress-barColorPrimary":{
            background: "linear-gradient(269.95deg, #6C328B 0.04%, #F42074 71.48%, #EF2B4B 99.95%)",
            borderRadius:"4px"
        }
      
});

const ProgressContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    margin: "20px 0",
    gap: "8px",
    borderBottom: " 1px solid #E5E2E1",
    paddingBottom:"35px",
});

const ProgressTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const ProgressBottom = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const SequenceNumberShow = styled(Box)({
    height: '48px',
    borderRadius: '4px',
    width: '48px',
    textAlign: "center",
    color:"#652786",
    fontWeight: 600,
    fontSize: "20px",
    border: "1px solid #E5E2E1 !important",
    padding: "7px",
});

const NameWithCount = styled("p")({
    flexDirection: "column",
    display: "flex",
});

export function MediaControlCard({ ele, button, isFreeTrial,handleNavigateToLesson,activeId }: any) {
    const classes = useStyles();
    function showIcons(element: string) {
        switch (element) {
            case "video":
                return iconVideo
            case "document":
                return iconPage
            case "audio":
                return iconMusic
            case "image":
                return iconImage
            case "quiz":
                return iconQuiz
            default:
                return;
        }
    };
    return (
        <Card className={ele.id === Number(activeId) ? classes.activeRoot : classes.root} style={styles.cardMedia} onClick={()=>handleNavigateToLesson(ele)}>
            <Box style={styles.media_mainBox} >
                <SequenceNumberShow> {ele.sequence_number}
                </SequenceNumberShow>
                <Box style={styles.media_boxtitle}>
                    {button === "start" ?
                        <h5 id="start-course-id-tst3" style={styles.media_title}>{ele?.title}</h5>
                        : <>
                            {isFreeTrial === "free_trial" ?
                                <h5 style={styles.media_title}>{ele?.title}</h5>
                                : <Box><NameWithCount style={styles.media_title} className="media_title"><span>{ele.title}</span> 
                                    </NameWithCount>
                                </Box>
                               
                            }
                        </>
                    }</Box></Box><IconArrange>
                <Box style={{ marginTop: "10px",width:"32px",height:"32px" }}>
                    {ele.is_completed ? <img src={lessonCompleteIcon} style={{width:"100%"}}/> : <img src={showIcons(ele.icon_type)} style={{width:"100%"}}/>}
                </Box>
            </IconArrange>
        </Card>
    )
}
// Customizable Area End