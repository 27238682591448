export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgDemo = require("../assets/image.png");
export const Calender = require("../assets/Calender.svg");
export const Vector = require("../assets/Vector.svg");
export const BackgroundColor = require("../assets/Background-Color.svg");
export const upload = require("../assets/upload.svg");
export const CirclePlus = require("../assets/CirclePlus.svg");
export const Library = require("../assets/Library.svg");
export const Trash = require("../assets/Trash.svg");
export const Shape = require("../assets/Shape.svg");
export const ShapePng = require("../assets/Shape.png");
export const Rectangle = require("../assets/Rectangle.svg");
export const AddVideoIcon = require("../assets/AddVideoIcon.svg");
export const AddQuizIcon = require("../assets/AddQuizIcon.svg");
export const RewardIcon = require("../assets/RewardIcon.svg");
export const AchievementIcon = require("../assets/AchievementIcon.svg");
export const Plus = require("../assets/Plus.svg");
export const Caret = require("../assets/Caret.svg");
export const CirclePlusRA = require("../assets/CirclePlusRA.svg");
export const Dollar = require("../assets/Dollar.svg");
export const PlayCopy = require("../assets/PlayCopy.svg");
export const quiz = require("../assets/quiz.svg");
export const Group2 = require("../assets/Group2.svg");
export const Group4 = require("../assets/Group4.svg");
export const Group7 = require("../assets/Group7.svg");
export const Group8 = require("../assets/Group8.svg");
export const CopiesIcon = require("../assets/copiesIcon.svg");
export const Caret1 = require("../assets/Caret1.svg");
export const Caret2 = require("../assets/Caret2.svg");
export const MenuIcon = require("../assets/MenuIcon.svg");
export const Download = require("../assets/Download.svg");
export const Delete = require("../assets/Delete.svg");
export const Folder = require("../assets/Folder.svg");
export const copy = require("../assets/copy.svg");
export const edit = require("../assets/edit.svg");
export const forwards = require("../assets/forwards.svg");
export const trash = require("../assets/mytrash.svg");
export const medal = require("../assets/medal.svg");
export const Course = require("../assets/Course.svg");
export const uploadIcon = require("../assets/NewShape.svg");
export const libraryIcon = require("../assets/libraryIcon.svg");
export const SelectArrow = require("../assets/SelectArrow.svg");
export const EditCourse = require("../assets/EditCourse.svg");
export const IconClse = require("../assets/icon_close.png");
export const Teacher = require("../assets/Teacher.svg");
export const Union = require("../assets/Union.svg");
export const IconObject = require("../assets/icon_object.svg");
export const ArrowDown = require("../assets/arrowdown.svg");
// app
export const CourseCreationImg = require("../assets/coursecreationbg.jpg");
export const CoursePlayImg = require("../assets/play.png");
export const VIDEO_ICON = require("../assets/play1.png");
export const VIDEO_ICON2 = require("../assets/play2.png");
export const RIGHT_ICON = require("../assets/righticon_course.png");
export const GIFT_ICON = require("../assets/gift.png");
export const EDIT_ICON = require("../assets/notes.png");
export const QUIZ_ICON = require("../assets/quiz.png");

export const EDIT1_ICON = require("../assets/edit.png");
export const HAPPY = require("../assets/happy.png");
export const SAD = require("../assets/sad.png");
export const INLOVE = require("../assets/inlove.png");
export const NOMOOD = require("../assets/expressionless.png");
export const NOMOODGRAY = require("../assets/nomoodgray.png");
export const SADGRAY = require("../assets/sadgray.png");
export const HAPPYGRAY = require("../assets/happygray.png");
export const INLOVEGRAY = require("../assets/inlovegray1.png");
export const FAILEDBG = require("../assets/failed_bg.png");
export const PASSEDBG = require("../assets/pass_bg.png");
export const TICK = require("../assets/tick.png");
export const CROSS = require("../assets/cross.png");
export const PROGRESSBAR = require("../assets/progress_barblank.png");
export const PROGRESSBARPASS = require("../assets/prog_bar_pass.png");
export const PROGRESS = require("../assets/progress.png");
export const CROSSPIC = require("../assets/cross_pic.png");
export const PASSPIC = require("../assets/pass_pic.png");
export const QUIZCOMPLETED = require("../assets/quiz_completed.png");
export const QUIZSUCCESS = require("../assets/quiz_success.png");
export const VIDEOSTART = require("../assets/video_start.png");
export const ENABLESUB = require("../assets/enable_sub.png");
export const CANCELSUB = require("../assets/cancel_sub.png");
export const MODALIMG = require("../assets/modalimg.png");
export const ASSIGNMENTIMG = require("../assets/assignment.png");
export const GOALIMG = require("../assets/goal.png");
export const OBJECTIMG = require("../assets/object.png");
export const PLAYICONIMG = require("../assets/playIcon.png");
export const SESSIONIMG = require("../assets/session.png");
export const userImage = require("../assets/user.jpeg");
export const fullScreen = require("../assets/full_screen.png");
export const setting = require("../assets/setting.png");
export const playPause = require("../assets/play_pause.png");
export const mute = require("../assets/mute.png");
export const unmute = require("../assets/unmute.png");
export const pause = require("../assets/pause.png");
export const play = require("../assets/play_control.png");
export const ACHIEVEMENTIMG = require("../assets/achievement.png");
export const ACHIEVEMENTIMG1 = require("../assets/achievement1.png");
export const THREE_DOT = require("../assets/threedot.png");
export const CART = require("../assets/Cart2.png");
export const WHITEPLAY = require("../assets/whiteplayicon.png");
export const UNLOCK = require("../assets/unlock.png");
export const QUIZCHECK = require("../assets/quizcheck.png");
export const PLAYIMG = require("../assets/playimg.png");
export const LOCKIMG = require("../assets/lockimg.png");
export const AddMore = require("../assets/Object77.png");
export const NotFoundImage = require("../assets/NotFoundImage.jpeg");
export const iconQuiz = require("../assets/iconQuiz.png");
export const iconPage = require("../assets/iconPage.png");
export const dotsIcon = require("../assets/iconChocolate.png");
export const videoIcon = require("../assets/groupIconVideo.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const editIcon = require("../assets/EditIcon.png");
export const audioIcon = require("../assets/groupIconAudio.png");
export const imageIcon = require("../assets/groupIconImage.png");
export const documentImage = require("../assets/groupIconDocument.png");
export const quizIcon = require("../assets/groupIconDocument2.png");
export const addIconHover = require("../assets/addIconHover.png");
export const iconClose = require("../assets/iconClose.png");
export const lessonImage = require("../assets/lessonImage.png");
export const dropDownArrow = require("../assets/dropDownArrow.png");
export const lessonDelete = require("../assets/lessonDelete.png");
export const collapseIcon = require("../assets/collapseIcon.png");

export const icon_audio = require("../assets/icon_audio.svg");
export const icon_page = require("../assets/icon_page.svg");
export const icon_video = require("../assets/newCourse.svg");
export const addContentImg = require("../assets/image_select.png");
export const imageHtmlCourse2 = require("../assets/image_html_course2.png")
export const rightArrow = require("../assets/right_arrow.png")
export const leftArrow = require("../assets/left_arrow.png")
export const editIconBlue = require("../assets/editBlueIcon.svg")
export const BackArrow = require("../assets/button_Back.svg");
export const NewFilter = require("../assets/NewFilter.svg");
export const Filter = require("../assets/filter.svg");
export const SortIcon = require("../assets/icon_page.svg");
export const UnlinkIcon = require("../assets/icon_unlink.svg");

