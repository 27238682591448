// Customizable Area Start
import React from "react";

import {
  Box,
  Popover,
  Badge,
} from "@material-ui/core";

import { createTheme,styled } from "@material-ui/core/styles";
import {AddIcon} from "./assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web"
import { NewFilter,Filter } from "../../../../packages/blocks/catalogue/src/assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ShowBranchController, {
  Props,
} from "./ShowBranchController.web";

export default class ShowBranch extends ShowBranchController {
  constructor(props: Props) {
    super(props);
  }

  advancedSearchFun=()=>{
    const openFilterContainer = Boolean(this.state.anchorTeamFilter)
    return(<StyledContentWrappers>
      <AdvancedSearch
    data-test-id="btn_change_one"
    searchInputValue={this.state.AdvanceSearchValue}
    handleListItemClick={this.handleThumbnailListClick}
    handleInputChange={this.handleTeamInputChangeSearch}
    inputWidth={window.innerWidth<800?"300":"480"}
    filterList={this.state.filteredContentData}
    isFilterDropActive={openFilterContainer}
    showList={this.state.showContentLists} navigation={undefined} id={""} />
     <div style={{ marginLeft: "0px" }} className={this.state.anchorTeamFilter ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDropClick}>
    {this.state.placeholderSelectItems.length > 0 ? (
      <BadgeComponent color="secondary" variant="dot"></BadgeComponent>) : (
      ""
    )
    }
    {this.state.anchorTeamFilter ? (
      <img src={Filter} data-test-id="filterOpenTest" alt="Filter" />
    ) : (
      <img src={NewFilter} data-test-id="filterOpenTest" />
    )}
  </div>
  <Popover 
    data-test-id="filterPropsID"
    className="filterContainerPopover"
    id="myPopover"
    open={openFilterContainer}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
  
  >
  </Popover>
    </StyledContentWrappers>)
    
  }
  render() {
    return (
      <>
      <HeadingMainWrapper>
        <HeadingInnerWrapper>
        <p className="main_head">Primary Branch</p>
        <p className="sub_head">D2DU</p>
        </HeadingInnerWrapper> 
        <Box className="advance_main">
          {this.advancedSearchFun()}
          </Box>
          <ButtonWrappers >
          <button className="btns" data-test-id="create_Branch" onClick={this.handleCreateBranch}><span><img src={AddIcon} alt ="icon"/></span>Create Branch</button>
          </ButtonWrappers>
      </HeadingMainWrapper>
      </>
    
    );
  }
}
const StyledContentWrappers = styled(Box)({
  position: "relative",
  display: "flex",
  width: "100%",

  "& .filterIconBox": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#FAFAFA",
      alignItems: "center",
      width: "63px",
      borderRadius: "8px",
      borderTopLeftRadius: "8px !important",
      cursor: "pointer",
      borderBottomLeftRadius: "8px !important",
      border: "1px solid #C9C6C5",
      position: "relative",
      zIndex: 999,
      left: "-1%"
  },
  "& .filterIconBox:hover": {
      borderColor: '#652786'
  },
  "& .allBorder": {
    backgroundColor: "#EEE8F2",
      borderColor: '#652786',
  }
});

const ButtonWrappers = styled(Box)({
 display:"flex",
 justifyContent:"center",
 alignItems:"center",
 "& .btns": {
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  width:"228px",
  gap:"10px",
  height:"56px",
  backgroundColor: "#652786 !important",
  cursor:"pointer",
  color:"#fff",
  border:"none",
  borderRadius: "16px",
  fontFamily: "'Poppins', sans-serif" as const,
  fontSize: "16px",
  fontWeight: 600 as const,
  textTransform: "capitalize" as const,
},

});
const BadgeComponent = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      right: " 0px",
      left: "20px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      height: "10px",
      width: "10px",
      borderRadius: "20px"
  }
});
const  HeadingInnerWrapper =styled(Box)({
  display:"flex",
  flexDirection:"column",
  paddingLeft:"20px",
  justifyContent:"center",
  gap:"5px",
  width:"304px",
  height:"72px",
  backgroundColor: "#fff !important",
  cursor:"pointer",
  color:"#2B2E3CCC",
  border:"none",
  borderRadius: "16px",
  fontFamily: "'Poppins', sans-serif" as const,
  fontSize: "16px",
  fontWeight: 700 as const,
  textTransform: "capitalize" as const,
  boxShadow:  "6px 6px 25px 0px #E5E5E580", 
"& .main_head":{
  margin:0
},"& .sub_head":{
  margin:0,
  color:"#2B2E3C99",
  fontWeight:500
}
})
  const HeadingMainWrapper=styled(Box)({
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    '@media (max-width: 1100px)': {
      alignItems:"flex-start",
       flexDirection:"column",
       gap:"10px"
    },
    "& .advance_main":{
      '@media (max-width: 800px)': {
        paddingLeft:"20px"
     },
    }
  })

// Customizable Area End
