import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { Platform } from "react-native";
import React from "react";
import { toast } from "react-toastify";
export const baseURL = require("../../../framework/src/config.js").baseURL;

interface IUploadedFile {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      files:
      | {
        id: number;
        file_name: string;
        file_url: string;
      }[]
      | null;
      status: string;
    };
  };
  meta: {
    message: string;
  };
}

interface IFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

interface IDownloadResponse {
  jobId: number;
  statusCode: number;
  bytesWritten: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  filesWeb: File[];
  files: any[];
  uploadedFiles: IUploadedFile[];
  filesStatus: (undefined | "uploading" | "success" | "failed")[];
  contentType: any;
  fileInput: any;
  thumbnailImage: any;
  thumbnailImageFile: any;
  attachedFile: any;
  attachment_key: any,
  tagsList: any;
  title: string;
  uploadStatus: string;
  uploadProgress: number;
  contentId: any;
  isAttachmentChange: boolean,
  currentChunkIndex:number,
  totalChunk:number,
  uploadSuccess:boolean,
  ObjectName:string;
  thumbnailPopupShow:boolean;
  thumbnailId:string;
  ContentFileType:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  getTagsApiCallId: string = "";
  createContentApiCallId: string = "";
  updateContentApiCallId: string = "";
  generatePresignedUrlApiCallId: string = "";
  postInitializerCallId: string="";
  postUploadChunkCallID:string="";
  FinalUploadCallId:string="";
  DeleteUploadFileCallId:string="";
  formikRef:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      filesWeb: [],
      files: [],
      uploadedFiles: [],
      filesStatus: [],
      contentType: "",
      thumbnailImage: "",
      thumbnailImageFile: "",
      uploadStatus: "notStarted",
      attachedFile: null,
      attachment_key: null,
      tagsList: [],
      uploadProgress: 0,
      fileInput: React.createRef(),
      contentId: null,
      isAttachmentChange: false,
      title: "Document Name",
      currentChunkIndex:0,
      totalChunk:0,
      uploadSuccess:false,
      ObjectName:"",
      thumbnailPopupShow:false,
      thumbnailId:"",
      ContentFileType:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const messageId = message.id;
    const sessionResponseId = getName(MessageEnum.SessionResponseMessage);
    const restAPIResponseId = getName(MessageEnum.RestAPIResponceMessage);

    if (messageId === sessionResponseId) {
      this.handleSessionResponseMessage(message);
    } else if (messageId === restAPIResponseId) {
      this.handleRestAPIResponseMessage(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSessionResponseMessage(message: Message) {
    runEngine.debugLog("Message Received", message);
    const token = message.getData(getName(MessageEnum.SessionResponseToken));
    if (token) {
      this.setState({ token: token });
    } else {
      this.showAlert("Alert", configJSON.loginAlertMessage);
    }
  }

  handleTitle(type: string) {
    if (type === 'video') {
      return 'Video Name';
    } else if (type === 'audio') {
      return 'Audio Name';
    } else {
      return 'Document Name';
    }
  }
  handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (errorResponse) {
      this.showAlert("Alert", configJSON.somethingWentWrongMsg);
    } else if (apiRequestCallId) {
      this.processApiRequestCall(apiRequestCallId, responseJson);
    }
  }

  processApiRequestCall(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.createBulkUploadCallId) {
      this.handleCreateBulkUpload(responseJson);
    } else if (apiRequestCallId === this.getBulkUploadCallId) {
      this.handleGetBulkUpload(responseJson);
    } else if (apiRequestCallId === this.deleteBulkUploadCallId) {
      this.handleDeleteBulkUpload(responseJson);
    } else if (apiRequestCallId === this.getTagsApiCallId) {
      this.handleGetTags(responseJson);
    } else if (apiRequestCallId === this.createContentApiCallId) {
      this.handleCreateContentResponse(responseJson);
    } else if(apiRequestCallId=== this.postInitializerCallId){
      if(!responseJson.error){
        this.setState({contentId:responseJson.data.id})
        this.postUploadChunk()
      }
    }else if(apiRequestCallId=== this.postUploadChunkCallID){
       this.handleChunkUploadres(responseJson)
    }else if(apiRequestCallId===this.FinalUploadCallId){
      this.handleFinalUplaod(responseJson)
    }else if (apiRequestCallId===this.DeleteUploadFileCallId){
      this.handleDeleteUploadres(responseJson)
    }
  }
  handleChunkUploadres=(responseJSON:any)=>{
    if(!responseJSON.error){
      if(this.state.uploadProgress===100){
          this.finalUpload();
      }
     this.setState((prevState) => ({ currentChunkIndex: prevState.currentChunkIndex + 1 }), () => {
     this.uploadNextChunk(25 * 1024 * 1024);
  });
    }else{
      toast.error(responseJSON.error, { autoClose: 2000 });
      this.handleState()
    }
   
  }
  handleFinalUplaod=(responseJSON:any)=>{
    if(!responseJSON.error){
      this.setState({uploadStatus:"uploaded",uploadSuccess:true})
     }else{
      toast.error(responseJSON.error, { autoClose: 2000 });
      this.handleState()
     }
  }
 handleDeleteUploadres=(responseJson: any)=>{
  if(!responseJson.error){
    this.handleState()
  }else{
    toast.error(`Something went wrong`, { autoClose: 2000 });
   }
 }
 handleState=()=>{
  this.setState({attachedFile:null,currentChunkIndex:0,uploadStatus:"notStarted",uploadSuccess:false})

 }
  handleCreateBulkUpload(responseJson: any) {
    let filesStatus = this.state.filesStatus;
    if (responseJson && responseJson.data) {
      filesStatus[filesStatus.length - 1] = "success";
    } else {
      filesStatus[filesStatus.length - 1] = "failed";
    }
    this.setState({ filesStatus });
    this.uploadFile();
  }

  handleGetBulkUpload(responseJson: any) {
    if (!responseJson.errors) {
      this.setState({ uploadedFiles: responseJson });
    }
  }

  handleDeleteBulkUpload(responseJson: any) {
    this.showAlert("Alert", configJSON.deletedSuccessMsg);
    this.getUploadedFiles();
  }

  handleGetTags(responseJson: any) {
    this.setState({ tagsList: responseJson.data });
  }

  componentDidMount = async () => {
    this.getToken();
    this.getTags();
   const type = sessionStorage.getItem('createContentType') || 'document'
   const titleText = this.handleTitle(type)
   this.setState({ contentType: type, title: titleText });
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  uploadFile = () => {
    const length = this.isPlatformWeb()
      ? this.state.filesWeb.length
      : this.state.files.length;

    if (length > this.state.filesStatus.length) {
      this.uploadFileSingle(this.state.filesStatus.length);
      this.setState({ filesStatus: [...this.state.filesStatus, "uploading"] });
    }
  };

  uploadFileSingle = (index: number) => {
    const header = {
      token: this.state.token,
    };

    const formData = new FormData();
    formData.append(
      "files[]",
      this.isPlatformWeb()
        ? this.state.filesWeb[index]
        : this.state.files[index]
    );

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createBulkUploadCallId = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBulkUploadEndpoint
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  };
  removeFile = (index: number) => {
    this.setState({
      files: Array.from(this.state.files).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    });
  };

  clearAllFile = () => {
    this.setState({ filesWeb: [], files: [], filesStatus: [] });
  };


  selectFiles = async () => {
    try {
      const pickerResult = await DocumentPicker.pickMultiple({
        presentationStyle: "fullScreen",
        copyTo: "cachesDirectory",
      });
      const tempFile: DocumentPickerResponse[] = pickerResult.filter((result) => {
        if (result.size && result.size > this.maxFileSize) {
          this.showAlert("Alert", result.name + configJSON.fileSizeErrorMsg);
          return false;
        } else {
          return true;
        }
      });

      this.setState({
        files: [...this.state.files, ...tempFile],
      });
    } catch (error) {
      this.showAlert("Alert", configJSON.downloadingFailedMsg);
    }
  };

  getUploadedFiles = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBulkUploadCallId = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBulkUploadEndpoint
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  };
  DeleteUploadFile=()=>{
    if(this.state.uploadSuccess){
      this.DeleteFileUpload()
    }else{
     this.setState({attachedFile:null})
    }
  }

  deleteFile = (fileGroupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBulkUploadCallId = deleteBulkUploadMsg.messageId;

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteBulkUploadEndpoint}/${fileGroupId}`
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
  };

  fileDownloadHandler = (filePath: string, fileName: string) => {
    if (Platform.OS !== "web") {
      try {
        const RNFS = require("react-native-fs");
        const dirPath = this.isPlatformiOS()
          ? RNFS.DocumentDirectoryPath
          : RNFS.DownloadDirectoryPath;
        const toFile = `${dirPath}/${Date.now()}${fileName}`;
        RNFS.downloadFile({
          fromUrl: baseURL + filePath,
          toFile,
        }).promise.then((response: IDownloadResponse) => {
          if (response.statusCode === 200) {
            this.showAlert("Alert", configJSON.downloadedSuccessMsg + toFile);
          } else {
            this.showAlert("Alert", configJSON.downloadingFailedMsg);
          }
        });
      } catch (error) {
        this.showAlert("Alert", configJSON.downloadingFailedMsg);
      }
    }
  };

  onSelectFile = (file: any) => {
    const maxFileSize = 25 * 1024 * 1024; 
    this.setState({ContentFileType:file.type})
    if (file.size>maxFileSize) {
      this.setState({ isAttachmentChange: true, attachedFile: file, uploadProgress: 0, uploadStatus: 'uploading'},()=>this.postInitiateUpload())
    }else{
      this.setState({ isAttachmentChange: true, attachedFile: file, uploadProgress: 0, uploadStatus: 'uploaded'})

    }
  }

  getTags = () => {
    this.getTagsAPICALL({
      contentType: configJSON.apiContentType,
      method: configJSON.getBulkUploadMethod,
      endPoint: configJSON.getTagsApiEndPoint,
    });
  };

  getTagsAPICALL(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTagsApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleCancel=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ManageContentLibrarylist");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationContentManagementLibraryMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  createContentFormData = (values: any) => {
    console.log(this.state.ContentFileType,"this.state.ContentFileType")
    let formData = new FormData();
    this.setState({ObjectName:values.videoName})
    formData.append("content[title]", values.videoName);
    formData.append("content[description]", values.content_description);
    formData.append("content[content_type]", this.state.contentType);
    if (this.state.uploadSuccess) {
      formData.append("content[upload_id]",this.state.contentId);
    }else{
      formData.append("content[attachment]",this.state.attachedFile);
    }
    formData.append("content[file_extension]",this.state.ContentFileType)
    formData.append("thumbnail_image_id",this.state.thumbnailId)
    formData.append("tag_ids[]", values.tags.map((item: any) => item.attributes.id))
    return formData
  }

  addContent(values: any) {
    const formData = this.createContentFormData(values);
     this.createContentAPICall({
        method: configJSON.createBulkUploadMethod,
        endPoint: configJSON.createContentEndPoint,
        body: formData,
      });
  }

  createContentAPICall(data: any) {
    const { method,body } = data;
    const header = {
      token: localStorage.getItem("token"),
      ContentType:"multipart/form-data"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.createContentApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_video_library/contents"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    !!body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleCreateContentResponse(responseJSON: any) {
    if(!responseJSON.errors){
      if(!this.state.uploadSuccess){
        this.setState({uploadStatus:"uploaded",uploadProgress:100})
       }
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ManageContentLibrarylist");
         message.addData(getName(MessageEnum.NavigationPayLoadMessage),{showSnackbar:this.state.ObjectName});
       
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }else{
      toast.error("something went wrong", { autoClose: 2000 });
    }
  }
  postInitiateUpload=async()=>{
    const body={
      filename:this.state.attachedFile.name,
      content_type:this.state.attachedFile.type
    }
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json" 
    };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.postInitializerCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.bulkFileUploadAPIEnd}/initiate_upload`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)  
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.createBulkUploadMethod
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}


postUploadChunk = async () => {
 
  const chunkSize = 25 * 1024 * 1024; 
    const totalChunks = Math.ceil(this.state.attachedFile.size / chunkSize);
    this.setState({totalChunk:totalChunks})
     await this.uploadNextChunk(chunkSize);
  
}


uploadNextChunk = async (chunkSize: number) => {
  if (this.state.currentChunkIndex <this.state.totalChunk) {
    const percentageUploaded = Math.round(((this.state.currentChunkIndex + 1) / this.state.totalChunk) * 100);
    this.setState({uploadProgress:percentageUploaded,uploadStatus:"uploading"})
    const start = this.state.currentChunkIndex * chunkSize;
    const end = Math.min((this.state.currentChunkIndex + 1) * chunkSize, this.state.attachedFile.size);
    const formData: any = new FormData();
    const blobSlice = this.state.attachedFile.slice(start, end, this.state.attachedFile.type);
    const chunkFileName = `${this.state.attachedFile.name}-chunk-${this.state.currentChunkIndex + 1}`;
    formData.append('file', blobSlice, chunkFileName);
    formData.append('total_chunk', this.state.totalChunk);
    formData.append('chunk_number', this.state.currentChunkIndex + 1);
   
    const header = {
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.postUploadChunkCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bulkFileUploadAPIEnd}/${this.state.contentId}/upload_chunk`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
selectedFileDelete=()=>{
  this.setState({ isAttachmentChange: true, attachedFile: null})
}

  updateContentAPICall(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.updateContentApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    !!body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  finalUpload=async()=>{
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json" 
    };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.FinalUploadCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.bulkFileUploadAPIEnd}/${this.state.contentId}/final_upload?total_chunk=${this.state.totalChunk}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getBulkUploadMethod
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }

  DeleteFileUpload=async()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.DeleteUploadFileCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.bulkFileUploadAPIEnd}/${this.state.contentId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteBulkUploadMethod
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }
  handleThumbnailContent=()=>{
    this.setState({thumbnailPopupShow:true})
  }
    handleThumbnailClose=(objectData:any)=>{
      this.formikRef?.setFieldValue("thumbnail",objectData.attributes.image.url)
    this.setState({thumbnailPopupShow:false,thumbnailId:objectData.id},()=>{
    })

  }
  closeThumbnailModal=()=>{
    this.setState({thumbnailPopupShow:false})
  }
  handleFailUpload(data: any) {
    toast.success(`${data.title} added`, { autoClose: 2000 });
    this.setState({ uploadStatus: data.uploadStatus, isAttachmentChange: data.isAttachmentChange, attachment_key: data.attachment_key })
    this.handleCancel()
  }
  // Customizable Area End
}
