Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "coursecreation";
exports.labelBodyText = "coursecreation Body";
exports.expertAdminListApiEndPoint = "bx_block_library2/experts";
exports.apiMethodTypeGet = "GET";
exports.courseAPiEndPoint = "bx_block_catalogue/collection_lists";
exports.catagoryAdvanceSearch ="bx_block_advanced_search/search/filter";

exports.btnExampleTitle = "CLICK ME";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.PutMethod ="PUT"
exports.PostMethod ="POST";
exports.DeleteMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CourseCreation";
exports.labelBodyText = "CourseCreation Body";
exports.createNotesAPiMethod = "POST";
exports.editNotesAPiMethod = "PUT";
exports.allNotesAPiEndPoint = "bx_block_library2/notes";
exports.btnExampleTitle = "CLICK ME";
exports.categoryFetchAPiEndPoint = "bx_block_categories/categories";
exports.createCourseAPiEndPoint = "bx_block_coursecreation/courses";
exports.updateCourseAPiEndPoint = "bx_block_coursecreation/courses/";
exports.createProgramAPiEndPoint = "bx_block_coursecreation/programs";
exports.getProgramDetailApiEndPoint = "bx_block_coursecreation/programs";
exports.getCourseDetailApiEndPoint = "bx_block_coursecreation/courses";
exports.updateProgramDetailApiEndPoint = "bx_block_coursecreation/programs";
exports.updateCourseDetailApiEndPoint = "bx_block_coursecreation/courses";
exports.getProgramListApiEndPoint = "bx_block_coursecreation/program_list";
exports.getIconsApiEndPoint = "bx_block_attachment/icons";
exports.getContentCourseApiEndPoint = "bx_block_coursecreation/course_contents/"
exports.getContentProgramApiEndPoint = "bx_block_coursecreation/program_contents/"
exports.addPhaseApiEndPoint = "bx_block_coursecreation/phases"
exports.renamePhaseApiEndPoint = "bx_block_coursecreation/phases/"
exports.changePhasesOrderApiEndPoint="/bx_block_coursecreation/order_phases"
exports.changeLessonsOrderApiEndPoint="bx_block_coursecreation/order_lessons"
exports.deleteLessonApiEndPoint = "/bx_block_custom_form/lessons/"
exports.reorderPhaseContetnsApiEndPoint =
  "bx_block_coursecreation/change_sequence";
exports.addRewardsApiEndPoint = "bx_block_library2/rewards";
exports.addAchievmentApiEndPoint = "bx_block_library2/achievements";
exports.getAdminNotificationList =
  "bx_block_coursecreation/notification_admin_list";
  
exports.getContentRoleListApiEndPoint = "bx_block_coursecreation/content_roles";
exports.createFolderApiEndPoint = "bx_block_admin/folder";
exports.getFolderApiEndPoint = "/bx_block_admin/folder/";
exports.getDeleteVideosApiEndPoint = 'bx_block_coursecreation/course_videos';
exports.getDeleteQuizApiEndPoint = 'bx_block_coursecreation/course_quizzes';
exports.getContentCoursesEndPoint = "bx_block_coursecreation/course_contents"
exports.getTagListApiEndPoint = 'bx_block_library2/custom_tags';
exports.createObjectApiEndPoint = 'bx_block_library2/program_objects';
exports.getdeleteObjectApiEndPoint = 'bx_block_coursecreation/program_objects';
exports.getdeleteCourseApiEndPoint = 'bx_block_coursecreation/program_courses';
exports.getExistingCourseListApiEndPoint = 'bx_block_coursecreation/collection_lists/courses';
exports.getRewardListApiEndPoint  = 'bx_block_library2/rewards';
exports.getAchievementListApiEndPoint = 'bx_block_library2/achievements';
exports.getSASTokenApiEndPoint = 'bx_block_coursecreation/course_videos/get_sas_token';
exports.reorderProgramContetnsApiEndPoint = 'bx_block_coursecreation/programs/change_sequence';
exports.listCourseDataEndPoint ='bx_block_coursecreation/content_roles/courses?';
exports.listProgramDataEndPoint = 'bx_block_coursecreation/content_roles/programs?';
exports.contentRoleDraftAndCreate = 'bx_block_coursecreation/content_roles';
exports.modalDraftText = "Are you sure you want to create this course but keep it in Draft status?";
exports.modalDraftTextSecond = "This means the course isn't available to users.";
exports.modalCreateCourseText = "Are you sure you want to create this course as active?";
exports.modalCreateCourseTextSecond = "This makes the course available to users.";

exports.catagoryAdvanceSearchFilter ="bx_block_advanced_search/search/filter";
exports.duplicateCourseApi = "/bx_block_coursecreation/duplicate_courses"

exports.btnExampleTitle = "CLICK ME";
exports.cancelTxt="Cancel";
exports.duplicateText="Duplicate"
exports.duplicateHeadingTxt="Are you sure you want to duplicate this Course?";
exports.duplicateSubText = "Only the content is copied. Assigned users/branches and rewards and achievements will not be copied.";
exports.duplicateSuccessTxt="Properties and lessons were also copied";
exports.saveTodraftButtonText = "Save & Make Active";
exports.contentRoleListingApiEndPoint ="bx_block_coursecreation/content_roles";
exports.saveAsDraftSubText="Are you sure you'd like to save this as a draft?";
exports.saveAsDraftHeadding="Save to Draft";
exports.saveAndContinueBtn="Save Draft";
exports.catagoryListingApiEndPoint ="bx_block_categories/categories";
// Customizable Area End