import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
export interface Reminder {
  app_url: number;
  courseable_id: number;
  courseable_type: string;
  due_date: string;
  due_in: number;
  full_date: string;
  popup_type: string;
  title: string;
  video_count: string;
}

const toastOptions: any = {
  position: "top-left"
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  fullScreen: boolean;
  isModalOpen:boolean;
  handelEditDailogClose?: () => void;
  classes?: any;
  history?: any;
  isLoading: boolean;
  courses: any[];
  courseStatus: string;
  handleCourseStatusChange: (value: string) => void;
  fetchMoreDashboardCourses: () => void;
  hasMore: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  courseStatus: string;
  statTimeFrame: string;
  courses: any;
  hasMore:boolean;
  advertismentData:any[];
  keyStatistics: {
    timeWatched: number;
    totalTimeWatched: number;
    completedRewards: number;
    totalRewards: number;
    courseOverallProgress: number;
  };
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: Message | string = "";
  dashboardApiCallId: Message | string = "";
  apiGetQueryStrinurl: Message | string = "";
  apiUserDetailsCallId: Message | string = "";
  apiGetCoursesCallId: Message | string = "";
  apiGetKeyStatisticsCallId: Message | string = "";
  apiGetRemindersCallId: Message | string = "";
  apiPostDismissRemindersCallId: Message | string = "";
  getAdsDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.handleCourseStatusChange = this.handleCourseStatusChange.bind(this);
    this.handleStatTimeFrameChange = this.handleStatTimeFrameChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      courseStatus: "all_courses",
      statTimeFrame: "",
      courses: [],
      hasMore:true,
      advertismentData:[],
      keyStatistics: {
        timeWatched: 0,
        totalTimeWatched: 0,
        completedRewards: 0,
        totalRewards: 0,
        courseOverallProgress: 0,
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getCourses(this.state.courseStatus,20);
    this.getCarouselData()
    this.getKeyStatistics(this.state.statTimeFrame);
    if (localStorage.getItem("token")) {
      this.getCourses(this.state.courseStatus,20);
      this.getKeyStatistics(this.state.statTimeFrame);
    }
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.apiDashboardItemCallId:
          this.handleGetDashboardData(responseJson, errorReponse);
          break;
        case this.apiGetCoursesCallId:
          this.handleGetCoursesResponse(responseJson);
          break;
        case this.apiGetKeyStatisticsCallId:
          this.handleGetKeyStatistics(responseJson);
          break;
        case this.getAdsDataCallId:
          responseJson.data && this.setState({advertismentData:responseJson.data});
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  
  // Handle get dashboard data response
  handleGetDashboardData = (responseJson: any, errorResponse: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (responseJson.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false,
        });
      } else {
        this.setState({
          dashboardData: responseJson.data,
          errorMsg: "",
          loading: false,
        });
      }
    } else {
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false,
        });
      } else {
        this.setState({
          errorMsg: errorResponse,
          loading: false,
        });
      }
    }
  }

  // Common method for api calls
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, payload } = data;
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleCourseStatusChange(value:string) {
    this.setState({
      courseStatus:value,
    });
    this.getCourses(value,20);
  }

  handleStatTimeFrameChange(event: React.ChangeEvent<{ value: unknown }>) {
    const timeFrame = event.target.value as string;
    this.setState({
      statTimeFrame: timeFrame,
    });
    this.getKeyStatistics(timeFrame);
  }

  // Get courses data
  getCourses = async (courseStatus: string,perPage:number) => {
    this.setState({
      loading: true,
    });

    this.apiGetCoursesCallId = await this.apiCall({
      contentType: webConfigJSON.dashboardContentType,
      method: webConfigJSON.getApiMethodType,
      endPoint: `${webConfigJSON.coursesGetUrl}?filter_status=${courseStatus}&page=${1}&per_page=${perPage}`,
    });
  }
  fetchMoreDasboardCourses=()=>{
    if(this.state.courses.length<this.state.courses.meta.pagination.total_count){
      this.getCourses(this.state.courseStatus,this.state.courses.data.length+20);
    } else{
      this.setState({hasMore:false})
    }   

  }
  // Handle get courses response
  handleGetCoursesResponse = (responseJson: any) => {
    this.setState({
      loading: false,
    });
    // Handle courses
    if (responseJson && !responseJson.errors && responseJson) {
      this.setState({
        courses: responseJson,
      });
    } else {
      toast.error("Something went wrong", toastOptions);
    }
  }

  // Get key statistics
  getKeyStatistics = async (timeFrame: string) => {
    this.apiGetKeyStatisticsCallId = await this.apiCall({
      contentType: webConfigJSON.dashboardContentType,
      method: webConfigJSON.getApiMethodType,
      endPoint: `${webConfigJSON.keyStatisticsGetUrl}?filter=${timeFrame}`,
    });
  }

  // Handle get key stats response
  handleGetKeyStatistics = (responseJson: any) => {
    if (
      responseJson &&
      !responseJson.errors &&
      responseJson.insight_overview
    ) {
      const {
        insight_overview: {
          time_watched,
          completed_rewards,
          total_rewards,
          course_ourall_progress,
          total_time,
        },
      } = responseJson;
      this.setState({
        keyStatistics: {
          timeWatched: time_watched,
          totalTimeWatched: total_time,
          completedRewards: completed_rewards,
          totalRewards: total_rewards,
          courseOverallProgress: course_ourall_progress,
        },
      });
    } else {
      toast.error("Something went wrong", toastOptions);
    }
  }

  handleNavigateTOCourse = (navigationUrl:string) => {
      window.open(navigationUrl,"_blank")
  }

  getTransformValue = (currentIndex:number,itemsToShow:number) => {
   return this.transformValueByWidth(window.innerWidth,currentIndex,itemsToShow)
  }

  transformValueByWidth = (windowWidth:number,currentIndex:number,itemsToShow:number) => {
    if(windowWidth>900){
      return -(currentIndex * 100) / itemsToShow
    } else if(windowWidth<900 && windowWidth > 500){
      return -(currentIndex * 50) / itemsToShow
    } else if(windowWidth < 500){
      return -(currentIndex * 32) / itemsToShow
    }
  }

  getCarouselData = () => {
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getAdsDataCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_promotecontent/advertisements`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'get'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
