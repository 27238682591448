import React from "react";
import {
  Box, Grid
  // Customizable Area End
  ,
  // Customizable Area Start
  Paper, TextField, Typography
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from '@material-ui/core/styles';
import InProgessController from './InProgressController.web';
import SearchIcon from '@material-ui/icons/Search';
import './videostyles.css';
// Customizable Area Start
import DynamicTitle from "../../../components/src/DynamicTitle";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { iconVideo } from "./assets";
const img4 = require("../assets/slider-1.png");
const img2 = require("../assets/courseimage4.png");
const play = require("../assets/play.png")
const img3 = require("../assets/courseimage5.png")
const group = require("../assets/Group 8.png")
const shop = require("../assets/image_Bitmap Copy 4.png")

// Customizable Area End
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      width: '60%',
      marginTop: '15px',
      marginLeft: '17px',
    },
    colorPrimary: {
      // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
      borderRadius: 5,
      background: `linear-gradient(87deg, rgba(240,50,76,1) 0%, rgba(254,132,64,1) 100%)`
    },
  }))(LinearProgress);

export default class InProgess extends InProgessController {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start

    // Customizable Area End
    _onFocus = () => {
      this.props.history.push('/Search')
    }

    render() {
        return (
            <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <ArrowBackIosIcon data-test-id="gotoBackBtn"
                onClick={this.gotoBackSide} style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="In Progress" />
            </div>
        <Grid className="containerGrid" container>
        {this.state.inProgressLibraryData.map((conent: any, index: number) => {
          const totalVideoCount = conent.attributes.total_video_count || 0;
          const completedVideoCount = conent.attributes.completed_video_count || 0;
          const completionPercentage = totalVideoCount ? (completedVideoCount / totalVideoCount) * 100 : 0;
          return (
          <Grid key={index} item lg={2} md={4} sm={6} xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Box className="gridItem" onClick={()=>this.handleNavigateTOProduct(conent)} 
                    style={{
                      height: "407px",position: "relative",
                      cursor:"pointer"}} key={index}>
                      <Box style={{
                          height: "181px", padding: "16px 0px 0px 0px", gap: "13px",borderRadius: "12px 0px 0px 0px",opacity: "0px",position: "relative"
                        }}>
                        <img width="100%" height="100%" style={{borderRadius:"12px"}} src={conent.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                         <Box style={{...styles.flexCenter,width:"100%",height:"100%",borderRadius: "12px",position:"absolute",top:"17px",background: "rgba(0, 0, 0, 0.3)"}}>
                          </Box> 
                          {conent.attributes.collection.attributes.expand_type === "Program" && <Typography style={{...styles.chipText,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{conent.attributes.collection.attributes.expand_type}</Typography>} 
                      </Box>
                      <Box style={{width:"100%",height: "242px",padding: "24px",borderRadius: "12px ",position: "absolute",bottom:5,backgroundColor:"white",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"}}>
                          <Box style={{ display:"flex",gap: "16px",flexDirection:"column"}}>
                        <Typography style={{fontFamily: "Poppins",fontSize: "16px",fontWeight: 700,lineHeight: "22px",textAlign: "left",color:"#1C1B1B"}}>{conent.attributes.collection.attributes.title}</Typography>
                        <Typography style={{fontFamily: "Poppins",fontSize: "14px",fontWeight: 500, lineHeight: "22px",}}>{conent.attributes.collection.attributes.created_by}</Typography>
                        <Typography
                          style={{
                            fontFamily: "Poppins",fontSize: "12px",fontWeight: 400,lineHeight: "18px",color:"#484646"
                          }}>{conent.attributes.due_in_days === "Overdue" ? conent.attributes.due_in_days : `Due in ${conent.attributes.due_in_days} Days`}</Typography>
                      </Box>
                      <Box style={{display:"flex",justifyContent:"space-between",position:"absolute",width:"80%",bottom:"20px"}}><Box>
                          <Box style={{ 
        width: "154px", height: "10px", borderRadius: "88px", 
        backgroundColor: "rgba(236, 195, 195, 0.23)", position: "relative" 
      }}>
        <span style={{  display: "block",  width: `${completionPercentage}%`,  height: "10px",  borderRadius: "88px", background: "linear-gradient(155.66deg, #EF2B4B -1.02%, #FF8B3F 68.24%)",position: "absolute",top: 0, left: 0 }}></span>
      </Box>
                            <Typography style={{color: "var(--sys-color-Secondary, rgba(228, 112, 4, 1))",fontWeight:400,fontSize:"12px",marginTop:"10px"}}>
                          {`${conent.attributes.completed_video_count}/${conent.attributes.total_video_count} Lessons Completed`}</Typography>
                          </Box><Box style={{width:"40px",height:"40px",borderRadius:"8px",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",display:"flex",justifyContent:"center",alignItems:"center" }}>
                            <img src={iconVideo}/></Box>
                         </Box>
                      </Box>  
                    </Box>
          </Grid>)})}
        </Grid>
            </>
        )

    }

}

const styles = {
  img_shape: {
      paddingLeft: '13px'
    },
    typography_personal: {
      fontWeight: 700,
      fontSize: '24px'
    },
    flexBetween:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center"
    },
    flexCenter:{
      display:"flex",
      justifyContent:"center",
    },
    chipText:{
      width: "117px",
      height:"28px",
      padding: "3px 0px",
      borderRadius: "16px",
      background: "#E70E6B",
      color:"white",
      fontWeight:400,
      fontSize:"14px",
    }
}