// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  InputLabel,
  CircularProgress,
  Typography,
  Radio,
  Checkbox,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  withStyles,
  styled
} from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik, Form } from "formik";
import "react-calendar/dist/Calendar.css";
import { SelectArrow, infoIcon } from "./assets";
import ManageUsersController from "./ManageUsersController.web";
const configJSON = require("./config.js");

export interface Props {
  classes: any;
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s'-]+$/, 'First name can only include alphabetic characters, spaces, hyphens, and apostrophes')
    .min(2, 'First name must be between 2 and 50 characters long')
    .max(50, 'First name must be between 2 and 50 characters long'),
  middleName: yup.string()
    .matches(/^[a-zA-Z\s'-]+$/, 'Middle name can only include alphabetic characters, spaces, hyphens, and apostrophes')
    .min(2, 'Middle name must be between 2 and 50 characters long')
    .max(50, 'Middle name must be between 2 and 50 characters long'),
  lastName: yup.string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s'-]+$/, 'Middle name can only include alphabetic characters, spaces, hyphens, and apostrophes')
    .min(2, 'Middle name must be between 2 and 50 characters long')
    .max(50, 'Middle name must be between 2 and 50 characters long'),
  title: yup.string(),
  email: yup
    .string()
    .email("Email address is not valid")
    .required("Email address is required"),
  phone: yup.number().required("Mobile number is required").typeError("'Mobile number can only contain numeric characters"),
});

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  title: "",
  email: "",
  phone: "",
  branchId: "",
  teamId: "",
  activeUser: "no",
  CountryCode: "US",
};

export const SelectArrowIcon = () => {
  return (
    <img
      src={SelectArrow}
      alt="down arrow"
      style={{ marginRight: 10, width: 13 }}
    />
  );
};

class CreateUser extends ManageUsersController {
  render() {
    return (
      
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(event) => { this.postUserForm(event) }}
          data-test-id="userFormTest"
        >
          {(formikProps) => {
            return (
              <form>
                <div className="user-invite">
                  <div className={this.props.classes.root}>
                    <Typography
                      className={this.props.classes.userInformationHeading}
                    >
                      {configJSON.userInformation}
                    </Typography>
                    <div className={this.props.classes.fullWidth}>
                      <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                          <InputLabel
                            className={this.props.classes.label}
                            htmlFor="first_name"
                          >
                            {configJSON.firstName}
                          </InputLabel>
                          <TextField
                            fullWidth
                            id="first_name"
                            name="firstName"
                            variant="outlined"
                            placeholder="First Name"
                            value={formikProps.values.firstName}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            className={this.props.classes.textField}
                            error={this.hasError(formikProps.touched.firstName, formikProps.errors.firstName)}
                            helperText={
                              formikProps.touched.firstName &&
                              formikProps.errors.firstName
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel
                            className={this.props.classes.label}
                            htmlFor="middle_name"
                          >
                            {configJSON.middleName}

                          </InputLabel>
                          <TextField
                            fullWidth
                            id="middle_name"
                            name="middleName"
                            variant="outlined"
                            placeholder="Middle Name"
                            value={formikProps.values.middleName}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            className={this.props.classes.textField}
                            error={
                              formikProps.touched.middleName &&
                              Boolean(formikProps.errors.middleName)
                            }
                            helperText={
                              formikProps.touched.middleName &&
                              formikProps.errors.middleName
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel
                            className={this.props.classes.label}
                            htmlFor="last_name"
                          >
                            {configJSON.lastName}
                          </InputLabel>
                          <TextField
                            fullWidth
                            id="last_name"
                            name="lastName"
                            variant="outlined"
                            placeholder="Last Name"
                            value={formikProps.values.lastName}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            className={this.props.classes.textField}
                            error={
                              formikProps.touched.lastName &&
                              Boolean(formikProps.errors.lastName)
                            }
                            helperText={
                              formikProps.touched.lastName &&
                              formikProps.errors.lastName
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel
                            className={this.props.classes.label}
                            htmlFor="add_to_team"
                          >
                            {configJSON.title}
                          </InputLabel>
                          <FormControl className={this.props.classes.selectFormControl} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur}>
                            <Select
                              labelId="select-teams"
                              displayEmpty
                              name="teamId"
                              data-test-id="selectTitle"
                              id="select-teams"
                              variant="outlined"
                              placeholder="dropdown"
                              onChange={formikProps.handleChange}
                              value={formikProps.values.teamId}
                              onBlur={formikProps.handleBlur}
                              className={this.props.classes.select}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return <span className={this.props.classes.dropDownPlaceHolder}>Select from dropdown</span>;
                                }
                                return selected as string; 
                              }}
                              IconComponent={(props) => (
                                <div {...props} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingRight: "10px" }}>
                                  <img
                                    src={SelectArrow}
                                    alt="down arrow"
                                    style={{ paddingRight: "10px", width: "24px", height: "24px" }}
                                  />
                                </div>
                              )}
                            >
                              {['mr', 'mrs', 'miss', 'ms', 'dr', 'prefer not to say'].map((option) => (
                                <MenuItem key={option} value={option} className={this.props.classes.menuItem}>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        size="small"
                                        checked={formikProps.values.teamId?.toString() === option}
                                        style={{ color: this.getColor(formikProps.values.teamId, option) }}
                                      />
                                    }
                                    label=""
                                    className={this.props.classes.radio}
                                    style={{ marginRight: '8px' }}
                                  />
                                  {option.charAt(0).toUpperCase() + option.slice(1).replace(/([A-Z])/g, '')}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <div className={this.props.classes.emailPhoneDiv}>
                        <Grid item md={6} xs={12}>
                          <InputLabel
                            className={this.props.classes.label}
                            htmlFor="email_address"
                          >
                            {configJSON.emailAddress}
                          </InputLabel>
                          <TextField
                            fullWidth
                            id="email_address"
                            name="email"
                            variant="outlined"
                            placeholder="Email Address"
                            value={formikProps.values.email}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            className={this.props.classes.textField}
                            error={
                              formikProps.touched.email &&
                              Boolean(formikProps.errors.email)
                            }
                            helperText={
                              formikProps.touched.email &&
                              formikProps.errors.email
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <div className={this.props.classes.infoDiv}>
                            <img src={infoIcon} style={{ width: "20px" }} />
                            <p className={this.props.classes.emailText}>{configJSON.emailText}</p>
                          </div>
                        </Grid>
                        <Grid item md={6} xs={10} className={this.props.classes.phoneNumberDiv} >
                          <Grid item md={12} xs={12}>
                            <InputLabel
                              className={this.props.classes.label}
                              htmlFor="phone_number"
                            >
                              {configJSON.mobileNumber}
                            </InputLabel>
                            <div style={{ display: "flex" }}>

                              <CustomSelect1
                                id="CountryCode"
                                name="CountryCode"
                                displayEmpty
                                value={formikProps.values.CountryCode}
                                onChange={formikProps.handleChange}
                                onOpen={this.handleSelectOpen}
                                onClose={this.handleSelectClose}
                                MenuProps={{ ...CustomMenuProps, anchorOrigin: { vertical: "bottom", horizontal: "right" }, transformOrigin: { vertical: "top", horizontal: "right" } }}
                                className={this.props.classes.countryCodeDropdown}
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                {this.state.countryFlagCode && this.state.countryFlagCode.map((name) => (
                                  <MenuItem

                                    key={name.id}
                                    value={name.id}
                                    className="menu_box"
                                  >
                                    <span className="menu_arrow"></span>
                                    <span className={`flag_img fi fi-${name.id.toLowerCase()}`}></span>
                                    <span className="flag_Text">{`+${name.attributes.country_code}`}</span>
                                  </MenuItem>
                                ))}
                              </CustomSelect1>
                              {this.state.selectOpen}
                              <TextField
                                fullWidth
                                id="phone_number"
                                name="phone"
                                variant="outlined"
                                placeholder="Mobile Number"
                                value={formikProps.values.phone}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                className={this.props.classes.textField}
                                style={{ width: "496px", marginLeft: "10px" }}
                                error={
                                  formikProps.touched.phone &&
                                  Boolean(formikProps.errors.phone)
                                }
                                helperText={
                                  formikProps.touched.phone &&
                                  formikProps.errors.phone
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div className={this.props.classes.emailCheckBoxDiv}>
                      <div style={{ display: "flex", alignItems: "center" }}>

                        <span className={this.props.classes.sendToLink}>{configJSON.sendLinkTo}</span>
                        <div style={{ marginLeft: "5px" }} >
                          <CheckboxOne
                            data-test-id="filterTestId" />
                          <span className={this.props.classes.checkEmailText}>{configJSON.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.props.classes.inviteUserBtn}>
                    <Button
                      data-test-id="submit-btn"
                      disableElevation
                      variant="contained"
                      onClick={() => { this.postUserForm(formikProps.values) }}
                      type="submit"
                      className={`${this.props.classes.btn} ${this.props.classes.createUserBtn}`}
                      endIcon={
                        this.state.isLoading ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : null
                      }
                    >
                      {configJSON.inviteUser}
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    emailCheckBoxDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "56px",
      background: "#EEE8F2",
      color: "black",
      borderRadius: "12px",
      margin: "15px 0",
      gap: "20px"
    },
    checkEmailText: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      color: "#484646"
    },
    sendToLink: {
      color: "#1C1B1B",
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Poppins",
      marginBottom: "5px"
    },
    inviteUserBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "35px"
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
    },
    hiddenRadio: {
      display: 'none',
    },
    select: {
      textTransform: "lowercase",
      height: '56px',
      borderRadius: '8px',
      padding: "0 15px 0 0 ",
      '& .MuiSelect-select:focus': {
        background: 'white !important',
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E5E2E1",
      },
      "& ::first-letter": {
        textTransform: "capitalize",
      },
    },
    countryCodeDropdown: {
      height: "56px",
      width: "149px",
      border: "1px solid #E5E2E1",
      borderRadius: "8px",
      "@media (max-width: 960px)": {
        width: "50%",
        "& .MuiFormControl-root": {
          minWidth: "none !important",
        }
      },
    },
    phoneNumberDiv: {
      marginLeft: "30px",
      "@media (max-width: 960px)": {
        marginLeft: "0px !important",
        marginTop: "10px",
        maxWidth: "none !important",
        "& .MuiGrid-grid-xs-12": {
          width: "100% !important",
        },
        "& .MuiGrid-grid-xs-10": {
          maxWidth: "none !important"
        },
      },

    },
    emailPhoneDiv: {
      marginTop: "25px",
      display: "flex",
      alignItems: "start",
      justifyContent: "space-evenly",
      "@media (max-width: 960px)": {
        flexDirection: "column",
        width: "100% !important",
      },
    },
    root: {
      width: "100%",
      marginBottom: 20,
      padding: "20px 24px",
      backgroundColor: "#FFFFFF",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
      borderRadius: "16px",
    },
    accordionContainer: {
      borderRadius: "18px !important",
      background: "#FFF",
      boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    },
    urlInput: {
      border: '1px solid #6962624f',
      width: '70%'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    copyUrlButton: {
      background: '#653889',
      height: '32px',
      weight: '108px',
      borderRadius: '8px',
      padding: '8px,16px,8px,16px',
      color: 'white',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      position: 'relative',
      width: '517px'
    },
    paperParent: {
      backgroundColor: 'transparent',
      padding: '15px',
    },
    crossIcon: {
      position: 'relative',
      top: -32,
      right: '-33em',
    },
    details: {
      alignItems: "center",
    },
    btn: {
      borderRadius: "16px",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "16px",
      textAlign: "center",
      textTransform: "capitalize",
      fontFamily: "Poppins !important"
    },
    createUserBtn: {
      background: "#652786",
      width: "411px",
      height: "56px",
      color: "#ffffff",
      "&:hover": {
        background: "#652786",
      },
    },
    inviteBtn: {
      background: "#fff",
      width: "185px",
      height: "56px",
      color: "#6C328B",
      border: "1.5px solid #6C328B",
    },
    selectFormControl: {
      width: "100%",

    },
    selectDropdown: {
      height: "55px",
      background: "#fafafa",
      padding: "0 15px 0 0",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #d6d6d6",
        borderRadius: 15,
      },
      "& .MuiSelect-select:focus": {
        borderRadius: 18,
        background: "#fff",
      },
      "&.MuiSelect-outlined.MuiSelect-outlined": {
        padding: "8px !important"
      }
    },
    teamSelectContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    justifyEnd: {
      justifyContent: "flex-start",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
      },
    },
    radio: {
      "& .Mui-checked": {
        color: "#6C328E",
      },
    },
    fullWidth: {
      width: "100%",
    },
    userInformationHeading: {
      color: "#1C1B1B",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "33px",
      padding: "14px 0px",
      fontFamily: "Poppins !important",
    },
    label: {
      color: "#1C1B1B",
      fontSize: "16px",
      fontFamily: "Poppins !important",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: 5,
    },
    dropDownPlaceHolder: {
      color: "#ADAAAA",
      fontSize: "16px",
      fontFamily: "Poppins !important",
      fontWeight: 400,
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        background: "#FAFAFA",
        borderRadius: "8px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E5E2E1",
      },
    },
    emailText: {
      padding: "0px",
      margin: "5px 0px",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "12px"
    },
    infoDiv: {
      display: "flex",
      gap: "5px",
    }

  });

const CheckboxOne = withStyles({
  root: {
    color: '#652786 !important',
    width: "24px",
    height: "24px",
    margin: "0 4px 2px 8px",
    '&$checked': {
      color: '#652786',
    },
  },
  checked: {},
})(Checkbox);

const CustomSelect1 = styled(Select)({
  '& .MuiSelect-root': {
    position: 'relative',
    display: "flex",
    alignItems: "center",
    gap: "25px",
    padding:"0 15px 0 0",
    "@media (max-width: 960px)": {
      "& .MuiGrid-item .MuiFormControl-root": {
        width: "50% !important",
      }
    },
  },
  '& .MuiPopover-paper': {
    position: 'relative',
    zIndex: 999,
  },
  "& .MuiList-padding": {
    paddingTop: "0px"
  },
  "& .MuiListItem-gutters": {
    paddingLeft: "2px !important",
    paddingRight: "8px !important",
    gap: "10px !important",
    color: "#6C328B !important",
    fontSize: "14px !important",
    fontWeight: 500
  },
  '& .MuiSelect-icon': {
    display: "none"
  }
})

const CustomMenuProps = {
  PaperProps: {
    style: {
      height: "150px" as const,
      marginTop: '22px' as const,
      marginLeft: "10px" as const,
      width: "100px" as const,
      minWidth: "100px",
      left: "10px",
      color: "red"
    },
  },
  getContentAnchorEl: null
};
export default withStyles(styles)(CreateUser);
// Customizable Area End
