import React from "react";
import { Typography, Box, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

interface Props {
  title?: string;
  title1?: string;

  showBackButton?: boolean;
  onBackClick?: () => void;
}

export default function Title({ title, title1, showBackButton, onBackClick }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.pageTitleContainer}>
      {showBackButton && ( <ArrowBackIosIcon  onClick={onBackClick} className={classes.backIcon}/>  )}
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.title1}>{title1}</Typography>

    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      // marginBottom: "15px",
      // marginLeft: "-15px",
    },
    title: {
      fontWeight: 700,
      fontSize: "25px",
      lineHeight: "30px",
      color: "#1C1B1B",
      fontFamily: "Poppins, sans-serif",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "30px",
        lineHeight: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "48px",
        lineHeight: "72px",
      },
    },
    title1: {
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "60px",
      color: "#1C1B1B",
      fontFamily: "Poppins, sans-serif",
      [theme.breakpoints.up("sm")]: {
        fontSize: "40px",
        lineHeight: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "40px",
        lineHeight: "60px",
      },
    },
    backIcon: {
      marginLeft:"8px",
      cursor:"pointer",
      color:"#92929D",
    },
  })
);