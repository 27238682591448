Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiMethod = "GET";
exports.getUserListApiContentType = "application/json";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.validationApiContentType = "application/json";
exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.deleteApiMethod="DELETE";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.createTagApiEndPoint = "bx_block_coursecreation/custom_tags";
exports.descriptionBottomText = "*A description will increase sales and improve the user experience.";
exports.pasteLinkText = "You can paste your Youtube link here";
exports.enterLinkText = "Paste link here";
exports.chooseLibraryText = "Choose from Content Library";
exports.createBulkUploadMethod = "POST";
exports.lessonAPiEndPoint="/bx_block_custom_form/lessons";
exports.saveDratLooseChange = "Save to Draft to avoid losing changes";
exports.bulkFileUploadAPIEnd="/bx_block_bulk_uploading/file_uploads";
exports.textLeaveWithout = "If you leave without saving, all your changes will be lost.";

// Customizable Area End