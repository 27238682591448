// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  anchorTeamFilter:boolean;
  AdvanceSearchValue:string;
  filteredContentData:any;
  showContentLists:boolean;
  placeholderSelectItems:string;
}

interface SS {
  id: any;
}

export default class ShowBranchController extends BlockComponent<
  Props,
  S,
  SS
> {

  getTeamCollectionCallId:string="";
  getAdvanceSearchApiCallIds:string="";
  getDeleteApiCallIds:string="";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      anchorTeamFilter:false,
      filteredContentData:[],
      AdvanceSearchValue:"",
      showContentLists:false,
      placeholderSelectItems:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
   
if(apiRequestCallId===this.getAdvanceSearchApiCallIds){
   this.setState({filteredContentData:responseJson.data})
}
  }
  async componentDidMount() {
    super.componentDidMount();
  }

  getAdvancedSearchLists=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdvanceSearchApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AdvancedSearchEndPoint}?type=team&search=${this.state.AdvanceSearchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleCreateBranch=()=>{
    this.props.navigation.navigate("CreateBranch", {
      type: "Create"
    });
  }
  handleThumbnailListClick=(item:string)=>{
    this.setState({
      AdvanceSearchValue: item,
      showContentLists: false
    })
   }
  handleTeamInputChangeSearch = (event:any) => {
    this.setState({AdvanceSearchValue:event.target.value},() => {
    })
      this.setState({showContentLists:event.target.value !== ""},()=>{this.getAdvancedSearchLists()}) 
     }

  handleFilterDropClick=()=>{

  }
}

// Customizable Area End
