import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  styled,
  Badge,
  Popover
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { thumbnailImg, Filter } from "./assets";
import { createTheme } from "@material-ui/core/styles";
import { NewFilter } from "../../../../packages/blocks/catalogue/src/assets";
import ContentLibraryTable from "../../../components/src/ContentLibraryTable";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal"
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import FilterItems from "../../filteritems/src/FilterItems.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ThumbnailmanagementController, {
  Props,
  validationSchema
} from "./ThumbnailmanagementController.web"

export default class Thumbnailmanagement extends ThumbnailmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  initialFormikState = {
    thumbnail: ""
  }

  renderInputFilterRow = () => {
    const { anchorsFilterContentsEl } = this.state;
    const openFilterContainer = Boolean(anchorsFilterContentsEl)
    return (
      <>
        <InputSearchWrapper>
        <StyledThumbnailWrappers>
                <AdvancedSearch
              data-test-id="advanced_btn"
              searchInputValue={this.state.SearchBarValue}
              handleInputChange={this.handleInputSearch}
              handleListItemClick={this.handleThumbnailListClick}
              filterList={this.state.filteredDataLists}
              inputWidth={window.innerWidth<500?"280":"480"}
              isFilterDropActive={openFilterContainer}
              showList={this.state.showContentData} navigation={undefined} id={""} />
               <div style={{ marginLeft: "0px" }} className={this.state.anchorsFilterContentsEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDropClick}>
              {this.state.creationDateThumbnail.length > 0 || (this.state.creationDateParamsdatas.hasOwnProperty('start_date') && this.state.creationDateParamsdatas.start_date.length > 0) || this.state.placeholderSelectsItemed.length > 0 ? (
                <BadgeThumbnails color="secondary" variant="dot"></BadgeThumbnails>) : (
                ""
              )
              }
              {this.state.anchorsFilterContentsEl ? (
                <img src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={NewFilter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover 
              data-test-id="filterPropsID"
              className="filterContainerPopover"
              open={openFilterContainer}
              id="myPopover"
              anchorEl={this.state.anchorsFilterContentsEl}
              onClose={this.filterContainerDropdownCloseHandlers}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            
            >
              <FilterItems
                isThumbnail
                navigation={this.props.navigation}
                data-test-id="FilterItemsTestID"
                handleClick={this.handleFilterClick}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandle}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChanges}
                dropdownOpenHandler={this.dropdownHandlerOpens}
                creationDateChangeHandler={this.creationChangeHandlerDates}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendors}
                anchorEl={this.state.anchorsEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectsItemed}
                placeholderSearchText={this.state.placeholderSearchData}
                creationDate={this.state.creationDateThumbnail}
                expertAdminList={this.state.expertAdminsData}
                isCalendarOpen={this.state.isCalendarshow}
                handleClear={this.handlesFilterClear}
                handleCalendarChange={this.handleChangeCalender} />
               
            </Popover>
              </StyledThumbnailWrappers>
          <CreateGrid>
          <Box sx={webStyle.Accordion} className="btnBox">
            <Button
              onClick={() => this.handleOpen()}
              style={webStyle.AccordionSummary}
              data-test-id='thumbnail_id'
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <CreateMainComponent>
                <span style={{ fontSize: "16px", fontWeight: 600, paddingRight: "10px", fontFamily: "Poppins, sans-serif" }}>
                  +
                </span>
                Add Thumbnail
              </CreateMainComponent>
            </Button>
          </Box>

          <DeleteModal
            data-test-id='delete-content'
            openDialog={this.state.deleteModalOpen}
            headingText={`Are you sure you want to delete this ?`}
            btnCancelText="Go Back"
            btnOkText="Delete"
            subHeading="This action cannot be undone."
            handleCancel={this.cancelContentDeleteDialog}
            handleOk={() => this.handleDeleteContent(this.state.latestId)}
          />
          <DeleteModal
            data-test-id='delete-all-content'
            openDialog={this.state.deleteAllOpen}
            headingText={`Are you sure you want to delete this ?`}
            btnCancelText="Go Back"
            btnOkText="Delete"
            subHeading="This action cannot be undone."
            handleCancel={this.cancelContentAllDeleteDialog}
            handleOk={this.handleDeleteAllContent}
          />
       
          <AlertSuccess
            data={{
              show: this.state.deletealert.isOpen,
              msg: this.state.showMessage  ? "Deletion Successful": this.state.deletealert.msg,
              type: this.state.deletealert.type,
              vertical: "top",
              horizontal: "right",
              showThumb:false
            }}
          />
          {this.state.showMessage && <AlertSuccess
            data={{
              show: this.state.deletealert.isOpen,
              msg: "Some thumbnails could not be deleted since they are being used.",
              type: this.state.deletealert.type,
              vertical: "top",
              horizontal: "right",
              showThumb:true
            }}
          />}
            
        </CreateGrid>
        </InputSearchWrapper>
     

      </>

    )
  }

  renderTableOrNotFound = () => {
    const TABLEHEADERS = [
      { id: 1, label: "", sort: false },
      { id: 2, label: "Name", sort: true, dataType: "string", key: "title" },
      { id: 4, label: "Created by", sort: true, dataType: "string", key: "created_by" },
      { id: 5, label: "Created on", sort: true, dataType: "date", key: "created_at" },
      { id: 6, label: "", sort: false },
    ];
    return (
      <TableWrapper>
        {this.state.ThumbnailData && this.state.ThumbnailData.length > 0 ?
          <ContentLibraryTable
            data-test-id='contentTableTestId'
            thumbnailList={this.state.ThumbnailData}
            isThumbnail={true}
            onSortIconClick={this.sortUsers}
            TableHeader={TABLEHEADERS}
            getSelectedChecboxID={this.getSelectedIDChecbox}
            deleteItem={this.deleteItemSingle}
            handleDeleteAll={this.handleDeleteBulk}
            closeDeleteButton={this.state.RemoveDeleteButton}
            updateChildState={this.updateChildState} 
          /> :

          <MainNotFoundBox>
            <MainInnerBox>
              <Box>
                <ImageComponent src={thumbnailImg} />
              </Box>
              <NotFoundText>No results found</NotFoundText>
            </MainInnerBox>
          </MainNotFoundBox>
        }
      </TableWrapper>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
 <Grid container style={{ marginTop: '15px', justifyContent: "space-between" }}>
            {this.renderInputFilterRow()}
            {this.renderTableOrNotFound()}
          </Grid>
        
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start


const TableWrapper = styled(Box)({
  width: "100%",
  "& .table thead .tr .th:nth-child(2)": {
    "& p": {
      marginLeft: "92px !important",
      justifyContent: "flex-start !important",
    }
  },
  "& .table tbody .tr .td:nth-child(2)": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "12px"
  },
  "& .table tbody .rowBg": {
    backgroundColor: "#80008012"
  }
})

const CreateGrid = styled(Grid)({
  paddingRight: "0 !important",
  "& .btnBox": {
    width: "max-content",
    marginLeft: "auto",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      marginTop:"10px"
    },
  }
});

const ImageComponent = styled("img")({
  macWidth:"100%",
  height:"auto",
});

const MainNotFoundBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
});

const MainInnerBox = styled(Box)({
  marginTop: "40px"
});

const NotFoundText = styled(Typography)({
  marginTop: "26px",
  color: "#1C1B1B !important",
  textAlign: "center",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-1px"
});
const BadgeThumbnails = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      left: "20px",
      right: " 0px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      width: "10px",
      height: "10px",
      borderRadius: "20px"
  }
});
const StyledThumbnailWrappers = styled(Box)({
  position: "relative",
  display: "flex",
  width: "100%",

  "& .filterIconBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      width: "63px",
      borderRadius: "8px",
      borderTopLeftRadius: "8px !important",
      borderBottomLeftRadius: "8px !important",
      cursor: "pointer",
      border: "1px solid #C9C6C5",
      position: "relative",
      zIndex: 999,
      left: "-1%"
  },
  "& .filterIconBox:hover": {
      borderColor: '#652786'
  },
  "& .allBorder": {
      borderColor: '#652786',
      backgroundColor: "#EEE8F2",
  }
});

const InputSearchWrapper = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  width:"100%",
  [theme.breakpoints.down('sm')]: {
   flexDirection:"column"
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    border: '1px solid #E5E2E1',
    height: '54px',
    borderRight: "none",
    transition: 'border-color 0.3s ease',
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    '&:hover': {
      borderColor: '#652786 !important',
      borderRadius: '10px !important',
      border: '1px solid #652786',
      color: '#652786 !important',
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '0px',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#652786',
    borderRadius: '10px',
    border: '1px solid #652786',
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
  }
});


const CreateMainComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: ' flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize'
})

const webStyle = {

  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

  Accordion: {
    height: '56px',
    borderRadius: '15px',
    boxShadow: 'none',
    position: 'relative',
  },
  AccordionSummary: {
    backgroundColor: '#6C328B',
    borderRadius: '15px',
    width: '192px',
    height: '56px',
  },
};
// Customizable Area End
